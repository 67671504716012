import {Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {sliderOptions} from '@digitalhilft/utils/helper';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {MainService} from '@digitalhilft/services/main.service';
import {COMPANY_CATEGORIES, JOB_CATEGORIES, SEARCH_FILTERS, TIME_FILTER} from '@digitalhilft/utils/constans';


@Component({
  selector: 'filter-sidebar',
  templateUrl: './filter-sidebar.component.html',
  styleUrls: ['./filter-sidebar.component.scss'],
})

export class FilterSidebarComponent implements OnInit, OnChanges {
  @Input() stopMovePropagation: boolean = false;
  @Input() stopMovePro;
  @Input() centerMap;
  @Input() initialCircle;
  @Input() selectedLocationProperties;
  @Input() jobs;
  @Input() allJobs;

  @Output() stopMovePropagationChange = new EventEmitter<boolean>();
  @Output() centerMapChange = new EventEmitter<boolean>();
  @Output() initialCircleChange = new EventEmitter<boolean | null>();
  @Output() selectedLocationPropertiesChange = new EventEmitter<boolean | null>();
  @Output() onClose = new EventEmitter();
  @Output() countOfAppliedFilters = new EventEmitter();


  jobCategories = JOB_CATEGORIES;
  companyCategories = COMPANY_CATEGORIES;


  filteredJobs: any[];

  searchForm: FormGroup;
  sliderOptions = sliderOptions;
  locationModel: any;
  localSearchCtrl = new FormControl();

  searchFilter = SEARCH_FILTERS;
  filteredByTime = TIME_FILTER;

  filterCounts = {};
  now = new Date();
  today = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate(), 0, 0, 0, 0);
  before7days = new Date(this.today.getTime() - (7 * 24 * 60 * 60 * 1000));

  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private _mainService: MainService,
    private _activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.initializeSearchForm();
    this.onFormControlsChanged();
    this.observeUrl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filter(this.appliedFilters());
    this.calculateCounts();
  }

  calculateCountOfAppliedFilters(){
    let _appliedFilters = 0;
    const {job_category, company_category, time_filter, searchKey} = this.appliedFilters();

    if(job_category.length > 0){
        _appliedFilters += job_category.length
    }
    if(company_category.length > 0){
      _appliedFilters += company_category.length
    }
    if(time_filter){
      _appliedFilters += 1;
    }
    if(searchKey){
      _appliedFilters += 1;
    }

    this.countOfAppliedFilters.emit(_appliedFilters);

    return _appliedFilters;
  }

  resetSearchForm(): void {
    this.localSearchCtrl.reset();
    this.jobCategories.map(category => category.is_selected = false);
    this.companyCategories.map(category => category.is_selected = false);
    this.filteredByTime.filteredByTime = null;
    this.searchFilter.searchKey = null;
  }

  searchLocation = (text$: Observable<string>) => text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap(term =>
      this._mainService.locations(term).pipe(
        catchError(() => of([])))),
  );

  closeDrawer(): void {
    this.calculateCountOfAppliedFilters();
    this.onClose.emit(this.filteredJobs);
  }

  formatter = (x): string => x.display_label;

  selectCompanyCategory(item) {
    item.is_selected = !item.is_selected;

    const filters = this.appliedFilters();
    this.filter(this.appliedFilters());
    if (filters?.company_category?.length == 0) {
      this.calculateCounts('job_category');
    }
  }

  private initializeSearchForm(): void {
    this.searchForm = this._fb.group({
      display_label: [null, Validators.required],
      zip_code: [null, Validators.required],
      distance: [50, Validators.required],
      favorite: [false],
    });

    this.localSearchCtrl.setValue(this.searchFilter.searchKey);

    this.searchForm.patchValue({
      ...this._activatedRoute.snapshot.queryParams,
    });

    this.locationModel = {
      display_label: this._activatedRoute.snapshot.queryParams?.display_label,
      zip_code: this._activatedRoute.snapshot.queryParams?.zip_code,
    };
  }

  private navigate(data: any = {}): void {

    this._router.navigate([], {
      queryParams: {...this.searchForm.getRawValue(), ...data, cmpId: this._activatedRoute.snapshot.queryParams['cmpId'] || null},
    });
  }

  private setSelectedLocationProperties(locationModel): void {
    this.selectedLocationPropertiesChange.emit(locationModel);
    localStorage.setItem('selectedLocationProperties', JSON.stringify(locationModel));
  }

  search(): void {
    if (!this.locationModel) {
      return;
    }
    this.searchForm.patchValue({
      zip_code: this.locationModel.geodata_de_zipcode ? this.locationModel.geodata_de_zipcode : this.locationModel.zip_code,
      display_label: this.locationModel.display_label ? this.locationModel.display_label : this.locationModel,
      favorite: false,
    });

    this.stopMovePropagationChange.emit(true);
    this.centerMapChange.emit(true);
    this.initialCircleChange.emit(null);
    this.setSelectedLocationProperties(this.locationModel);
    this.navigate({
      st: 'search', 
      job_category: this.calculateCountOfAppliedFilters() > 0 ? this.jobCategories.filter(category => category.is_selected).map(category => category.name).join(";") : null
    });
    this.calculateCountOfAppliedFilters();
    this.onClose.emit(this.filteredJobs);
  }

  private onFormControlsChanged() {
    this.localSearchCtrl.valueChanges.subscribe(res => {
      this.searchFilter.searchKey = res;
      this.filter(this.appliedFilters());
      this.calculateCounts();
    });

    this.searchForm.get('distance').valueChanges.subscribe(res => {
      if (res && this.searchForm.get('zip_code').value) {
        this.navigate({
          st: 'search', 
          job_category: this.calculateCountOfAppliedFilters() > 0 ? this.jobCategories.filter(category => category.is_selected).map(category => category.name).join(";") : null
        });
      }
    });
  }

  appliedFilters() {
    const filters = {job_category: [], company_category: [], time_filter: null, searchKey: null};

    this.jobCategories.forEach(jobCategory => {
      if (jobCategory.is_selected) {
        filters.job_category.push(jobCategory.name.toLowerCase());
      }
    });

    this.companyCategories.forEach(companyCategory => {
      if (companyCategory.is_selected) {
        filters.company_category.push(companyCategory.name.toLowerCase());
      }
    });

    if (this.searchFilter?.searchKey) {
      filters.searchKey = this.searchFilter.searchKey;
    }

    if (this.filteredByTime?.filteredByTime) {
      filters.time_filter = this.filteredByTime.filteredByTime == 'week' ? this.before7days : this.today;
    }

    return filters;
  }

  filter(filters) 
  {
    this.filteredJobs = this.allJobs;
    
    if (filters?.searchKey) {
      this.filterByKey(filters.searchKey);
    }

    this.filteredJobs = this.filteredJobs?.filter(layer =>
      (!filters?.job_category || filters?.job_category?.length == 0 || filters?.job_category?.indexOf(layer.job_category?.toLowerCase()) != -1) &&
      (!filters?.company_category || filters?.company_category?.length == 0 || filters?.company_category.indexOf(layer.company_category?.toLowerCase()) != -1) &&
      (!filters.time_filter || filters.time_filter <= new Date(Date.parse(layer.publish_date)))
    );

  }

  private filterByKey(searchKey) {
    const keys = searchKey.split(' ');
    this.filteredJobs = this.allJobs?.filter(layer => {
        for (const key of keys) {
          if (!layer.title.toLowerCase().includes(key?.toLowerCase()) &&
            !layer.company_name.toLowerCase().includes(key?.toLowerCase()) &&
            !layer.main_company_name.toLowerCase().includes(key?.toLowerCase()) &&
            !layer.plz.toLowerCase().includes(key?.toLowerCase()) &&
            !layer.ort.toLowerCase().includes(key?.toLowerCase())) {
            return false;
          }
        }
        return true;
      }
    );
  }

  private calculateCounts(without: null | 'job_category' | 'company_category' | 'time_filter' = null): void {
    if (without !== 'time_filter') {
      this.filterCounts['time_filter_week'] = 0;
      this.filterCounts['time_filter_day'] = 0;
      for (const job of this.allJobs) {
        const jobDate = new Date(Date.parse(job.publish_date));
        if (jobDate > this.before7days) {
          if (jobDate > this.today) {
            this.filterCounts['time_filter_day'] = (this.filterCounts['time_filter_day'] || 0) + 1;
          }
          this.filterCounts['time_filter_week'] = (this.filterCounts['time_filter_week'] || 0) + 1;
        }
      }
    }

    if (without !== 'job_category') {
      this.filterCounts['job_category'] = 0;
      for (const category of this.jobCategories) {
        this.filterCounts['job_category_' + category.name] = 0;
      }
      for (const job of this.filteredJobs) {
        this.filterCounts['job_category']++;
        this.filterCounts['job_category_' + job.job_category] = (this.filterCounts['job_category_' + job.job_category] || 0) + 1;
      }
    }

    if (without !== 'company_category') {
      this.filterCounts['company_category'] = 0;
      for (const company of this.companyCategories) {
        this.filterCounts['company_category_' + company.name] = 0;
      }
      for (const job of this.filteredJobs) {
        this.filterCounts['company_category']++;
        this.filterCounts['company_category_' + job.company_category]++;
      }
    }
  }


  private observeUrl() {
    let jobCategory = "";
    if (this._activatedRoute.snapshot.queryParams?.j_category) {
      jobCategory += this._activatedRoute.snapshot.queryParams?.j_category;
    } 
    if (this._activatedRoute.snapshot.queryParams?.job_category) {
      if (this._activatedRoute.snapshot.queryParams?.j_category) {
        jobCategory += ";"
      }
      jobCategory += this._activatedRoute.snapshot.queryParams?.job_category;
    }

    if (jobCategory) {
      this.jobCategories.forEach(category => {
          category.is_selected = jobCategory.split(";").some(el => el === category.name);
      });
    }

    this.filter(this.appliedFilters());
    this.calculateCounts('job_category');
  }

  selectJobCategory(item) {
    item.is_selected = !item.is_selected;
    this.filter(this.appliedFilters());

    this.calculateCounts('job_category');
  }

  onSelect(event) {
    this.locationModel = {
      display_label: event?.item?.display_label,
      zip_code: event?.item?.zip_code,
    };

    this.searchForm.patchValue(this.locationModel);
    this.navigate({st: 'search', ...this.locationModel});
  }

  timeFilter(type: string) {
    this.filteredByTime.filteredByTime = this.filteredByTime.filteredByTime == type ? null : type;
    this.filter(this.appliedFilters());
  }
}
