<div class="modal-content bg-primary" *ngIf="type == 'favorite'" style="z-index: 9999;">
  <div class="modal-body p-4">
    <div class="row">
      <div class="col">
        <h4 class="text-white">{{'KEINE_FAVORITEN' | translate}}</h4>
        <p class="font-weight-light mt-3 p-color-9">{{'KLICKE_FAVOURITEN' | translate}}</p>
      </div>
      <div class="col-1">
        <button type="button" class="close text-white" style="outline: none" data-dismiss="modal" aria-label="Close" (click)="onClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="row justify-content-center">
      <div style="width: 310px;touch-action: none;">
        <div class="job-container">
          <div class="p-3">
            <div class="flex-space-between pb-1">
              <span class="sub-title">{{jobContent?.job_category}}</span>
            </div>

            <div>
              <span class="job-title">{{jobContent?.title}}</span>
            </div>

            <div>
              <span class="sub-title">{{jobContent?.company_name}}</span>
            </div>

            <div class="flex-space-between">
              <span class="sub-title">{{jobContent?.plz}} {{jobContent?.ort}}</span>
              <span class="sub-title">{{jobContent?.publish_date | date:'dd MMM yyyy'}}</span>
            </div>

            <div class="flex-space-between mt-2 position-relative">
              <i class="bi bi-star fs-22 pointer"></i>
              <img class="hand" src="assets/img/hand.svg">
              <a target="_blank"><button class="btn btn-sm btn-primary button-radius">{{'ZUM_JOB' | translate}}</button></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal-content bg-white no-job-modal" style="" *ngIf="type == 'job'">
  <div class="modal-body modal-p">
    <div class="row">
      <div class="col">
        <p class="text-muted p-color-7">{{'KEINE_JOBS' | translate}}</p>
        <p class="font-weight-light text-muted mt-3 p-color-5">{{'ERWEITERE_SUCHE' | translate}}</p>
      </div>
      <div class="col-1">
        <button type="button" class="close text-muted" style="outline: none" data-dismiss="modal" aria-label="Close" (click)="onClose()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</div>

