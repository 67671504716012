import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {FormControl} from '@angular/forms';
import {TourService, IStepOption} from 'ngx-tour-ng-bootstrap';

@Component({
  selector: 'search-result-sidebar',
  templateUrl: './search-result-sidebar.component.html',
  styleUrls: ['./search-result-sidebar.component.scss'],
})

export class SearchResultSidebarComponent implements OnInit {
  @Input() isSidebarOpen;
  @Input() jobs;

  @Output() onSearch = new EventEmitter();
  @Output() isSidebarOpenChange = new EventEmitter();
  @Output() sortJobs = new EventEmitter();

  sortObj: { key: string, sortDirection: string | 'asc' | 'desc' } = null;

  searchCtrl = new FormControl();
  showSortSection: boolean = false;
  currentTourStepId: string;

  constructor(private _tourService: TourService) {
  }

  ngOnInit(): void {
    this.searchCtrl.valueChanges.subscribe(res => {
      const searchValues = res.split(' ');

      this.onSearchJobs(searchValues);
    });
  }

  handleTourEvent() {
    let tourSteps: IStepOption[] = [];
    if (screen.width > 575) {
      tourSteps.push({
        stepId: "4",
        anchorId: "search-sidebar-container",
        title: "Jobliste",
        content: "Hier findest du alle Jobs der Karte im Listenformat.<br><span class='text-muted'>Alle gesetzten Filter werden auch auf diese Liste angewandt.</span>",
        placement: "right",
        prevBtnTitle: "Zurück",
        nextBtnTitle: "Nächster Schritt",
        endBtnTitle: "Tour beenden"
      });
      this._tourService.initialize(tourSteps);
      this._tourService.start();
      this.currentTourStepId = "4";
      this._tourService.end$.subscribe(() => this.currentTourStepId = null);
    }
  }

  selectSort(key, sortDirection) {
    if (key === this.sortObj?.key && sortDirection === this.sortObj?.sortDirection) {
      this.sortObj = null;
      return;
    }
    this.sortObj = {key, sortDirection};
  }

  onSearchJobs(searchValues: string[]) {
    this.onSearch.emit(searchValues);
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.isSidebarOpenChange.emit(this.isSidebarOpen);
  }

  sort(): void {
    this.showSortSection = false;
    if (!this.sortObj) {
      this.sortJobs.emit({key: null});
      return;
    }
    this.sortJobs.emit(this.sortObj);
  }

  resetSort(): void {
    this.showSortSection = false;
    this.sortObj = null;
  }
}
