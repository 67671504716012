<div *ngIf="!einstellungun" class="modal-content bg-primary">
  <div class="modal-body p-5">
    <div class="row">
      <div class="col">
        <h3 class="text-white">{{'DATENSCHUTZEINSTELLUNGEN' | translate}}</h3>
        <p class="text-white mt-4">{{'WEBSITE_COOKIES' | translate}}</p>
        <p class="font-weight-lighter p-color-light">{{'WEITERE_INFORMATIONEN' | translate}}</p>
        <div class="text-center">
          <button class="btn btn-light text-primary my-3 font-weight-bold" (click)="allowCookie()">{{'COOKIES_ZULASSEN'
            | translate}}</button>
          <p class="font-weight-lighter p-color-light" style="cursor:pointer" (click)="onClose()">{{'Cookies ablehnen' |
            translate}}</p>
          <p class="font-weight-lighter text-white" style="cursor:pointer" (click)="einstellungun = true">
            {{'COOKIE_EINSTELLUNGEN' | translate}}</p>
          <a href="legaldocs/datenschutz.html"
            target="_blank" class="text-white text-decoration-none mr-40 footer-link">{{'DATENSCHUTZ' | translate}}</a>
          <a href="legaldocs/impressum.html" target="_blank"
            class="text-white text-decoration-none mr-3 footer-link">{{'IMPRESSUM' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="einstellungun" class="modal-content bg-primary center-modal">
  <div class="modal-body p-5">
    <div class="row">
      <div class="col">
        <h3 class="text-white">{{'COOKIE_EINSTELLUNGUN' | translate}} <i class="bi bi-x pointer float-right"
            (click)="activeModal.close()"></i></h3>
        <p class="text-white my-4">{{'NOTWENDIGE_COOKIES' | translate}}</p>
        <p class="text-white my-4">{{'AUF_INFO_ANBIETERN' | translate}}</p>
        <div class="text-left mb-2">
          <span class="text-white font-weight-bold">{{'TRACKING' | translate}}</span>
          <label class="switch inline-switch mt-1">
            <input type="checkbox" id="public" value="public" (change)="trackingChecked()"
              [checked]="isTrackingAllowed">
            <span class="slider round"></span>
          </label>
          <span class="p-color-light">{{isTrackingAllowed ? ('AKTIV' | translate) : ('INAKTIV' | translate)}}</span>
        </div>
        <div class="d-flex mb-1 pt-2" *ngIf="showedCookieInformation['Facebook']">
          <div class="text-white" style="width:120px;">Facebook</div>
          <a class="p-color-light ml-sm-4 ml-2 d-flex" (click)="changeShowMore('Facebook')">
            <div class="d-none d-sm-block">{{ !showAllCookieOptions['Facebook'] ?
              ('MEHR_INFORMATIONEN'| translate):('WENIGER_INFORMATIONEN'| translate) }}</div><i
              class="bi bi-chevron-{{ !showAllCookieOptions['Facebook'] ? 'down':'up' }} ml-1"></i>
          </a>
        </div>
        <div class="scroll-cookie-content" *ngIf="showAllCookieOptions['Facebook']">
          <div class="d-flex text-white flex-column flex-sm-row" *ngFor="let x of cookieContent[0]|keyvalue">
            <div class="col col-sm-4 mb-sm-3 text-break">{{x.key}}</div>
            <div class=" col col-sm-8 mb-3 text-break">
              <div *ngIf="!isLink(x.value)">{{x.value}}</div>
              <a class="text-white" href={{x.value}} *ngIf="isLink(x.value)" target="_blank">{{x.value}}</a>
            </div>
          </div>
        </div>
        <div class="d-flex mb-1 pt-1" *ngIf="showedCookieInformation['Google Ads']">
          <div class="text-white" style="width:120px;">Google Ads</div>
          <a class="p-color-light ml-sm-4 ml-2 d-flex" (click)="changeShowMore('Google Ads')">
            <div class="d-none d-sm-block">{{ !showAllCookieOptions['Google Ads'] ?
              ('MEHR_INFORMATIONEN'| translate):('WENIGER_INFORMATIONEN'| translate) }}</div><i
              class="bi bi-chevron-{{ !showAllCookieOptions['Google Ads'] ? 'down':'up' }} ml-1"></i>
          </a>
        </div>
        <div class="scroll-cookie-content" *ngIf="showAllCookieOptions['Google Ads']">
          <div class="d-flex text-white flex-column flex-sm-row" *ngFor="let x of cookieContent[1]|keyvalue">
            <div class="col col-sm-4 mb-sm-3 text-break">{{x.key}}</div>
            <div class="col col-sm-8 mb-3 text-break">
              <div *ngIf="!isLink(x.value)">{{x.value}}</div>
              <a class="text-white" href={{x.value}} *ngIf="isLink(x.value)" target="_blank">{{x.value}}</a>
            </div>
          </div>
        </div>
        <div class="d-flex mb-2 pt-1" *ngIf="showedCookieInformation['Google Analytics']">
          <div class="text-white" style="width:120px;">Google Analytics</div>
          <a class="p-color-light ml-sm-4 ml-2 d-flex" (click)="changeShowMore('Google Analytics')">
            <div class="d-none d-sm-block">{{ !showAllCookieOptions['Google Analytics'] ?
              ('MEHR_INFORMATIONEN'| translate):('WENIGER_INFORMATIONEN'| translate) }}</div><i
              class="bi bi-chevron-{{ !showAllCookieOptions['Google Analytics'] ? 'down':'up' }} ml-1"></i>
          </a>
        </div>
        <div class="scroll-cookie-content" *ngIf="showAllCookieOptions['Google Analytics']">
          <div class="d-flex text-white flex-column flex-sm-row" *ngFor="let x of cookieContent[2]|keyvalue">
            <div class="col col-sm-4 mb-sm-3 text-break">{{x.key}}</div>
            <div class="col col-sm-8 mb-3 text-break">
              <div *ngIf="!isLink(x.value)">{{x.value}}</div>
              <a class="text-white" href={{x.value}} *ngIf="isLink(x.value)" target="_blank">{{x.value}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <div class="row">
          <div class="col-lg-6 text-center text-lg-left">
            <button class="btn btn-light text-primary text-center text-lg-left"
              (click)="cookieSubmit()">{{'SPEICHERN_BEENDEN' |
              translate}}</button>
          </div>
          <div class="col-lg-6 text-center text-lg-right mt-2 mt-lg-0 pt-2">
            <span class="mr-2"><a class="text-white text-decoration-none" target="_blank" href="legaldocs/datenschutz.html">{{'DATENSCHUTZ' | translate}}</a></span>
            <span class="ml-2"><a class="text-white text-decoration-none" target="_blank" href="legaldocs/impressum.html">{{'IMPRESSUM' | translate}}</a></span>
          </div>
        </div>
      </div>
    </div>
  </div>
