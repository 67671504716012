// Angular
import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {HyphenationPatternsService} from '../services/hyphenation-patterns.service';


interface HyphenateOptions {
  onlyNLongest?: number;
  longerThan?: number;
}

/**
 * Sanitize HTML
 */
@Pipe({
  name: 'hyphenate'
})
export class HyphenatePipe implements PipeTransform {

  /**
   * Pipe Constructor
   *
   * @param sanitizer: DomSanitezer
   */
  private hyphenChar = '\u00AD';
  // private hyphenChar = '*';

  constructor(private hyphenater: HyphenationPatternsService, private translate: TranslateService) {
  }

  /**
   * Transform
   *
   */
  transform(text: string, options: HyphenateOptions = {}): string {
    const words = text.split(/\s+/);
    const hyphenateNLongest = Math.min(
      words.length, options.onlyNLongest || words.length
    );
    const hyphenateLongerThan = options.longerThan || 0;

    const wordsToHyphenate = words
      .concat()
      .sort((word1, word2) => word2.length - word1.length)
      .slice(0, hyphenateNLongest)
      .filter(word => word.length > hyphenateLongerThan);

    const currentLanguage = this.translate.currentLang || 'de';
    return words
      .map(word => {
        if (wordsToHyphenate.indexOf(word) !== -1) {
          return this.hyphenater.hyphenate(word, currentLanguage).join(this.hyphenChar);
        }
        return word;
      })
      .join(' ');
  }
}
