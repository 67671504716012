<div class="d-flex justify-content-between facilities">
    <div class="component-container text-center">
        <div class="w-100">
            <img id="dubistpflege-logo" src="../../../../../assets/img/bpa-campaign/dubistpflege-logo.png" alt="">
        </div>
        <div *ngIf="!facilitiesAlreadyConfigured" class="d-flex justify-content-center" style="padding: 0 20px;">
            <div id="configuration-card">
                <div class="heading mb-2">Adress-Daten der teilnehmenden Einrichtungen erfassen</div>
                <div class="accordion mt-4" id="accordionExample">
                    <div class="card" *ngFor="let facility of facilities;let i = index;let last = last;">
                      <div class="card-header" [id]="'heading' + i" style="padding: 0px;">
                        <h2 class="mb-0">
                          <button class="btn btn-link btn-block text-left" [ngStyle]="facility.valid && {'color':'#0eb370'}"
                                    type="button" (click)="toggleCollapse(i)" style="padding: 1.1rem 2rem;">
                                <div class="w-100 d-flex justify-content-between">
                                    <div>
                                        <span *ngIf="!facility.get('company_name')?.value">Einrichtung </span>
                                        <span *ngIf="facility.get('company_name')?.value?.length > 0">{{facility.get("company_name").value}} </span>
                                        <span>({{i+1}}/{{facilities.length}}) </span>
                                        <span style="position: relative;">
                                            <i *ngIf="facility.valid" class="bi bi-check-circle"></i>
                                        </span>
                                    </div>
                                    <div class="position-relative" style="height: 24px;">
                                        <i *ngIf="activeFacilityIndex != i" class="bi bi-chevron-down" style="position: absolute;top: -5px;right: 0;font-size: 22px;"></i>
                                        <i *ngIf="activeFacilityIndex == i" class="bi bi-chevron-up" style="position: absolute;top: -5px;right: 0;font-size: 22px;"></i>
                                    </div>
                                </div>
                                <div *ngIf="facilitiesWithDuplicates[i]?.length > 0" style="color: #d41616;font-size: 14px;">
                                    <span>Die Adress-Daten dieser Einrichtung sind identisch mit </span>
                                    <span *ngIf="facilitiesWithDuplicates[i]?.length == 1">der Einrichtung <span>{{facilitiesWithDuplicates[i][0]}}</span></span>
                                    <span *ngIf="facilitiesWithDuplicates[i]?.length > 1">den Einrichtungen <span *ngFor="let index of facilitiesWithDuplicates[i];let last = last;let first = first">{{last ? " und " : first ? "" : ", "}}{{index}}</span></span>
                                    <span>. Bitte prüfen Sie Ihre Angaben.</span>
                                </div>
                          </button>
                        </h2>
                      </div>
                  
                      <div class="collapse-content" [ngClass]="{'show': activeFacilityIndex == i}">
                        <div class="card-body text-left">
                            <form [formGroup]="facility">
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label for="inputAddress">Unternehmensname<span class="required-form-field">*</span></label>
                                        <input type="text" formControlName="company_name" class="form-control" id="inputAddress" placeholder="Unternehmensname">
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label for="inputPassword4">Unternehmenskategorie<span class="required-form-field">*</span></label>
                                        <select formControlName="company_category" id="inputState" class="form-control">
                                            <option value="" disabled selected hidden style="color: #495057 !important;">Wählen Sie eine Kategorie aus...</option>
                                            <option *ngFor="let category of companyCategories">{{category.name | translate}}</option>
                                          </select>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-3">
                                        <label for="inputEmail4">Postleitzahl<span class="required-form-field">*</span></label>
                                        <input type="text" formControlName="zip" class="form-control" id="inputEmail4" placeholder="Postleitzahl">
                                        <small class="text-danger" *ngIf="facility.get('zip').value && facility.get('zip').errors">
                                            Die Postleitzahl muss fünfstellig sein.
                                        </small>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <label for="inputEmail4">Stadt<span class="required-form-field">*</span></label>
                                        <input type="text" formControlName="city" class="form-control" id="inputEmail4" placeholder="Stadt">
                                    </div>
                                    <div class="form-group col-md-5">
                                        <label for="inputPassword4">Straße & Hausnummer<span class="required-form-field">*</span></label>
                                        <input type="text" formControlName="street" class="form-control" id="inputPassword4" placeholder="Straße & Hausnummer">
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6" style="margin-bottom: 0px !important;">
                                        <label for="inputEmail4">E-Mail zur Auslieferung der Bewerber<span class="required-form-field">*</span></label>
                                        <input type="email" formControlName="application_delivery_email" class="form-control" id="inputEmail4" placeholder="E-Mail">
                                        <small class="text-danger" *ngIf="facility.get('application_delivery_email').errors?.email">Die E-Mail Adresse ist ungültig.</small>
                                    </div>
                                    <div class="form-group col-md-6" style="margin-bottom: 0px !important;">
                                        <label for="inputEmail4">Karriere-Website Link</label>
                                        <input type="text" formControlName="carrer_site_url" class="form-control" id="inputEmail4" placeholder="Link">
                                        <small class="text-danger" *ngIf="facility.get('carrer_site_url').value && facility.get('carrer_site_url').errors">Der Link ist ungültig.</small>
                                    </div>
                                </div>
                            </form>
                            <div class="warning text-center" [ngClass]="{'show': showWarning}" style="z-index: 2;">Bitte füllen Sie alle Felder aus, die mit einem Stern (*) markiert sind.</div>
                            <div class="text-center">
                                <button *ngIf="facility.valid && facilities.length > 1 && !getFacilitiesConfigurationState()" class="btn btn-outline-primary" style="z-index: 3;" (click)="openNextIncompleteFacility()">
                                    <span *ngIf="!last">NÄCHSTE EINRICHTUNG</span>
                                    <span *ngIf="last">ZUR NÄCHSTEN UNVOLLSTÄNDIGEN EINRICHTUNG</span>
                                </button>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="w-100 text-center" style="margin-top: 20px;">
                    <!-- <button class="btn btn-primary" [class.disabled-button]="!getFacilitiesConfigurationState()" (click)="submitFacilitiesConfiguration(submittedFormModal)">ABSENDEN</button> -->
                    <button *ngIf="!getFacilitiesConfigurationState()" class="btn btn-primary" (click)="openNextIncompleteFacility()" style="opacity: 0.6;">EINRICHTUNG<span *ngIf="facilities.length > 1">EN</span> ABSENDEN</button>
                    <button *ngIf="getFacilitiesConfigurationState()" class="btn btn-primary" style="background-color: #0eb370;border-color: #0eb370;" (click)="submitFacilitiesConfiguration(submittedFormModal)">EINRICHTUNG<span *ngIf="facilities.length > 1">EN</span> ABSENDEN</button>
                    <div *ngIf="!facilitiesAlreadyConfigured" class="mt-1">
                        <button class="btn btn-link" (click)="configureLater()" style="text-decoration: underline;color: #8B94AF;">Später erfassen</button>
                        <div class="d-flex justify-content-center">
                            <div class="later-configuration-text mt-1 text-muted">Wenn Sie auf "Später erfassen" klicken, senden wir Ihnen einen Link an <span>{{email}}</span>, über den Sie Ihre Adress-Daten zu einem späteren Zeitpunkt erfassen können.</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <footer class="footer">
        <a href="legaldocs/impressum.html" target="_blank" class="footer-link">Impressum</a>
        <a href="legaldocs/datenschutz.html" target="_blank" class="footer-link">Datenschutz</a>
    </footer>
</div>

<ng-template #submittedFormModal>
    <div id="submitted-form-modal" class="text-center">
        <div class="d-flex justify-content-between">
            <div id="modal-heading">Vielen Dank für Ihre Angaben!</div>
            <i class="bi bi-x pointer" (click)="closeModal()"></i>
        </div>
        <div id="modal-text">
            <span>Sie haben Ihre </span>
            <span *ngIf="mainFacilityData?.number_of_facilities > 1">{{mainFacilityData?.number_of_facilities}} Einrichtungen </span>
            <span *ngIf="mainFacilityData?.number_of_facilities < 2">Einrichtung </span>
            <span>erfolgreich für die Teilnahme an der Recruiting Kampagne angemeldet.</span>
            <div id="check-email-text">
                <div>Die Stellenanzeigen werden automatisch von Ihrer Webseite übernommen. Sollte dies nicht möglich sein, wird unser Support-Team auf Sie zukommen.</div>
                <div style="padding: 0 20px;">Sie werden informiert, sobald Ihre Stellen auf der JobMap live sind.</div>
                <div class="text-muted mt-2">Weitere Informationen erhalten Sie im Onboarding-Webinar. Den Link dazu finden Sie in Ihrer Welcome-Mail.</div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #alreadyConfiguredFormModal>
    <div id="submitted-form-modal" class="text-center">
        <div class="d-flex justify-content-between">
            <div id="modal-heading">Ihre {{mainFacilityData?.number_of_facilities > 1 ? "Einrichtungen wurden" : "Einrichtung wurde"}} bereits konfiguriert!</div>
            <i class="bi bi-x pointer" (click)="closeModal()"></i>
        </div>
        <div id="modal-text">
            <div id="check-email-text">
                <div>Die Stellenanzeigen werden automatisch von Ihrer Webseite übernommen. Sollte dies nicht möglich sein, wird unser Support-Team auf Sie zukommen.</div>
                <div style="padding: 0 20px;">Sie werden informiert, sobald Ihre Stellen auf der JobMap live sind.</div>
                <div class="text-muted mt-2">Weitere Informationen erhalten Sie im Onboarding-Webinar. Den Link dazu finden Sie in Ihrer Welcome-Mail.</div>
            </div>
        </div>
    </div>
</ng-template>