<router-outlet></router-outlet>
<tour-step-template>
  <ng-template let-step="step">
    <div class="tour-step-content" style="margin-bottom: 8px;" [innerHTML]="step?.content"></div>
    <div class="tour-step-navigation">
      <div class="d-flex" style="column-gap: 8px;">
        <!-- <button *ngIf="_tourService.hasPrev(step) && _tourService.currentStep?.stepId != '1'" class="btn btn-sm btn-outline-primary btn-default" (click)="_tourService.prev()" [innerHTML]="step?.prevBtnTitle"></button> -->
        <button *ngIf="_tourService.hasNext(step)" class="btn btn-sm btn-primary btn-default" (click)="_tourService.next()" [innerHTML]="step?.nextBtnTitle"></button>
      </div>
      <button class="btn btn-sm btn-default" (click)="_tourService.end()" style="color: #5223ca;" [innerHTML]="step?.endBtnTitle"></button>
    </div>
  </ng-template>
</tour-step-template>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{'LÄDT' | translate}}...</p>
</ngx-spinner>
<ng-template #cookie let-modal>
  <div>
    <app-cookies></app-cookies>
  </div>
</ng-template>
<ng-template #message let-modal>
  <div>
    <app-messages></app-messages>
  </div>
</ng-template>
