import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MainService} from './@digitalhilft/services/main.service';
import {TourService, IStepOption} from 'ngx-tour-ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent  {
  @ViewChild('cookie') cookie;
  @ViewChild('message') message;

  constructor(private translate: TranslateService, private mainService: MainService, private _tourService: TourService) {
    translate.addLangs(['de', 'en']);
    translate.setDefaultLang('en');
    translate.use('en');
  }

}
