import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes, UrlSerializer} from '@angular/router';
import {MapScreenModule} from './map-screen/map-screen.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {IFrameModeViewComponent} from './i-frame-mode-view.component';
import {MapScreenComponent} from './map-screen/map-screen.component';
import {CookiesComponent} from './@digitalhilft/modals/cookies/cookies.component';
import {MessagesComponent} from './@digitalhilft/modals/messages/messages.component';
import {JobCardComponentModule} from './@digitalhilft/components/job-card/job-card.module';
import {NgxSpinnerModule} from 'ngx-spinner';
import {GoogleTagManagerModule} from 'angular-google-tag-manager';
import {NgbCarouselModule} from '@ng-bootstrap/ng-bootstrap';
import {PipesModule} from './@digitalhilft/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecruitingCampaignComponent } from './@digitalhilft/components/recruiting-campaign/recruiting-campaign.component';
import { FacilitiesComponent } from './@digitalhilft/components/recruiting-campaign/facilities/facilities.component';
import { VerificationComponent } from './@digitalhilft/components/recruiting-campaign/verification/verification.component';
import { BpaCampaignComponent } from './@digitalhilft/components/bpa-campaign/bpa-campaign.component';
import { IntermediatePageComponent } from './@digitalhilft/components/recruiting-campaign/intermediate-page/intermediate-page.component';
import { OptOutOptInComponent } from './@digitalhilft/components/recruiting-campaign/opt-out-opt-in/opt-out-opt-in.component';
import { CustomUrlSerializer } from '../app/@digitalhilft/utils/custom-url-serializer';
import { DemoLinksForwardingPageComponent } from './@digitalhilft/components/recruiting-campaign/demo-links-forwarding-page/demo-links-forwarding-page.component';
import {TourNgBootstrapModule} from 'ngx-tour-ng-bootstrap';

const routes: Routes = [
  { path:'',  component: MapScreenComponent },
  
  { path:':project_name/recruiting-kampagne/registrierung',  component: RecruitingCampaignComponent },
  { path:'recruiting-kampagne/registrierung',  component: RecruitingCampaignComponent },
  { path:'recruiting-kampagne/erfolgreich-registriert',  component: VerificationComponent },
  { path:'recruiting-kampagne/einrichtungen-konfigurieren',  component: FacilitiesComponent },

  { path:':project_name/recruiting-kampagne/webinar-anmeldung',  component: IntermediatePageComponent },
  { path:'recruiting-kampagne/webinar-anmeldung',  component: IntermediatePageComponent },
  { path:':project_name/recruiting-kampagne/update-webinar-anmeldung',  component: IntermediatePageComponent },
  { path:'recruiting-kampagne/update-webinar-anmeldung',  component: IntermediatePageComponent },
  { path:':project_name/recruiting-kampagne/onboarding-anmeldung',  component: IntermediatePageComponent },
  { path:'recruiting-kampagne/onboarding-anmeldung',  component: IntermediatePageComponent },

  // Now the BpaCampaignComponent will redirect the user to the RecruitingCampaignComponent
  { path:'bpa-recruiting-kampagne/registrierung',  component: BpaCampaignComponent },
  { path:'bpa-recruiting-kampagne/erfolgreich-registriert',  component: VerificationComponent },
  { path:'bpa-recruiting-kampagne/einrichtungen-konfigurieren',  component: FacilitiesComponent },
  { path:'bpa-recruiting-kampagne/webinar-anmeldung',  component: IntermediatePageComponent },
  { path:'bpa-recruiting-kampagne/update-webinar-anmeldung',  component: IntermediatePageComponent },
  { path:'bpa-recruiting-kampagne/onboarding-anmeldung',  component: IntermediatePageComponent },

  { path:':project_name/recruiting-kampagne/opt-out',  component: OptOutOptInComponent },
  { path:'recruiting-kampagne/opt-out',  component: OptOutOptInComponent },
  { path:':project_name/recruiting-kampagne/opt-in',  component: OptOutOptInComponent },
  { path:'recruiting-kampagne/opt-in',  component: OptOutOptInComponent },
  
  { path:'recruiting-kampagne/demo-links',  component: DemoLinksForwardingPageComponent },

  { path:':main_company',  component: MapScreenComponent },
  { path:':main_company/:company',  component: MapScreenComponent },
  { path:'embed/:main_company',  component:IFrameModeViewComponent },
  { path:'embed/:main_company/:company',  component:IFrameModeViewComponent },
  { path: '**', component: MapScreenComponent },
  ];

function getParameterByName(name) {
  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}
export const TAG_MANAGER_CONFIGS = {
  ggl1: "GTM-WXNZQNP",
  wndk1: "GTM-56XCTQP"
};

let googleTagManagerId = '';
for(const i in TAG_MANAGER_CONFIGS){
  if(getParameterByName('cmpId') == i)
    googleTagManagerId = TAG_MANAGER_CONFIGS[i];
}

@NgModule({
  imports: [
      CommonModule,
      BrowserModule,
      BrowserAnimationsModule,
      RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
      HttpClientModule,
      MapScreenModule,
      TranslateModule.forRoot(
          {
              loader: {
                  provide: TranslateLoader,
                  useFactory: httpLoaderFactory,
                  deps: [HttpClient]
              }
          }
      ),
      JobCardComponentModule,
    NgxSpinnerModule,
    GoogleTagManagerModule.forRoot({
      id: googleTagManagerId,
    }),
    PipesModule,
    NgbCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    TourNgBootstrapModule.forRoot()
  ],
  declarations: [
    AppComponent,
    IFrameModeViewComponent,
    CookiesComponent,
    MessagesComponent,
    RecruitingCampaignComponent,
    FacilitiesComponent,
    VerificationComponent,
    BpaCampaignComponent,
    IntermediatePageComponent,
    OptOutOptInComponent,
    DemoLinksForwardingPageComponent
  ],
  providers: [
    {
      provide: UrlSerializer,
      useClass: CustomUrlSerializer
    }
  ],
  exports: [
  ],
  bootstrap: [AppComponent],
  entryComponents:[CookiesComponent]
})
export class AppModule {
}

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
