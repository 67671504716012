import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BpaService {

  constructor(private _http: HttpClient) { }

  register(data: any): Observable<any> {
    return this._http.post(environment.apiUrl + "/auth/bpa-register", data);
  }

  orderVerificationEmail(email: string, order_id: string): Observable<any> {
    return this._http.post(environment.apiUrl + "/auth/bpa-notifications", {email: email, order_id: order_id, operation: "verify_order"});
  }

  sendBillInfoLater(email: string, order_id: string): Observable<any> {
    return this._http.post(environment.apiUrl + "/auth/bpa-notifications", {email: email, order_id: order_id, operation: "bill_to_later"});
  }

  registerFacilitiesLater(email: string, order_id: string): Observable<any> {
    return this._http.post(environment.apiUrl + "/auth/bpa-notifications", {email: email, order_id: order_id, operation: "configure_facilities_later"});
  }

  sendWelcomeEmail(email: string, order_id: string): Observable<any> {
    return this._http.post(environment.apiUrl + "/auth/bpa-notifications", {email: email, order_id: order_id, operation: "welcome_mail"});
  }
  
  getMainFacilityData(email: string, order_id: string): Observable<any> {
    return this._http.get(environment.apiUrl + "/auth/bpa-main-facility-data", {params: {email: email, order: order_id}});
  }

  verifyRegistration(email: string, order_id: string): Observable<any> {
    return this._http.post(environment.apiUrl + "/auth/verify-bpa-registration", {email: email, order_id: order_id});
  }
  
  registerFacilities(data: any): Observable<any> {
    return this._http.post(environment.apiUrl + "/auth/bpa-register-facilities", data);
  }
  
  bpaBusinessLog(order_id: string, session_id: string, operation: string, project: string, email: string = null): Observable<any> {
    return this._http.post(environment.apiUrl + "/auth/bpa-business-log", {order_id: order_id, session_id: session_id, operation: operation, project: project, email: email});
  }

  getSubscriptionDetailsByEmail(email: string, project?: string): Observable<any> {
    return this._http.get(environment.apiUrl + `/auth/subscription?email=${email}` + (project ? `&project=${project}` : ""));
  }
  
  setSubscriptionOperation(data: any): Observable<any> {
    return this._http.post(environment.apiUrl + "/auth/subscription", data);
  }

  getProjectConfig(project: string): Observable<any> {
    return this._http.get(environment.apiUrl + `/auth/get-project-config?project=${project}`);
  }
  
  getFacilityDemoLinksStatus(facilityID: string): Observable<any> {
    return this._http.get(environment.apiUrl + `/auth/facility-media-status?facility_id=${facilityID}`);
  }

  rejectFacilityDemoLinks(facilityID: any): Observable<any> {
    return this._http.put(environment.apiUrl + "/auth/reject-facility-media", {}, {params:{facility_id: facilityID}});
  }
  
  confirmFacilityDemoLinks(facilityID: any, confirmedBy?: any): Observable<any> {
    return this._http.put(environment.apiUrl + "/auth/confirm-facility-media", {}, {params:{facility_id: facilityID, confirmed_by: confirmedBy}});
  }
}
