<div class="sections-wrapper">
    <div class="section-1-wrapper">
        <div class="section-1">
            <div id="section-1-part-1">
                <div id="section-1-heading">Registrieren Sie sich jetzt zur Teilnahme an</div>
                <img id="dubistpflege-logo" src="../../../../assets/img/recruiting-campaign/dubistpflege-logo.png" alt="">
                <img id="campaign-images" src="../../../../assets/img/recruiting-campaign/campaign-images.png" alt="">
            </div>
            <div id="section-1-part-2">
                <div class="w-100 text-right project-and-logon-logo-wrapper">
                    <img *ngIf="projectLogo" class="logo" style="max-width: calc(100% - 240px)" [src]="projectLogo" alt="">
                    <img *ngIf="!hideLogonLogo" class="logo logon-logo" src="../../../../assets/img/recruiting-campaign/logon.png" alt="">
                </div>
                <div id="section-2">

                    <!-- SECTION 1 -->
                    <div *ngIf="currentForm == 'emailForm'">
                        <form [formGroup]="emailForm" (ngSubmit)="selectForm('furtherInformationForm')" style="margin-top: 1.7vw;">
                            <div class="d-flex justify-content-between">
                                <div class="campaign-description">
                                    <div id="dubistpflege-text">#DubistPflege -</div>
                                    <div>jetzt direkt teilnehmen und mit wenigen Klicks anmelden.</div>
                                </div>
                                <div *ngIf="project == 'bpa'" style="height: 100%;width: 120px;position: relative;">
                                    <div id="bpa-sonderkonditionen">bpa<br>Sonder-<br>konditionen<br>sichern!</div>
                                </div>
                            </div>
                            <div class="d-flex mt-3 email-teilnehmen-wrapper">
                                <div *ngIf="!emailForm.valid" id="teilnehmen-button-1" (click)="participateNowWithoutEmail()">JETZT TEILNEHMEN<i class="bi bi-chevron-right"></i></div>
                                <button *ngIf="emailForm.valid" id="teilnehmen-button-2" type="submit">JETZT TEILNEHMEN<i class="bi bi-chevron-right"></i></button>
                                <div class="w-100">
                                    <input class="form-control" style="min-width: 220px;" formControlName="email" type="email" placeholder="E-Mail">
                                    <small class="text-warning" *ngIf="emailForm.get('email').errors?.email">Die E-Mail Adresse ist ungültig.</small>
                                </div>
                            </div>
                        </form>
                        <!-- FAQ -->
                        <div style="margin-top: 10px">
                            <div class="w-100 collapse-buttons">
                                <div class="collapse-button w-100">
                                    <div style="width: 100%;display: flex;justify-content: space-between;" data-toggle="collapse" href="#collapseFaq" role="button" aria-expanded="{{faqToggleActive}}" aria-controls="collapseFaq" (click)="onToggleClick('faq')">
                                        <div>Antworten auf häufig gestellte Fragen</div>
                                        <i class="bi bi-chevron-down"></i>
                                    </div>
                                </div>
                            </div>
                            <div id="collapseGroup">
                                <div class="collapse show" id="collapseFaq" data-parent="#collapseGroup">
                                    <div class="card card-body" id="faq-wrapper">
                                        <div *ngFor="let faqItem of faqList;let i = index">
                                            <div class="faq-collapse-button w-100" style="padding: 5px 8px;">
                                                <div class="w-100 d-flex justify-content-between faq-question" data-toggle="collapse" [attr.href]="'#collapseQuestion' + i" role="button"
                                                [attr.aria-expanded]="faqItem.selected" [attr.aria-controls]="'collapseQuestion' + i" (click)="onToggleClick('faq-question', i)">
                                                {{faqItem.question}}
                                                <div style="width: 25px;position: relative;">
                                                    <i class="bi bi-plus" style="position:absolute;top: -4px;right: 0;font-size: 21px;" [ngStyle]="faqItem.selected && {'transform':'rotate(45deg)'}"></i>
                                                </div>
                                                </div>
                                            </div>
                                            <div class="collapse faq-collapse" [attr.id]="'collapseQuestion' + i">
                                                <div class="card card-body faq-card-body">
                                                    <div *ngIf="i == 0" style="font-size: 15px;color: #E6E6E6;">
                                                        <div *ngIf="pricePackages?.length > 1">Die folgenden Preismodelle können am Ende der Registrierung ausgewählt werden:</div>
                                                        <div *ngIf="pricePackages?.length == 1">Mit der Registrierung buchen Sie das folgende Preismodell:</div>
                                                        <div style="padding-left: 10px;margin-bottom: 4px;margin-top: 2px;">
                                                            <div *ngFor="let package of pricePackages">
                                                                <input type="radio" [value]="package.id" [(ngModel)]="selectedPricePackageId" (click)="selectedPricePackageId = package.id">
                                                                <span class="ml-2 pointer faq-package" (click)="selectedPricePackageId = package.id">Laufzeit: {{package.valid_time}} {{package.valid_time_unit}}&nbsp;&nbsp;-&nbsp;&nbsp;Preis: {{package.price_amount}},- {{package.price_currency}} netto pro teilnehmende Einrichtung</span>
                                                            </div>
                                                        </div>
                                                        <div class="text-center">
                                                            <button *ngIf="!emailForm.valid" id="teilnehmen-button-1" style="margin: 8px 0px;font-size: 13px !important;" (click)="participateNowFromFAQ()">JETZT TEILNEHMEN<i class="bi bi-chevron-right"></i></button>
                                                        </div>
                                                        <span>{{pricePackages?.length > 1 ? "Jedes" : "Das"}} Preismodell beinhaltet: Medien- und Agenturkosten, automatische Übernahme der Stellen in die JobMap. Auf Wunsch kann die Laufzeit verlängert werden. Es werden keine weiteren Kosten fällig.</span>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <span *ngIf="i != 0">{{faqItem.answer}} <span *ngIf="i == '4'" id="webinar-link" (click)="openWebinarRegistrationInNewTab()">{{environment.dubistpflegeUrl}}{{project != "general" ? project + "/" : ""}}recruiting-kampagne/webinar-anmeldung</span></span>
                                                        </div>
                                                        <div class="text-center">
                                                            <a *ngIf="faqItem.question == 'Wie funktioniert #DubistPflege?'" class="btn btn-primary mt-2 mb-2" style="font-size: 13px;" href="assets/pdf/dubistpflege_onepager.pdf" target="_blank">ZUR PRODUKTINFORMATION</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr *ngIf="i+1 != faqList.length" style="margin: 6px 0px 0px;background-color: rgba(255, 255, 255, 0.13);">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="usage-terms pt-1 pb-2">Mit der Registrierung stimmen Sie unseren <a href="assets/pdf/T&C.pdf" target="_blank">Allgemeinen Geschäftsbedingungen</a>, unseren <a href="assets/pdf/TermsOfUse.pdf" target="_blank">Nutzungsbedingungen</a>, der <a href="assets/pdf/produktblatt.pdf" target="_blank">Leistungsbeschreibung</a> und unserer <a href="legaldocs/datenschutz.html" target="_blank">Datenschutzerklärung</a> zu.</div>
                    </div>

                    <!-- SECTION 2 -->
                    <form *ngIf="currentForm == 'furtherInformationForm'" [formGroup]="furtherInformationForm" (ngSubmit)="registerEmail()" class="form-background text-muted">
                        <div class="section-2-heading" [ngStyle]="furtherInformationForm.valid && {'color':'#0eb370'}">
                            <div>Fügen Sie hier die wichtigsten Informationen ein.</div>
                            <div style="position: relative;min-width: 31px;">
                                <i *ngIf="furtherInformationForm.valid" class="bi bi-check-circle ml-2" style="font-size: 23px;"></i>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="inputEmail4">E-Mail<span class="required-form-field">*</span></label>
                            <input type="email" formControlName="email" class="form-control" id="inputEmail4" placeholder="E-Mail">
                            <small class="text-danger" *ngIf="furtherInformationForm.get('email').errors?.email">Die E-Mail Adresse ist ungültig.</small>
                        </div>
                        <div class="form-group">
                            <label for="inputEmail4">Anzahl der teilnehmenden Einrichtungen<span class="required-form-field">*</span></label>
                            <input type="text" formControlName="number_of_facilities" class="form-control" id="inputEmail4" placeholder="Anzahl">
                            <small class="text-danger" *ngIf="furtherInformationForm.get('number_of_facilities').value && furtherInformationForm.get('number_of_facilities').errors">
                                In diesem Feld sind nur Nummern größer als 0 erlaubt.
                            </small>
                        </div>
                        <div *ngIf="membershipOrganisations.length > 0 && project != 'bpa'" class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputPassword4">Mitglied bei</label>
                                <select formControlName="membership_organisation" id="inputState" class="form-control">
                                    <option value="" disabled selected hidden style="color: #495057 !important;">Wählen Sie eine Organisation aus...</option>
                                    <option [selected]="furtherInformationForm.get('membership_organisation').value === organisation.name" *ngFor="let organisation of membershipOrganisations">{{organisation.name | translate}}</option>
                                  </select>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputPassword4">Mitgliedsnummer</label>
                                <input type="text" formControlName="membership_nr" class="form-control" id="inputPassword4" placeholder="Mitgliedsnummer">
                            </div>
                        </div>
                        <div *ngIf="project == 'bpa'" class="form-row">
                            <div class="form-group col-md-12">
                                <label for="inputPassword4"><span style="color: #005f9c;">b</span><span style="color: #63b9e9;">p</span><span style="color: #005f9c;">a</span> Mitgliedsnummer (optional)</label>
                                <input type="text" formControlName="membership_nr" class="form-control" id="inputPassword4" placeholder="Mitgliedsnummer">
                            </div>
                        </div>
                        <div class="w-100 text-center">
                            <button type="submit" [disabled]="!furtherInformationForm.valid" [class.disabled-button]="!furtherInformationForm.valid" class="btn btn-primary mt-2">WEITER</button>
                        </div>
                    </form>

                    <!-- SECTION 3 -->
                    <form *ngIf="currentForm == 'companyDetailsForm'" [formGroup]="companyDetailsForm" (ngSubmit)="submitRegistration()" class="form-background text-muted">
                        <div class="section-2-heading" [ngStyle]="companyDetailsForm.valid && {'color':'#0eb370'}">
                            <div>Fügen Sie hier Ihre Unternehmensdaten ein.</div>
                            <div style="position: relative;min-width: 31px;">
                                <i *ngIf="companyDetailsForm.valid" class="bi bi-check-circle ml-2" style="font-size: 23px;"></i>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="inputAddress">Unternehmensname<span class="required-form-field">*</span></label>
                            <input type="text" formControlName="company_name" class="form-control" id="inputAddress" placeholder="Unternehmensname">
                        </div>
                        <div class="form-row">
                            <div class="form-group col-12 col-lg-5">
                                <label for="inputEmail4">Postleitzahl<span class="required-form-field">*</span></label>
                                <input type="text" formControlName="zip" class="form-control" id="inputEmail4" placeholder="Postleitzahl">
                                <small class="text-danger" *ngIf="companyDetailsForm.get('zip').value && companyDetailsForm.get('zip').errors">
                                    Die Postleitzahl muss fünfstellig sein.
                                </small>
                            </div>
                            <div class="form-group col-12 col-lg-7">
                                <label for="inputEmail4">Stadt<span class="required-form-field">*</span></label>
                                <input type="text" formControlName="city" class="form-control" id="inputEmail4" placeholder="Stadt">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="inputPassword4">Straße & Hausnummer / Postfach<span class="required-form-field">*</span></label>
                            <input type="text" formControlName="street" class="form-control" id="inputPassword4" placeholder="Straße & Hausnummer / Postfach">
                        </div>
                        <div *ngIf="furtherInformationForm.get('number_of_facilities').value == 1" class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="facilityDataSameCheck" (click)="onFacilitySameAsCompanyCheckboxChange($event)">
                                <label class="form-check-label" for="facilityDataSameCheck">Einrichtungsdaten sind identisch mit Unternehmensdaten.</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="inputEmail4">Kontaktperson<span class="required-form-field">*</span></label>
                            <input type="text" formControlName="contact_name" class="form-control" id="inputEmail4" placeholder="Vorname und Nachname">
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputEmail4">E-Mail<span class="required-form-field">*</span></label>
                                <input type="email" formControlName="contact_email" class="form-control" id="inputEmail4" placeholder="E-Mail">
                                <small class="text-danger" *ngIf="companyDetailsForm.get('contact_email').errors?.email">Die E-Mail Adresse ist ungültig.</small>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputPassword4">Telefonnummer (optional)</label>
                                <input type="text" formControlName="contact_phone" class="form-control" id="inputPassword4" placeholder="Telefonnummer">
                            </div>
                        </div>
                        <div class="form-group" [class.disabled-button]="!companyDetailsForm.valid">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="gridCheck" (click)="onCheckboxChange($event)" #invoiceCheckbox>
                                <label class="form-check-label" for="gridCheck">Abweichende Rechnungsadresse.</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="inputAddress">Kostenstelle / Kundenzeichen (optional)</label>
                            <input type="text" formControlName="internal_ref_id" class="form-control" id="inputAddress" placeholder="Kostenstelle / Kundenzeichen">
                        </div>
                        <div class="w-100 text-center">
                            <button class="btn btn-primary" type="submit" [disabled]="!companyDetailsForm.valid" [class.disabled-button]="!companyDetailsForm.valid">{{pricePackages?.length > 1 || invoiceCheckboxIsChecked ? "WEITER" : "ABSENDEN"}}</button>
                        </div>
                        <div *ngIf="!(pricePackages?.length > 1 || invoiceCheckboxIsChecked)" class="usage-terms-form pt-1 pb-2">Mit der Registrierung stimmen Sie unseren <a href="assets/pdf/T&C.pdf" target="_blank">Allgemeinen Geschäftsbedingungen</a>, unseren <a href="assets/pdf/TermsOfUse.pdf" target="_blank">Nutzungsbedingungen</a>, der <a href="assets/pdf/produktblatt.pdf" target="_blank">Leistungsbeschreibung</a> und unserer <a href="legaldocs/datenschutz.html" target="_blank">Datenschutzerklärung</a> zu.</div>
                    </form>

                    <!-- SECTION 4 -->
                    <div *ngIf="currentForm == 'pricePackageForm'" class="form-background text-muted">
                        <div class="section-2-heading">
                            <div>Wählen Sie Ihr Paket aus.</div>
                        </div>
                        <div class="price-package-card-wrapper">
                            <div class="price-package-card" *ngFor="let package of pricePackages"
                                [class.selected-price-package]="package.id == selectedPricePackageId"
                                (click)="selectedPricePackageId = package.id">
                                <div class="d-flex align-items-center">
                                    <input type="radio" [value]="package.id" [(ngModel)]="selectedPricePackageId" (click)="selectedPricePackageId = package.id">
                                </div>
                                <div>
                                    <div style="font-size: 19px;font-weight: 500;margin-bottom: -4px;">{{package.name}}</div>
                                    <div style="font-size: 17px;font-weight: 400;">Laufzeit: {{package.valid_time}} {{package.valid_time_unit}}</div>
                                    <div style="font-size: 17px;font-weight: 400;line-height: 1.3;margin-bottom: 3px;">Preis: {{package.price_amount}},- {{package.price_currency}} netto pro teilnehmende Einrichtung</div>
                                    <!-- package description: -->
                                    <!-- <div *ngIf="package.id == selectedPricePackageId" style="line-height: 1.3;font-size: 14px;font-weight: 300;" [ngStyle]="package.id == selectedPricePackageId && {'font-weight': '400', 'color': '#f1f8fe'}">{{package.description}}</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="w-100 text-center mt-3">
                            <button class="btn btn-primary" (click)="submitRegistration()">{{invoiceCheckboxIsChecked ? "WEITER" : "ABSENDEN"}}</button>
                        </div>
                        <div *ngIf="!invoiceCheckboxIsChecked" class="usage-terms-form pt-1 pb-2">Mit der Registrierung stimmen Sie unseren <a href="assets/pdf/T&C.pdf" target="_blank">Allgemeinen Geschäftsbedingungen</a>, unseren <a href="assets/pdf/TermsOfUse.pdf" target="_blank">Nutzungsbedingungen</a>, der <a href="assets/pdf/produktblatt.pdf" target="_blank">Leistungsbeschreibung</a> und unserer <a href="legaldocs/datenschutz.html" target="_blank">Datenschutzerklärung</a> zu.</div>
                    </div>

                    <!-- SECTION 5 -->
                    <form *ngIf="currentForm == 'invoiceForm'" [formGroup]="invoiceForm" (ngSubmit)="submitRegistration()" class="form-background text-muted">
                        <div class="section-2-heading" [ngStyle]="invoiceForm.valid && {'color':'#0eb370'}">
                            <div>Fügen Sie hier Ihre Rechnungsdaten ein.</div>
                            <div style="position: relative;min-width: 31px;">
                                <i *ngIf="invoiceForm.valid" class="bi bi-check-circle ml-2" style="font-size: 23px;"></i>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end mb-2">
                            <div class="btn btn-outline-primary" (click)="sendBillInfoLater()">Rechnungsadresse später mitteilen</div>
                        </div>
                        <div>
                            <div class="form-group">
                                <label for="inputAddress">Unternehmensname<span class="required-form-field">*</span></label>
                                <input type="text" formControlName="bill_to_company_name" class="form-control" id="inputAddress" placeholder="Unternehmensname">
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputEmail4">Postleitzahl<span class="required-form-field">*</span></label>
                                    <input type="text" formControlName="bill_to_zip" class="form-control" id="inputEmail4" placeholder="Postleitzahl">
                                    <small class="text-danger" *ngIf="invoiceForm.get('bill_to_zip').value && invoiceForm.get('bill_to_zip').errors">
                                        Die Postleitzahl muss fünfstellig sein.
                                    </small>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputEmail4">Stadt<span class="required-form-field">*</span></label>
                                    <input type="text" formControlName="bill_to_city" class="form-control" id="inputEmail4" placeholder="Stadt">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="inputPassword4">Straße & Hausnummer / Postfach<span class="required-form-field">*</span></label>
                                <input type="text" formControlName="bill_to_street" class="form-control" id="inputPassword4" placeholder="Straße & Hausnummer / Postfach">
                            </div>
                            <div class="form-group">
                                <label for="inputEmail4">Kontaktperson<span class="required-form-field">*</span></label>
                                <input type="text" formControlName="bill_to_name" class="form-control" id="inputEmail4" placeholder="Vorname und Nachname">
                            </div>
                            <!-- <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputEmail4">E-Mail<span class="required-form-field">*</span></label>
                                    <input type="email" formControlName="bill_to_email" class="form-control" id="inputEmail4" placeholder="E-Mail">
                                    <small class="text-danger" *ngIf="invoiceForm.get('bill_to_email').errors?.email">Die E-Mail Adresse ist ungültig.</small>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputPassword4">Telefonnummer (optional)</label>
                                    <input type="text" formControlName="bill_to_phone" class="form-control" id="inputPassword4" placeholder="Telefonnummer">
                                </div>
                            </div> -->
                            <div class="form-group">
                                <label for="inputPassword4">Telefonnummer (optional)</label>
                                <input type="text" formControlName="bill_to_phone" class="form-control" id="inputPassword4" placeholder="Telefonnummer">
                            </div>
                            <div class="form-group">
                                <label for="inputAddress">Kostenstelle / Kundenzeichen (optional)</label>
                                <input type="text" formControlName="internal_ref_id" class="form-control" id="inputAddress" placeholder="Kostenstelle / Kundenzeichen">
                            </div>
                        </div>
                        <div class="w-100 text-center">
                            <button class="btn btn-primary" type="submit" [disabled]="!invoiceForm.valid" [class.disabled-button]="!invoiceForm.valid">ABSENDEN</button>
                        </div>
                        <div class="usage-terms-form pt-1 pb-2">Mit der Registrierung stimmen Sie unseren <a href="assets/pdf/T&C.pdf" target="_blank">Allgemeinen Geschäftsbedingungen</a>, unseren <a href="assets/pdf/TermsOfUse.pdf" target="_blank">Nutzungsbedingungen</a>, der <a href="assets/pdf/produktblatt.pdf" target="_blank">Leistungsbeschreibung</a> und unserer <a href="legaldocs/datenschutz.html" target="_blank">Datenschutzerklärung</a> zu.</div>
                    </form>

                </div>
            </div>
        </div>
    </div>
    <footer class="footer">
        <a href="legaldocs/impressum.html" target="_blank" class="footer-link">Impressum</a>
        <a href="legaldocs/datenschutz.html" target="_blank" class="footer-link">Datenschutz</a>
    </footer>
</div>

<ng-template #verificationLinkSentModal>
    <div id="order-verified-form-modal" class="text-center">
        <div class="d-flex justify-content-between">
            <div class="modal-heading">WIR HABEN IHNEN EINE BESTÄTIGUNGSMAIL GESCHICKT!</div>
            <i class="bi bi-x pointer" (click)="closeModal()"></i>
        </div>
        <div class="modal-text">Bitte bestätigen Sie Ihre Teilnahme nach Abschluss der Registrierung, indem Sie auf den "Registrierung bestätigen" Button in der E-Mail klicken (<span id="contact-email">{{email}}</span>).</div>
        <div class="d-flex justify-content-center">
            <button class="btn btn-primary" (click)="closeModal()">FORTFAHREN</button>
        </div>
    </div>
</ng-template>

<ng-template #submittedFormModal>
    <div id="submitted-form-modal" class="text-center">
        <div class="d-flex justify-content-between">
            <div class="modal-heading">Vielen Dank für Ihre Angaben!</div>
            <i class="bi bi-x pointer" (click)="closeModal()"></i>
        </div>
        <div *ngIf="!isEmailVerified" class="modal-text">Bitte bestätigen Sie jetzt Ihre Teilnahme, indem Sie auf den "Registrierung bestätigen" Button in der E-Mail klicken (<span id="contact-email">{{email}}</span>).
            <span *ngIf="configureFacilitiesAfterValidation == 1">Nach der Bestätigung erhalten Sie einen Link, über den Sie Ihre Einrichtung(en) eintragen können.</span></div>
        <div *ngIf="billInfoWillBeSentLater" class="modal-text">Wir werden Sie bezüglich Ihrer Rechnungsdaten kontaktieren.</div>
        <div *ngIf="!isEmailVerified" id="check-email-text" class="text-muted">Bitte überprüfen Sie Ihren Posteingang. Überprüfen Sie auch Ihren Spam-Ordner, falls Sie in den nächsten Minuten keine E-Mail erhalten haben.</div>
        <div *ngIf="isEmailVerified">
            <div id="modal-text">
                <div id="check-email-text">
                    <div>Die Stellenanzeigen werden automatisch von Ihrer Webseite übernommen. Sollte dies nicht möglich sein, wird unser Support-Team auf Sie zukommen.</div>
                    <div style="padding: 0 20px;">Sie werden informiert, sobald Ihre Stellen auf der JobMap live sind.</div>
                    <div class="text-muted mt-2">Weitere Informationen erhalten Sie im Onboarding-Webinar. Den Link dazu finden Sie in Ihrer Welcome-Mail.</div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #orderVerifiedModal>
    <div id="order-verified-form-modal" class="text-center">
        <div class="d-flex justify-content-between">
            <div class="modal-heading">Sie haben Ihre E-Mail erfolgreich bestätigt!</div>
            <i class="bi bi-x pointer" (click)="closeModal()"></i>
        </div>
        <div class="modal-text">Bitte schließen Sie Ihre Registrierung ab.</div>
        <div class="d-flex justify-content-center">
            <button class="btn btn-primary" (click)="closeModal()">FORTFAHREN</button>
        </div>
    </div>
</ng-template>
