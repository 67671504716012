import { Component, Input, OnInit } from '@angular/core';
import { MainService } from '../../services/main.service';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import {COOKIE_CONTENT} from '../../utils/constans';
import {TAG_MANAGER_CONFIGS} from '../../../app.module';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {
  @Input('type') einstellungun: any;

  isTrackingAllowed: boolean = true;
  cmpId: any;

  cookieContent = COOKIE_CONTENT;
  showedCookieInformation = { "Facebook": false, "Google Ads": false, "Google Analytics": false };
  allowedCookies = { "Facebook": false, "Google Ads": false, "Google Analytics": false };
  showAllCookieOptions = { "Facebook": false, "Google Ads": false, "Google Analytics": false };

  constructor(private mainService: MainService,
    private activeRoute: ActivatedRoute,
    public activeModal: NgbActiveModal,
    public gtmService: GoogleTagManagerService
  ) {}

  ngOnInit(): void {
    this.cmpId = this.activeRoute.snapshot.queryParams?.cmpId ? this.activeRoute.snapshot.queryParams?.cmpId : "default";
    this.isTrackingAllowed = this.mainService.consentEnabled(this.cmpId);

    this.showCookieInformation();
  }

  cookieSubmit() {
    const cmpId = this.activeRoute.snapshot.queryParams?.cmpId ? this.activeRoute.snapshot.queryParams?.cmpId : "default";
    if (this.isTrackingAllowed) {
      this.mainService.allowCookies(cmpId);
      if (TAG_MANAGER_CONFIGS[cmpId]) {
        this.gtmService.addGtmToDom();
        console.log('Add gtm!');
      }
    } else {
      let refresh = false;
      if (this.mainService.consentEnabled(cmpId)) {
        refresh = true;
      }
      this.mainService.forbidCookies(cmpId);
      if (refresh) {
        location.reload();
      }
    }
    this.activeModal.close();
  }

  trackingChecked() {
    this.isTrackingAllowed = !this.isTrackingAllowed;
  }

  allowCookie() {
    const cmpId = this.activeRoute.snapshot.queryParams?.cmpId ? this.activeRoute.snapshot.queryParams?.cmpId : "default";
    this.mainService.allowCookies(cmpId);
    if (TAG_MANAGER_CONFIGS[cmpId]) {
      this.gtmService.addGtmToDom();
      console.log('Add gtm!');
    }
    this.activeModal.close();
  }

  onClose() {
    this.activeModal.close()
  }

  changeShowMore(name) {
    Object.keys(this.showAllCookieOptions).map(key => {
      this.showAllCookieOptions[key] = key === name ? !this.showAllCookieOptions[key] : false
    });
  }

  isLink(value) {
    return value.startsWith('http');
  }

  showCookieInformation() {
    if(this.cmpId?.includes('lgnt') || this.cmpId == "default") {
      this.showedCookieInformation["Facebook"]=true; // only fb
    }

    switch (this.cmpId) {
      case 'ggl1':
      case 'ggl2':
        Object.keys(this.showedCookieInformation).map(key => this.showedCookieInformation[key] = true);
        break;

      default:
        break;
    }
  }
}
