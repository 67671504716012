import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BpaService } from '../../../../@digitalhilft/services/bpa.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-opt-out-opt-in',
  templateUrl: './opt-out-opt-in.component.html',
  styleUrls: ['./opt-out-opt-in.component.scss']
})
export class OptOutOptInComponent implements OnInit {

  constructor(private _route: ActivatedRoute,
              private _router: Router,
              private _bpaService: BpaService) { }

  landingPageBaseUrl: string;
  project: string;
  isOptOutUrl: boolean;
  email: string;
  subscriber: any;
  initialOperationState: string;

  ngOnInit(): void {
    if (this._route.snapshot.url[0].path != "recruiting-kampagne") {
      this.project = this._route.snapshot.url[0].path;
      this.landingPageBaseUrl = `/${this.project}/recruiting-kampagne`;
      this.isOptOutUrl = this._route.snapshot.url[2].path == "opt-out";
    } else {
      this.project = "general";
      this.landingPageBaseUrl = "/recruiting-kampagne"
      this.isOptOutUrl = this._route.snapshot.url[1].path == "opt-out";
    }

    this.email = this._route.snapshot.queryParamMap.get("email");

    if (this.email) {
      this._bpaService.getSubscriptionDetailsByEmail(this.email, (this.project != "general" ? this.project : null)).subscribe(res => {
        if (res) {
          this.initialOperationState = res.operation;
          this.subscriber = _.cloneDeep(res);
          if (this.isOptOutUrl) {
            this.optOutOfNewsletter();
          } else {
            if (this.initialOperationState == "opt-out") {
              this.signUpForNewsletter();
            }
          }
        } else {
          if (this.isOptOutUrl) {
            //this.email = null;
            // see 28.08.2023 fixing opt-out for non existing users
            this.initialOperationState ="opt-in";
            this.subscriber = {
              email: this.email,
              project: this.project,
              operation: "opt-out"
            }
            this.optOutOfNewsletter();
          } else {
            this.subscriber = {
              email: this.email,
              project: this.project,
              operation: "opt-in"
            }
            this.signUpForNewsletter();
          }
        }
      });
    }
  }

  optOutOfNewsletter() {
   // if (this.initialOperationState == "opt-in") {
      this.subscriber.operation = "opt-out";
      this._bpaService.setSubscriptionOperation(this.subscriber).subscribe();
   // }
  }

  signUpForNewsletter() {
    this.subscriber.operation = "opt-in";
    this._bpaService.setSubscriptionOperation(this.subscriber).subscribe(res => {
      this._router.navigateByUrl(this.landingPageBaseUrl + "/opt-in?email=" + this.email);
    });
  }

}
