<div class="sections-wrapper">
    <div class="component-container text-center">
        <div class="w-100">
            <img id="dubistpflege-logo" src="../../../../../assets/img/bpa-campaign/dubistpflege-logo.png" alt="">
        </div>
        <div class="d-flex justify-content-center">
            <div id="custom-card">
                <div *ngIf="!entityNotFound">
                    <div class="facility-name text-muted">{{facilityName}}</div>
                    <div class="heading">{{ demoLinksConfirmedAt || demoLinksRejectedAt ? "Sie haben Ihre Antwort bereits abgesendet" : "Vielen Dank für Ihre Antwort!" }}</div>
                    <div *ngIf="demoLinksRejectedAt || (!demoLinksRejectedAt && !demoLinksConfirmedAt && operation == 'reject')" class="main-text" style="margin-top: 7px;">Unser Support-Team wird Sie in den nächsten Tagen kontaktieren, um Ihre Änderungswünsche aufzunehmen.</div>
                    <div *ngIf="demoLinksConfirmedAt || (!demoLinksRejectedAt && !demoLinksConfirmedAt && operation == 'confirm')" class="main-text" style="margin-top: 7px;">Sobald Ihre Stellenangebote auf #DubistPflege veröffentlicht sind, werden Sie per E-Mail benachrichtigt.</div>
                </div>
                <div *ngIf="entityNotFound">
                    <div class="heading">Dieser Link ist ungültig</div>
                    <div class="main-text" style="margin-top: 7px;">Die Einrichtung zu Ihrem Link ist deaktiviert.</div>
                </div>
            </div>
        </div>
    </div>
    <footer class="footer">
        <a href="legaldocs/impressum.html" target="_blank" class="footer-link">Impressum</a>
        <a href="legaldocs/datenschutz.html" target="_blank" class="footer-link">Datenschutz</a>
    </footer>
</div>
