<div class="search-sidebar-container" [class.opened-sidebar]="isSidebarOpen" tourAnchor="search-sidebar-container" [ngStyle]="currentTourStepId == '4' && {'border': '2px solid #5223ca'}">
  <button (click)="toggleSidebar()" class="btn rounded-button btn-secondary" [class.toggle-hide]="!isSidebarOpen">
    <i *ngIf="isSidebarOpen" class="d-none d-sm-inline bi bi-chevron-bar-left"></i>
    <i *ngIf="!isSidebarOpen" class="d-none d-sm-inline bi bi-chevron-bar-right"></i>
    <i class="d-inline d-sm-none bi bi-x"></i>
  </button>

  <div *ngIf="isSidebarOpen" class="h-100">
    <div class="search-container">
      <i class="bi bi-search"></i>
      <input [formControl]="searchCtrl" class="form-control search-ctrl"
             placeholder="{{'JOB_UNTERNEHMEN' | translate}}"/>
    </div>

    <div class="sidebar-divider"></div>

    <div class="search-result-actions">
      <div class="d-flex justify-content-between">
        <div class="action-item px-2" style="pointer-events: none;">{{jobs?.length || 0}} {{(jobs?.length == 1 ? 'JOB_GEFUNDEN' : 'JOBS_GEFUNDEN') | translate}}</div>
        <div class="action-item px-2" (click)="showSortSection = !showSortSection"><i
          class="bi bi-filter mr-1"></i> {{'SORTIEREN' | translate}}</div>
      </div>
    </div>

    <div class="sort-container" *ngIf="showSortSection">
      <p class="sort-title">{{'SORTIEREN_NACH' | translate}}...</p>

      <div class="mt-3">
        <p class="mb-1">{{'JOB_TITLE' | translate}}</p>
        <div class="flex-center justify-content-between bg-transparent">
          <div class="sort-action" (click)="selectSort('title', 'asc')"
               [class.selected-sort-action]="sortObj?.key == 'title' && sortObj?.sortDirection == 'asc'">
            <i class="bi bi-sort-alpha-down"></i>
            {{'AUFSTEIGEND' | translate}}
          </div>
          <div class="sort-action" (click)="selectSort('title', 'desc')"
               [class.selected-sort-action]="sortObj?.key == 'title' && sortObj?.sortDirection == 'desc'">
            <i class="bi bi-sort-alpha-down-alt"></i>
            {{'ABSTEIGEND' | translate}}
          </div>
        </div>
      </div>

      <div class="mt-3">
        <p class="mb-1">{{'UNTERNEHMEN' | translate}}</p>
        <div class="d-flex justify-content-between bg-transparent">
          <div class="sort-action" (click)="selectSort('company_name', 'asc')"
               [class.selected-sort-action]="sortObj?.key == 'company_name' && sortObj?.sortDirection == 'asc'">
            <i class="bi bi-sort-alpha-down"></i>
            {{'AUFSTEIGEND' | translate}}
          </div>
          <div class="sort-action" (click)="selectSort('company_name', 'desc')"
               [class.selected-sort-action]="sortObj?.key == 'company_name' && sortObj?.sortDirection == 'desc'">
            <i class="bi bi-sort-alpha-down-alt"></i>
            {{'ABSTEIGEND' | translate}}
          </div>
        </div>
      </div>

      <div class="mt-3">
        <p class="mb-1">{{'VEROFFENTLICHUNG' | translate}}</p>
        <div class="d-flex justify-content-between bg-transparent">
          <div class="sort-action" (click)="selectSort('publish_date', 'desc')"
               [class.selected-sort-action]="sortObj?.key == 'publish_date' && sortObj?.sortDirection == 'desc'">{{'NEUSTE_ZUERST' | translate}}</div>
          <div class="sort-action" (click)="selectSort('publish_date', 'asc')"
               [class.selected-sort-action]="sortObj?.key == 'publish_date' && sortObj?.sortDirection == 'asc'">{{'ALTESTE_ZUERST' | translate}}</div>
        </div>
      </div>

      <hr/>

      <div class="text-right">
        <button class="btn btn-sm btn-bordered mr-2" (click)="resetSort()">{{'ABBRECHEN' | translate}}</button>
        <button class="btn btn-sm btn-primary" (click)="sort()">{{'SORTIEREN' | translate}}</button>
      </div>
    </div>

    <div class="search-result-items" [class.disable-job-list]="showSortSection">
      <div *ngFor="let job of jobs">
        <job-card [item]="job"></job-card>
      </div>
    </div>
  </div>
</div>

