import {NgModule} from '@angular/core';
import {MapScreenComponent} from './map-screen.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {NgbCarouselModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {CookieService} from 'ngx-cookie-service';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {LeafletMarkerClusterModule} from '@asymmetrik/ngx-leaflet-markercluster';
import {SearchResultSidebarModule} from "@digitalhilft/components/search-result-sidebar/search-result-sidebar.module";
import {FilterSidebarModule} from "@digitalhilft/components/filter-sidebar/filter-sidebar.module";
import {JobCardComponentModule} from '@digitalhilft/components/job-card/job-card.module';
import {TranslateModule} from '@ngx-translate/core';
import {TourNgBootstrapModule} from 'ngx-tour-ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LeafletModule,
        LeafletMarkerClusterModule,
        NgSelectModule,
        NgxSliderModule,
        NgbTypeaheadModule,
        SearchResultSidebarModule,
        FilterSidebarModule,
        JobCardComponentModule,
        TranslateModule,
        NgbCarouselModule,
        TourNgBootstrapModule
    ],
  declarations: [MapScreenComponent],
  providers: [CookieService],
  exports: [MapScreenComponent]
})
export class MapScreenModule {
}
