import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {JobCardComponent} from '@digitalhilft/components/job-card/job-card.component';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from '../../pipes/pipes.module';

@NgModule({
    imports: [CommonModule, TranslateModule, PipesModule],
  declarations: [JobCardComponent],
  exports: [JobCardComponent]
})
export class JobCardComponentModule {}
