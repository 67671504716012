import { Injectable } from '@angular/core';
import * as Hypher from 'hypher';
import * as german from 'hyphenation.de';

@Injectable({
  providedIn: 'root'
})
export class HyphenationPatternsService {
  // private enHyphenator: Hypher = null;
  private deHyphenator: Hypher = null;

  constructor() {
    // this.enHyphenator = new Hypher(english);
    this.deHyphenator = new Hypher(german);
  }

  public hyphenate(word: string, language: string) {
    // if(language == 'en')
    //   return this.enHyphenator.hyphenate(word);
    return this.deHyphenator.hyphenate(word);
  }
}
