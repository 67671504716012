<div class="sections-wrapper">
    <div class="component-container text-center">
        <div class="w-100">
            <img id="dubistpflege-logo" src="../../../../../assets/img/bpa-campaign/dubistpflege-logo.png" alt="">
        </div>
        <div class="d-flex justify-content-center">
            <div id="opt-card">
                <div *ngIf="!email">
                    <div class="heading mb-2">Dieser Link ist nicht gültig!</div>
                    <div class="main-text" style="margin-top: 14px;">Hier geht es zur <a style="text-decoration: underline;opacity: 0.93;" href="{{landingPageBaseUrl}}/registrierung" target="_blank">#DubistPflege Kampagne</a></div>
                </div>
                <div *ngIf="email && !isOptOutUrl">
                    <div class="heading mb-2">Vielen Dank, das freut uns!</div>
                    <div class="main-text" style="margin-top: 14px;">Sie haben sich erfolgreich für den #DubistPflege Newsletter mit Ihrer E-Mail Adresse <span style="color: #8B94AF;">{{email}}</span> angemeldet!</div>
                    <div class="main-text mt-2">Sobald es Neuigkeiten zur <a style="text-decoration: underline;opacity: 0.93;" href="{{landingPageBaseUrl}}/registrierung" target="_blank">#DubistPflege Kampagne</a> gibt,<br>werden wir Sie darüber informieren.</div>
                </div>
                <div *ngIf="email && isOptOutUrl">
                    <div class="heading mb-2">Sie haben sich {{initialOperationState == "opt-in" ? "erfolgreich" : "bereits"}} abgemeldet!</div>
                    <div class="main-text" style="margin-top: 14px;font-size: 19px;line-height: 27px;">Wir werden Ihnen keine weiteren E-Mails zur<br><a style="text-decoration: underline;opacity: 0.93;" href="{{landingPageBaseUrl}}/registrierung" target="_blank">#DubistPflege Kampagne</a> zusenden.</div>
                    <div class="main-text" style="font-size: 18px;margin-top: 14px;">Sie möchten doch Informationen von uns erhalten?</div>
                    <div class="main-text mt-1" style="font-size: 17px;">Dann bestätigen Sie mit Klick auf den Button, dass Sie sich mit Ihrer E-Mail Adresse <span style="color: #8B94AF;">{{email}}</span> für den Newsletter zur #DubistPflege Kampagne anmelden möchten:</div>
                    <div class="d-flex justify-content-center" style="margin-top: 14px;">
                        <button class="btn btn-outline-primary" (click)="signUpForNewsletter()">FÜR DEN NEWSLETTER ANMELDEN</button>
                    </div>
                    <div class="d-flex justify-content-center">
                        <div class="usage-terms pb-2 text-muted">Mit der Anmeldung stimmen Sie zu, dass wir Ihnen Angebote und Updates zur #DubistPflege Kampagne zusenden dürfen.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="footer">
        <a href="legaldocs/impressum.html" target="_blank" class="footer-link">Impressum</a>
        <a href="legaldocs/datenschutz.html" target="_blank" class="footer-link">Datenschutz</a>
    </footer>
</div>
