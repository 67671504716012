import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BpaService } from '@digitalhilft/services/bpa.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-intermediate-page',
  templateUrl: './intermediate-page.component.html',
  styleUrls: ['./intermediate-page.component.scss']
})
export class IntermediatePageComponent implements OnInit {

  placeholder: string;
  typeOfIntermediatePage: string;
  project: string;
  cleverReachLink: string;

  constructor(@Inject(DOCUMENT) private document: Document,
              private _spinner: NgxSpinnerService,
              private _bpaService: BpaService,
              private _activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this._spinner.show();
    if (this._activatedRoute.snapshot.url[0].path == "bpa-recruiting-kampagne") {
      this.project = "bpa";
    } else if (this._activatedRoute.snapshot.url[0].path != "recruiting-kampagne") {
      this.project = this._activatedRoute.snapshot.url[0].path;
    } else {
      this.project = "general";
    }
    this._bpaService.getProjectConfig(this.project).subscribe(projectConfig => {
      this.project = projectConfig.project;
      this._spinner.hide();
      this.typeOfIntermediatePage = this._activatedRoute.snapshot.url.slice(-1)[0].path;
      if (this.typeOfIntermediatePage == "webinar-anmeldung") {
        this.placeholder = "Info";
        this.cleverReachLink = projectConfig.webinar_link;
      } else if (this.typeOfIntermediatePage == "update-webinar-anmeldung") {
        this.placeholder = "Update";
        this.cleverReachLink = projectConfig.update_webinar_link;
      } else if (this.typeOfIntermediatePage == "onboarding-anmeldung") {
        this.placeholder = "Onboarding";
        this.cleverReachLink = projectConfig.onboarding_link;
      }
      setTimeout(() => {
        this.routeToCleverreach();
      }, 5000);
    });
  }

  routeToCleverreach() {
    this.document.location.href = this.cleverReachLink;
  }

}
