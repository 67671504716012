import {NgModule} from '@angular/core';
import {SearchResultSidebarComponent} from './search-result-sidebar.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NgSelectModule} from "@ng-select/ng-select";
import {JobCardComponentModule} from '@digitalhilft/components/job-card/job-card.module';
import {TranslateModule} from '@ngx-translate/core';
import {TourNgBootstrapModule} from 'ngx-tour-ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    JobCardComponentModule,
    TranslateModule,
    TourNgBootstrapModule
  ],
  declarations: [SearchResultSidebarComponent],
  exports: [SearchResultSidebarComponent]
})
export class SearchResultSidebarModule {}
