<div class="sections-wrapper">
    <div class="section-1-wrapper">
        <div class="section-1">
            <div id="section-1-part-1">
                <div id="section-1-heading">Registrieren Sie sich jetzt zur Teilnahme an</div>
                <img id="dubistpflege-logo" src="../../../../assets/img/bpa-campaign/dubistpflege-logo.png" alt="">
                <img id="campaign-images" src="../../../../assets/img/bpa-campaign/campaign-images.png" alt="">
            </div>
            <div id="section-1-part-2">
                <div class="w-100 text-right bpa-logon-wrapper">
                    <img id="bpa-logon" src="../../../../assets/img/bpa-campaign/bpa-logon.png" alt="">
                </div>
                <div id="section-2">

                    <!-- SECTION 1 -->
                    <form *ngIf="currentForm == 'emailForm'" [formGroup]="emailForm" (ngSubmit)="selectForm('furtherInformationForm')">
                        <div class="d-flex">
                            <div class="campaign-description">
                                <div style="font-weight: 500;margin-bottom: 4px;">#DubistPflege -</div>
                                <div>jetzt direkt teilnehmen und mit wenigen Klicks anmelden.</div>
                            </div>
                            <div style="height: 100%;width: 120px;position: relative;">
                                <div id="bpa-sonderkonditionen">bpa<br>Sonder-<br>konditionen<br>sichern!</div>
                            </div>
                        </div>

                        <div class="d-flex collapse-buttons">
                            <div class="collapse-button">
                                <div data-toggle="collapse" href="#collapseDurationCosts" role="button" aria-expanded="false" aria-controls="collapseDurationCosts" (click)="onDurationCostsToggleClick()">
                                Laufzeit und Kosten
                                <i class="bi bi-chevron-down"></i>
                                </div>
                            </div>
                            <div class="collapse-button">
                                <div data-toggle="collapse" href="#collapseProjectInfo" role="button" aria-expanded="false" aria-controls="collapseProjectInfo" (click)="onProjectInfoToggleClick()">
                                Infos zum Projekt
                                <i class="bi bi-chevron-down"></i>
                                </div>
                            </div>
                        </div>
                        <div id="collapseGroup">
                            <div class="collapse" id="collapseDurationCosts" data-parent="#collapseGroup">
                                <div class="card card-body" style="margin-top: 6px;background: none;padding: 10px !important;border: 1px solid rgba(255, 255, 255, 0.15);">
                                    <span>Die Laufzeit der Kampagne beträgt 6 Monate. Die Kosten betragen für bpa Mitglieder 350 Euro netto je teilnehmende Einrichtung für 6 Monate. Der Preis beinhaltet: Medien- und Agenturkosten, automatische Übernahme der Stellen in die JobMap. Auf Wunsch kann die Laufzeit verlängert werden.</span>
                                </div>
                            </div>
                            <div class="collapse" id="collapseProjectInfo" data-parent="#collapseGroup">
                                <div class="card card-body" style="margin-top: 6px;background: none;padding: 10px !important;border: 1px solid rgba(255, 255, 255, 0.15);">
                                    <span><span style="font-weight: 500;">#DubistPflege</span> ist eine Social Media Kampagne auf Facebook und Instagram, die sich gezielt an ehemalige Pflegekräfte, Azubis und Quereinsteiger richtet. Diese finden bei Interesse mit einem Klick eine vollständige JobMap mit allen teilnehmenden Einrichtungen. Dort können sie ganz einfach mobil direkt mit Ihnen in Kontakt treten. Die Ausspielung der Jobs erfolgt vollautomatisch ohne Aufwand für Sie.</span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex mt-3 email-teilnehmen-wrapper">
                            <div *ngIf="!emailForm.valid" id="teilnehmen-button-1" (click)="participateNowWithoutEmail()">JETZT TEILNEHMEN<i class="bi bi-chevron-right"></i></div>
                            <button *ngIf="emailForm.valid" id="teilnehmen-button-2" type="submit">JETZT TEILNEHMEN<i class="bi bi-chevron-right"></i></button>
                            <div class="w-100">
                                <input class="form-control" style="min-width: 220px;" formControlName="email" type="email" placeholder="E-Mail">
                                <small class="text-warning" *ngIf="emailForm.get('email').errors?.email">Die E-Mail Adresse ist ungültig.</small>
                            </div>
                        </div>
                        <div class="usage-terms pt-1 pb-2">Mit der Registrierung stimmen Sie unseren <a href="assets/pdf/T&C.pdf" target="_blank">Allgemeinen Geschäftsbedingungen</a>, unseren <a href="assets/pdf/TermsOfUse.pdf" target="_blank">Nutzungsbedingungen</a> und unserer <a href="legaldocs/datenschutz.html" target="_blank">Datenschutzerklärung</a> zu.</div>
                    </form>

                    <!-- SECTION 2 -->
                    <form *ngIf="currentForm == 'furtherInformationForm'" [formGroup]="furtherInformationForm" (ngSubmit)="registerEmail()" class="form-background text-muted">
                        <div class="section-2-heading" [ngStyle]="furtherInformationForm.valid && {'color':'#0eb370'}">
                            <div>Fügen Sie hier die wichtigsten Informationen ein.</div>
                            <div style="position: relative;min-width: 31px;">
                                <i *ngIf="furtherInformationForm.valid" class="bi bi-check-circle ml-2" style="font-size: 23px;"></i>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="inputEmail4">E-Mail<span class="required-form-field">*</span></label>
                            <input type="email" formControlName="email" class="form-control" id="inputEmail4" placeholder="E-Mail">
                            <small class="text-danger" *ngIf="furtherInformationForm.get('email').errors?.email">Die E-Mail Adresse ist ungültig.</small>
                        </div>
                        <div class="form-group">
                            <label for="inputEmail4">Anzahl der teilnehmenden Einrichtungen<span class="required-form-field">*</span></label>
                            <input type="text" formControlName="number_of_facilities" class="form-control" id="inputEmail4" placeholder="Anzahl">
                            <small class="text-danger" *ngIf="furtherInformationForm.get('number_of_facilities').value && furtherInformationForm.get('number_of_facilities').errors">
                                In diesem Feld sind nur Nummern größer als 0 erlaubt.
                            </small>
                        </div>
                        <div class="form-group">
                            <label for="inputPassword4"><span style="color: #005f9c;">b</span><span style="color: #63b9e9;">p</span><span style="color: #005f9c;">a</span> Mitgliedsnummer (optional)</label>
                            <input type="text" formControlName="membership_nr" class="form-control" id="inputPassword4" placeholder="bpa Mitgliedsnummer">
                        </div>
                        <div class="w-100 text-center">
                            <button type="submit" [disabled]="!furtherInformationForm.valid" [class.disabled-button]="!furtherInformationForm.valid" class="btn btn-primary mt-2">ABSENDEN</button>
                        </div>
                    </form>

                    <!-- SECTION 3 -->
                    <form *ngIf="currentForm == 'companyDetailsForm'" [formGroup]="companyDetailsForm" (ngSubmit)="submitRegistration(true)" class="form-background text-muted">
                        <div class="section-2-heading" [ngStyle]="companyDetailsForm.valid && {'color':'#0eb370'}">
                            <div>Fügen Sie hier Ihre Unternehmensdaten ein.</div>
                            <div style="position: relative;min-width: 31px;">
                                <i *ngIf="companyDetailsForm.valid" class="bi bi-check-circle ml-2" style="font-size: 23px;"></i>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="inputAddress">Unternehmensname<span class="required-form-field">*</span></label>
                            <input type="text" formControlName="company_name" class="form-control" id="inputAddress" placeholder="Unternehmensname">
                        </div>
                        <div class="form-row">
                            <div class="form-group col-12 col-lg-5">
                                <label for="inputEmail4">Postleitzahl<span class="required-form-field">*</span></label>
                                <input type="text" formControlName="zip" class="form-control" id="inputEmail4" placeholder="Postleitzahl">
                                <small class="text-danger" *ngIf="companyDetailsForm.get('zip').value && companyDetailsForm.get('zip').errors">
                                    Die Postleitzahl muss fünfstellig sein.
                                </small>
                            </div>
                            <div class="form-group col-12 col-lg-7">
                                <label for="inputEmail4">Stadt<span class="required-form-field">*</span></label>
                                <input type="text" formControlName="city" class="form-control" id="inputEmail4" placeholder="Stadt">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="inputPassword4">Straße & Hausnummer / Postfach<span class="required-form-field">*</span></label>
                            <input type="text" formControlName="street" class="form-control" id="inputPassword4" placeholder="Straße & Hausnummer / Postfach">
                        </div>
                        <div *ngIf="furtherInformationForm.get('number_of_facilities').value == 1" class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="facilityDataSameCheck" (click)="onFacilitySameAsCompanyCheckboxChange($event)">
                                <label class="form-check-label" for="facilityDataSameCheck">Einrichtungsdaten sind identisch mit Unternehmensdaten.</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="inputEmail4">Kontaktperson<span class="required-form-field">*</span></label>
                            <input type="text" formControlName="contact_name" class="form-control" id="inputEmail4" placeholder="Vorname und Nachname">
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label for="inputEmail4">E-Mail<span class="required-form-field">*</span></label>
                                <input type="email" formControlName="contact_email" class="form-control" id="inputEmail4" placeholder="E-Mail">
                                <small class="text-danger" *ngIf="companyDetailsForm.get('contact_email').errors?.email">Die E-Mail Adresse ist ungültig.</small>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="inputPassword4">Telefonnummer (optional)</label>
                                <input type="text" formControlName="contact_phone" class="form-control" id="inputPassword4" placeholder="Telefonnummer">
                            </div>
                        </div>
                        <div class="form-group" [class.disabled-button]="!companyDetailsForm.valid">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="gridCheck" (click)="onCheckboxChange($event)" #invoiceCheckbox>
                                <label class="form-check-label" for="gridCheck">Abweichende Rechnungsadresse.</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="inputAddress">Kostenstelle / Kundenzeichen (optional)</label>
                            <input type="text" formControlName="internal_ref_id" class="form-control" id="inputAddress" placeholder="Kostenstelle / Kundenzeichen">
                        </div>
                        <div class="w-100 text-center">
                            <button class="btn btn-primary" type="submit"  [disabled]="!companyDetailsForm.valid" [class.disabled-button]="!companyDetailsForm.valid">ABSENDEN</button>
                        </div>
                    </form>

                    <!-- SECTION 4 -->
                    <form *ngIf="currentForm == 'invoiceForm'" [formGroup]="invoiceForm" (ngSubmit)="submitRegistration(false)" class="form-background text-muted">
                        <div class="section-2-heading" [ngStyle]="invoiceForm.valid && {'color':'#0eb370'}">
                            <div>Fügen Sie hier Ihre Rechnungsdaten ein.</div>
                            <div style="position: relative;min-width: 31px;">
                                <i *ngIf="invoiceForm.valid" class="bi bi-check-circle ml-2" style="font-size: 23px;"></i>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end mb-2">
                            <div class="btn btn-outline-primary" (click)="sendBillInfoLater()">Rechnungsadresse später mitteilen</div>
                        </div>
                        <div>
                            <div class="form-group">
                                <label for="inputAddress">Unternehmensname<span class="required-form-field">*</span></label>
                                <input type="text" formControlName="bill_to_company_name" class="form-control" id="inputAddress" placeholder="Unternehmensname">
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputEmail4">Postleitzahl<span class="required-form-field">*</span></label>
                                    <input type="text" formControlName="bill_to_zip" class="form-control" id="inputEmail4" placeholder="Postleitzahl">
                                    <small class="text-danger" *ngIf="invoiceForm.get('bill_to_zip').value && invoiceForm.get('bill_to_zip').errors">
                                        Die Postleitzahl muss fünfstellig sein.
                                    </small>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputEmail4">Stadt<span class="required-form-field">*</span></label>
                                    <input type="text" formControlName="bill_to_city" class="form-control" id="inputEmail4" placeholder="Stadt">
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="inputPassword4">Straße & Hausnummer / Postfach<span class="required-form-field">*</span></label>
                                <input type="text" formControlName="bill_to_street" class="form-control" id="inputPassword4" placeholder="Straße & Hausnummer / Postfach">
                            </div>
                            <div class="form-group">
                                <label for="inputEmail4">Kontaktperson<span class="required-form-field">*</span></label>
                                <input type="text" formControlName="bill_to_name" class="form-control" id="inputEmail4" placeholder="Vorname und Nachname">
                            </div>
                            <!-- <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="inputEmail4">E-Mail<span class="required-form-field">*</span></label>
                                    <input type="email" formControlName="bill_to_email" class="form-control" id="inputEmail4" placeholder="E-Mail">
                                    <small class="text-danger" *ngIf="invoiceForm.get('bill_to_email').errors?.email">Die E-Mail Adresse ist ungültig.</small>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputPassword4">Telefonnummer (optional)</label>
                                    <input type="text" formControlName="bill_to_phone" class="form-control" id="inputPassword4" placeholder="Telefonnummer">
                                </div>
                            </div> -->
                            <div class="form-group">
                                <label for="inputPassword4">Telefonnummer (optional)</label>
                                <input type="text" formControlName="bill_to_phone" class="form-control" id="inputPassword4" placeholder="Telefonnummer">
                            </div>
                            <div class="form-group">
                                <label for="inputAddress">Kostenstelle / Kundenzeichen (optional)</label>
                                <input type="text" formControlName="internal_ref_id" class="form-control" id="inputAddress" placeholder="Kostenstelle / Kundenzeichen">
                            </div>
                        </div>
                        <div class="w-100 text-center">
                            <button class="btn btn-primary" type="submit"  [disabled]="!invoiceForm.valid" [class.disabled-button]="!invoiceForm.valid">ABSENDEN</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
    <footer class="footer">
        <a href="legaldocs/impressum.html" target="_blank" class="footer-link">Impressum</a>
        <a href="legaldocs/datenschutz.html" target="_blank" class="footer-link">Datenschutz</a>
    </footer>
</div>

<ng-template #verificationLinkSentModal>
    <div id="order-verified-form-modal" class="text-center">
        <div class="d-flex justify-content-between">
            <div class="modal-heading">WIR HABEN IHNEN EINE BESTÄTIGUNGSMAIL GESCHICKT!</div>
            <i class="bi bi-x pointer" (click)="closeModal()"></i>
        </div>
        <div class="modal-text">Bitte bestätigen Sie Ihre Teilnahme nach Abschluss der Registrierung, indem Sie auf den "Registrierung bestätigen" Button in der E-Mail klicken (<span id="contact-email">{{email}}</span>).</div>
        <div class="d-flex justify-content-center">
            <button class="btn btn-primary" (click)="closeModal()">FORTFAHREN</button>
        </div>
    </div>
</ng-template>

<ng-template #submittedFormModal>
    <div id="submitted-form-modal" class="text-center">
        <div class="d-flex justify-content-between">
            <div class="modal-heading">Vielen Dank für Ihre Angaben!</div>
            <i class="bi bi-x pointer" (click)="closeModal()"></i>
        </div>
        <div *ngIf="!isEmailVerified" class="modal-text">Bitte bestätigen Sie jetzt Ihre Teilnahme, indem Sie auf den "Registrierung bestätigen" Button in der E-Mail klicken (<span id="contact-email">{{email}}</span>).
            <span *ngIf="configureFacilitiesAfterValidation == 1">Nach der Bestätigung erhalten Sie einen Link, über den Sie Ihre Einrichtung(en) eintragen können.</span></div>
        <div *ngIf="billInfoWillBeSentLater" class="modal-text">Wir werden Sie bezüglich Ihrer Rechnungsdaten kontaktieren.</div>
        <div *ngIf="!isEmailVerified" id="check-email-text" class="text-muted">Bitte überprüfen Sie Ihren Posteingang. Überprüfen Sie auch Ihren Spam-Ordner, falls Sie in den nächsten Minuten keine E-Mail erhalten haben.</div>
    </div>
</ng-template>

<ng-template #orderVerifiedModal>
    <div id="order-verified-form-modal" class="text-center">
        <div class="d-flex justify-content-between">
            <div class="modal-heading">Sie haben Ihre E-Mail erfolgreich bestätigt!</div>
            <i class="bi bi-x pointer" (click)="closeModal()"></i>
        </div>
        <div class="modal-text">Bitte schließen Sie Ihre Registrierung ab.</div>
        <div class="d-flex justify-content-center">
            <button class="btn btn-primary" (click)="closeModal()">FORTFAHREN</button>
        </div>
    </div>
</ng-template>
