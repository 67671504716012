<div class="sections-wrapper">
    <div class="section-1-wrapper">
        <div class="section-1">
            <div id="section-1-part-1">
                <div id="section-1-heading">Anmeldung zum {{placeholder}} Webinar für</div>
                <img id="dubistpflege-logo" src="../../../../../assets/img/recruiting-campaign/dubistpflege-logo.png" alt="">
                <img id="campaign-images" src="../../../../../assets/img/recruiting-campaign/campaign-images.png" alt="">
            </div>
            <div id="section-1-part-2">
                <div class="w-100 text-right bpa-logon-wrapper">
                    <img id="bpa-logon" src="../../../../../assets/img/recruiting-campaign/logon.png" alt="">
                </div>
                <div id="section-2">
                    <div class="campaign-description">
                        <div>Sie werden automatisch zur Anmeldung für das {{placeholder}} Webinar weitergeleitet.</div>
                        <div class="mt-3">Falls Sie innerhalb von 5 Sekunden nicht weitergeleitet werden, klicken Sie bitte auf den Button:</div>
                    </div>
                    <div class="d-flex justify-content-center mt-4">
                        <a id="register-button" (click)="routeToCleverreach()">Zur Anmeldung<i class="bi bi-chevron-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="footer">
        <a href="legaldocs/impressum.html" target="_blank" class="footer-link">Impressum</a>
        <a href="legaldocs/datenschutz.html" target="_blank" class="footer-link">Datenschutz</a>
    </footer>
</div>

