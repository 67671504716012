import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BpaService } from '../../services/bpa.service';
import {NgxSpinnerService} from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import {v4 as uuidv4} from 'uuid';

@Component({
  selector: 'app-bpa-campaign',
  templateUrl: './bpa-campaign.component.html',
  styleUrls: ['./bpa-campaign.component.scss']
})
export class BpaCampaignComponent implements OnInit {

  @ViewChild('verificationLinkSentModal') verificationLinkSentModal: any;
  @ViewChild('submittedFormModal') submittedFormModal: any;
  @ViewChild('orderVerifiedModal') orderVerifiedModal: any;
  orderID: string;

  facilityDataAreTheSameAsCompanyData: boolean;
  configureFacilitiesAfterValidation: any;
  emailForm = this._fb.group({
    order_id: [''],
    email: ['', [Validators.email, Validators.required]],
    project: ['bpa'],
    configure_facilities_after_validation: ['1']
  });

  companyDetailsForm = this._fb.group({
    company_name: ['', Validators.required],
    zip: ['', [Validators.pattern(/^\d+$/), Validators.minLength(5), Validators.maxLength(5), Validators.required]],
    city: ['', Validators.required],
    street: ['', Validators.required],
    contact_name: ['', Validators.required],
    contact_surname: [''],
    contact_email: ['', [Validators.email, Validators.required]],
    contact_phone: [''],
    internal_ref_id: ['']
  });

  invoiceForm = this._fb.group({
    bill_to_company_name: ['', Validators.required],
    bill_to_zip: ['', [Validators.pattern(/^\d+$/), Validators.minLength(5), Validators.maxLength(5), Validators.required]],
    bill_to_city: ['', Validators.required],
    bill_to_street: ['', Validators.required],
    bill_to_name: ['', Validators.required],
    bill_to_surname: [''],
    bill_to_email: ['', [Validators.email, Validators.required]],
    bill_to_phone: [''],
    internal_ref_id: ['']
  });
  
  furtherInformationForm = this._fb.group({
    email: ['', [Validators.email, Validators.required]],
    membership_nr: [''],
    number_of_facilities: ['', [Validators.pattern(/^[1-9]\d*$/), Validators.required]]
  });

  selectedPricePackage: any;

  currentForm: string;
  isEmailVerified: boolean;

  invoiceCheckboxIsChecked: boolean;
  email: string;
  sessionID: string;

  billInfoWillBeSentLater: boolean;

  // this email is used to track whether the registrant came from our email
  emailFromEmailDistribution: string;

  constructor(private _bpaService: BpaService,
              private _fb: FormBuilder,
              private cd: ChangeDetectorRef,
              private modalController: NgbModal,
              private _spinner: NgxSpinnerService,
              private _router: Router,
              private _route: ActivatedRoute) { }

  ngOnInit(): void {
    this._router.navigateByUrl('bpa/recruiting-kampagne/registrierung');
    
    /* this.emailFromEmailDistribution = this._route.snapshot.queryParamMap.get("email");
    if (sessionStorage.getItem("session_id")) {
      this.sessionID = sessionStorage.getItem("session_id");
    } else {
      this.sessionID = uuidv4();
      sessionStorage.setItem("session_id", this.sessionID);
    }
    this._bpaService.getProjectConfig("bpa").subscribe(projectConfig => {
      this.selectedPricePackage = projectConfig.price_packages[0];
    });
    if (sessionStorage.getItem("continueRegistrationFormEmail")) {
      this._bpaService.getMainFacilityData(sessionStorage.getItem("continueRegistrationFormEmail"), sessionStorage.getItem("continueRegistrationFormOrderID")).subscribe(res => {
        this._bpaService.bpaBusinessLog(sessionStorage.getItem("continueRegistrationFormOrderID"), this.sessionID, "forwarded-user-to-landing-page", "bpa").subscribe();
        this.setRegistrationFormData(res);
        this.setInitialForm();
        sessionStorage.removeItem("continueRegistrationFormEmail");
        sessionStorage.removeItem("continueRegistrationFormOrderID");
        sessionStorage.setItem("landingPageAlreadyTrackedInThisSession", "true");
      });
    } else {
      // logging landing-page depending on where user came from
      if (sessionStorage.getItem("forwardedUserToLandingPage")) {
        this._bpaService.bpaBusinessLog(sessionStorage.getItem("forwardedUserOrderID"), this.sessionID, "forwarded-user-to-landing-page", "bpa").subscribe();
        sessionStorage.removeItem("forwardedUserToLandingPage");
        sessionStorage.removeItem("forwardedUserOrderID");
      } else if (!sessionStorage.getItem("landingPageAlreadyTrackedInThisSession")) {
        sessionStorage.setItem("landingPageAlreadyTrackedInThisSession", "true");
        this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "landing-page", "bpa", this.emailFromEmailDistribution).subscribe();
      }
      this.loadSessionStorageData();
      this.setInitialForm();
    }
    this.invoiceCheckboxIsChecked = true;
    this.facilityDataAreTheSameAsCompanyData = false; */
  }

  setRegistrationFormData(data: any) {
    this.isEmailVerified = data.order_verified == 1;

    this.emailForm.get("email").setValue(data.email);
    this.emailForm.get("order_id").setValue(data.order_id);

    this.furtherInformationForm.get("email").setValue(data.email);
    this.furtherInformationForm.get("membership_nr").setValue(data.membership_nr);
    this.furtherInformationForm.get("number_of_facilities").setValue(data.number_of_facilities);
    
    this.companyDetailsForm.get("company_name").setValue(data.company_name);
    this.companyDetailsForm.get("zip").setValue(data.zip);
    this.companyDetailsForm.get("city").setValue(data.city);
    this.companyDetailsForm.get("street").setValue(data.street);
    this.companyDetailsForm.get("contact_name").setValue(data.contact_name);
    this.companyDetailsForm.get("contact_surname").setValue(data.contact_surname);
    this.companyDetailsForm.get("contact_email").setValue(data.contact_email);
    this.companyDetailsForm.get("contact_phone").setValue(data.contact_phone);
    this.companyDetailsForm.get("internal_ref_id").setValue(data.internal_ref_id);
    
    this.invoiceForm.get("bill_to_company_name").setValue(data.bill_to_company_name);
    this.invoiceForm.get("bill_to_zip").setValue(data.bill_to_zip);
    this.invoiceForm.get("bill_to_city").setValue(data.bill_to_city);
    this.invoiceForm.get("bill_to_street").setValue(data.bill_to_street);
    this.invoiceForm.get("bill_to_name").setValue(data.bill_to_name);
    this.invoiceForm.get("bill_to_surname").setValue(data.bill_to_surname);
    this.invoiceForm.get("bill_to_email").setValue(data.bill_to_email);
    this.invoiceForm.get("bill_to_phone").setValue(data.bill_to_phone);
    this.invoiceForm.get("internal_ref_id").setValue(data.internal_ref_id);

    sessionStorage.setItem('emailForm', JSON.stringify(this.emailForm.value));
    sessionStorage.setItem('furtherInformationForm', JSON.stringify(this.furtherInformationForm.value));
    sessionStorage.setItem('companyDetailsForm', JSON.stringify(this.companyDetailsForm.value));
    sessionStorage.setItem('invoiceForm', JSON.stringify(this.invoiceForm.value));

    this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "continue-registration-process-modal", "bpa").subscribe();

    this.modalController.open(this.orderVerifiedModal, {
      size: 'md',
      centered: true
    });
  }

  participateNowWithoutEmail() {
    this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "participate-now-without-email", "bpa").subscribe();
    this.currentForm = 'furtherInformationForm';
  }

  loadSessionStorageData() {
    let forms = [
      sessionStorage.getItem('emailForm'),
      sessionStorage.getItem('furtherInformationForm'),
      sessionStorage.getItem('companyDetailsForm'),
      sessionStorage.getItem('invoiceForm')
    ];

    let formsDict = {
      0: this.emailForm,
      1: this.furtherInformationForm,
      2: this.companyDetailsForm,
      3: this.invoiceForm
    }

    for (const [formsDictKey, formsDictValue] of Object.entries(formsDict)) {
      if (forms[formsDictKey]) {
        for (const [key, value] of Object.entries(JSON.parse(forms[formsDictKey]))) {
          formsDict[formsDictKey].get(key).setValue(value);
        }
      }
    }
  }

  checkEmailVerificationState() {
    const email = this.emailForm.get("email").value;
    const orderID = this.emailForm.get("order_id").value;
    this._bpaService.getMainFacilityData(email, orderID).subscribe(res => {
      this.isEmailVerified = res.order_verified == 1;
      this.resetRegistration();
      if (this.isEmailVerified) {
        this._bpaService.bpaBusinessLog(orderID, this.sessionID, "forward-verified-user-to-facilities-configuration-screen", "bpa").subscribe();
        this._spinner.hide();
        this._router.navigateByUrl('/bpa-recruiting-kampagne/erfolgreich-registriert?email=' + email + "&order=" + orderID);
      } else {
        this._spinner.hide();
        this.modalController.open(this.submittedFormModal, {
          size: 'md',
          centered: true
        });
      }
    });
  }

  setInitialForm() {
    // For displaying the right form after refreshing the page
    if (!this.emailForm.valid && !this.furtherInformationForm.valid) {
      this.currentForm = "emailForm";
    } else if (this.emailForm.valid && !this.furtherInformationForm.valid) {
      this.currentForm = "furtherInformationForm";
    } else if (this.furtherInformationForm.valid && !this.companyDetailsForm.valid) {
      this.currentForm = "companyDetailsForm";
    } else if (this.companyDetailsForm.valid && !this.invoiceForm.valid) {
      this.currentForm = "invoiceForm";
    }
  }

  closeModal() {
    this.modalController.dismissAll();
  }

  onFacilitySameAsCompanyCheckboxChange(event: any) {
    let checkboxIsChecked = event.target.checked;
    if (checkboxIsChecked) {
      this.facilityDataAreTheSameAsCompanyData = true;
      this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "facility-data-same-as-company-data-checkbox-checked", "bpa").subscribe();
    } else {
      this.facilityDataAreTheSameAsCompanyData = false;
      this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "facility-data-same-as-company-data-checkbox-unchecked", "bpa").subscribe();
    }
  }


  onCheckboxChange(event: any) {
    this.invoiceCheckboxIsChecked = event.target.checked;
    if (this.invoiceCheckboxIsChecked) {
      if (this.facilityDataAreTheSameAsCompanyData) {
        this.setFacilityDataSameAsCompanyData();
      }
      let registrationForm = {
        ...this.emailForm.value,
        ...this.companyDetailsForm.value,
        ...this.invoiceForm.value,
        ...this.furtherInformationForm.value
      }
      this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "different-invoice-address-checkbox-click", "bpa").subscribe();
      this._bpaService.register(registrationForm).subscribe(res => { 
        if (this.companyDetailsForm.get("internal_ref_id")?.value?.length > 1) {
          this.invoiceForm.get("internal_ref_id").setValue(this.companyDetailsForm.get("internal_ref_id").value);
        }
        this.selectForm("invoiceForm");
      });
    }
    this.cd.detectChanges();
  }

  sendBillInfoLater() {
    this.billInfoWillBeSentLater = true;
    this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "send-invoice-data-later", "bpa").subscribe();
    this._bpaService.sendBillInfoLater(this.emailForm.get("email").value, this.emailForm.get("order_id").value).subscribe();
    this.submitRegistration(false);
  }

  selectForm(formName: string) {
    // Setting the session storage form and selecting the next form to be displayed
    switch(formName) {
      case 'furtherInformationForm':
        sessionStorage.setItem('emailForm', JSON.stringify(this.emailForm.value));
        this.furtherInformationForm.get("email").setValue(this.emailForm.get("email").value);
        break;
      case 'companyDetailsForm':
        sessionStorage.setItem('furtherInformationForm', JSON.stringify(this.furtherInformationForm.value));
        break;
      case 'invoiceForm':
        sessionStorage.setItem('companyDetailsForm', JSON.stringify(this.companyDetailsForm.value));
        this.invoiceForm.get("internal_ref_id").setValue(this.companyDetailsForm.get("internal_ref_id").value);
        break;
    }
    // Everytime the user goes to the next screen, the data will be stored in the database
    if (formName == "companyDetailsForm") {
      this.currentForm = formName;
    } else {
      let registrationForm = {
        ...this.emailForm.value,
        ...this.companyDetailsForm.value,
        ...this.invoiceForm.value,
        ...this.furtherInformationForm.value
      }
      registrationForm["package_id"] = this.selectedPricePackage.id;
      registrationForm["valid_time"] = this.selectedPricePackage.valid_time;
      registrationForm["valid_time_unit"] = this.selectedPricePackage.valid_time_unit;
      registrationForm["price_amount"] = this.selectedPricePackage.price_amount;
      registrationForm["price_currency"] = this.selectedPricePackage.price_currency;
      this._bpaService.register(registrationForm).subscribe(res => { 
        this.emailForm.get("order_id").setValue(res.order_id);
        if (formName == "furtherInformationForm") {
          this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "participate-now-with-email", "bpa").subscribe();
        }
        this.currentForm = formName;
      });
    }
  }

  copyCompanyDetailsAndContactDataToInvoiceForm() {
    this.invoiceForm.get("bill_to_company_name").setValue(this.companyDetailsForm.get("company_name").value);
    this.invoiceForm.get("bill_to_zip").setValue(this.companyDetailsForm.get("zip").value);
    this.invoiceForm.get("bill_to_city").setValue(this.companyDetailsForm.get("city").value);
    this.invoiceForm.get("bill_to_street").setValue(this.companyDetailsForm.get("street").value);
    this.invoiceForm.get("bill_to_name").setValue(this.companyDetailsForm.get("contact_name").value);
    this.invoiceForm.get("bill_to_surname").setValue(this.companyDetailsForm.get("contact_surname").value);
    // bill_to_email gets set after furtherInformationForm is sent and has the email of the furtherInformationForm
    // this.invoiceForm.get("bill_to_email").setValue(this.companyDetailsForm.get("contact_email").value);
    this.invoiceForm.get("bill_to_phone").setValue(this.companyDetailsForm.get("contact_phone").value);
    this.invoiceForm.get("bill_to_phone").setValue(this.companyDetailsForm.get("contact_phone").value);
    this.invoiceForm.get("internal_ref_id").setValue(this.companyDetailsForm.get("internal_ref_id").value);
    sessionStorage.setItem('invoiceForm', JSON.stringify(this.invoiceForm.value));
  }

  registerEmail() {
    this._spinner.show();
    if (!this.emailForm.valid) {
      this.emailForm.get("email").setValue(this.furtherInformationForm.get("email").value);
    }
    this.invoiceForm.get("bill_to_email").setValue(this.furtherInformationForm.get("email").value);
    let registrationForm = {
      ...this.emailForm.value,
      ...this.companyDetailsForm.value,
      ...this.invoiceForm.value,
      ...this.furtherInformationForm.value
    }
    registrationForm["package_id"] = this.selectedPricePackage.id;
    registrationForm["valid_time"] = this.selectedPricePackage.valid_time;
    registrationForm["valid_time_unit"] = this.selectedPricePackage.valid_time_unit;
    registrationForm["price_amount"] = this.selectedPricePackage.price_amount;
    registrationForm["price_currency"] = this.selectedPricePackage.price_currency;
    this._bpaService.register(registrationForm).subscribe(res => {
      this.emailForm.get("order_id").setValue(res.order_id);
      this._bpaService.bpaBusinessLog(res.order_id, this.sessionID, "send-number-of-facilities-form", "bpa").subscribe();
      this._bpaService.orderVerificationEmail(this.emailForm.get("email").value, this.emailForm.get("order_id").value).subscribe(res => {
        this.email = this.emailForm.get("email").value;
        this.selectForm('companyDetailsForm');
        this._spinner.hide();
        this.modalController.open(this.verificationLinkSentModal, {
          size: 'md',
          centered: true
        });
      });
    });
  }

  resetRegistration() {
    this.emailForm.reset();
    this.companyDetailsForm.reset();
    this.invoiceForm.reset();
    this.furtherInformationForm.reset();
    sessionStorage.removeItem('emailForm');
    sessionStorage.removeItem('furtherInformationForm');
    sessionStorage.removeItem('companyDetailsForm');
    sessionStorage.removeItem('invoiceForm');
    this.currentForm = "emailForm";
  }

  setFacilityDataSameAsCompanyData() {
    this.emailForm.get("configure_facilities_after_validation").setValue(0);
    this.configureFacilitiesAfterValidation = "0";
    let facility = this._fb.group({
      company_name: [this.companyDetailsForm.get("company_name").value],
      zip: [this.companyDetailsForm.get("zip").value],
      city: [this.companyDetailsForm.get("city").value],
      street: [this.companyDetailsForm.get("street").value],
      carrer_site_url: [''],
      company_category: [''],
      justapplai_email: [this.emailForm.get("email").value],
      application_delivery_email: ['']
    });
    let data = {
      ...this.emailForm.value,
      facilities: []
    }
    data.facilities.push(facility.value);
    this._bpaService.registerFacilities(data).subscribe(res => {
      this._bpaService.bpaBusinessLog(this.orderID, this.sessionID, "facility-was-created-automatically", "bpa").subscribe();
    });
  }


  submitRegistration(invoiceDataSameAsCompanyDetails: boolean) {
    this._spinner.show();
    if (invoiceDataSameAsCompanyDetails) {
      this.copyCompanyDetailsAndContactDataToInvoiceForm();
      if (this.facilityDataAreTheSameAsCompanyData) {
        this.setFacilityDataSameAsCompanyData();
      }
      this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "submit-registration-from-company-details", "bpa").subscribe();
    } else {
      this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "submit-registration-from-invoice-screen", "bpa").subscribe();
    }
    let registrationForm = {
      ...this.emailForm.value,
      ...this.companyDetailsForm.value,
      ...this.invoiceForm.value,
      ...this.furtherInformationForm.value
    }
    registrationForm["package_id"] = this.selectedPricePackage.id;
    registrationForm["valid_time"] = this.selectedPricePackage.valid_time;
    registrationForm["valid_time_unit"] = this.selectedPricePackage.valid_time_unit;
    registrationForm["price_amount"] = this.selectedPricePackage.price_amount;
    registrationForm["price_currency"] = this.selectedPricePackage.price_currency;
    this.email = registrationForm['email'];
    this._bpaService.register(registrationForm).subscribe(res => this.checkEmailVerificationState());
  }

  onDurationCostsToggleClick() {
    this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "duration-costs-toggle-click", "bpa").subscribe();
  }
  
  onProjectInfoToggleClick() {
    this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "project-info-toggle-click", "bpa").subscribe();
  }

}
