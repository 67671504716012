import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BpaService } from '../../../../@digitalhilft/services/bpa.service';

@Component({
  selector: 'app-demo-links-forwarding-page',
  templateUrl: './demo-links-forwarding-page.component.html',
  styleUrls: ['./demo-links-forwarding-page.component.scss']
})
export class DemoLinksForwardingPageComponent implements OnInit {

  // Query parameters
  facilityID: string;
  operation: any;

  // Status from backend
  facilityName: string;
  demoLinksConfirmedAt: any;
  demoLinksConfirmedBy: any;
  demoLinksRejectedAt: any;
  demoLinksSentAt: any;

  entityNotFound: boolean = false;

  constructor(private _spinner: NgxSpinnerService,
              private _route: ActivatedRoute,
              private _bpaService: BpaService) { }

  ngOnInit(): void {
    this._spinner.show();
    this.facilityID = this._route.snapshot.queryParamMap.get("f_id");
    this.operation = this._route.snapshot.queryParamMap.get("o");
    
    this._bpaService.getFacilityDemoLinksStatus(this.facilityID).subscribe(res => {
      this.facilityName = res.facility_name;
      this.demoLinksConfirmedAt = res.demo_links_confirmed_at;
      this.demoLinksConfirmedBy = res.demo_links_confirmed_by;
      this.demoLinksRejectedAt = res.demo_links_rejected_at;
      this.demoLinksSentAt = res.demo_links_sent_at;

      if (this.operation == "reject" && !this.demoLinksRejectedAt && !this.demoLinksConfirmedAt) {
        this.rejectDemoLinks();
      } else if (this.operation == "confirm" && !this.demoLinksRejectedAt && !this.demoLinksConfirmedAt) {
        this.verifyDemoLinks();
      }

      this._spinner.hide();
    }, err => {
      this.entityNotFound = true;
      this._spinner.hide();
    });

  }

  rejectDemoLinks() {
    this._bpaService.rejectFacilityDemoLinks(this.facilityID).subscribe(res => console.log(res));
  }

  verifyDemoLinks() {
    this._bpaService.confirmFacilityDemoLinks(this.facilityID, "customer").subscribe(res => console.log(res));
  }

}
