import { UrlSerializer, UrlTree, DefaultUrlSerializer } from '@angular/router';

export class CustomUrlSerializer implements UrlSerializer {
  parse(url: string): UrlTree {
    const dus = new DefaultUrlSerializer();
    return dus.parse(url.replace(/\(/g, '%28').replace(/\)/g, '%29'));
  }

  serialize(tree: UrlTree): string {
    const dus = new DefaultUrlSerializer();
    return dus.serialize(tree);
  }
}
