import {NgModule} from '@angular/core';
import {FilterSidebarComponent} from './filter-sidebar.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
 import {NgSelectModule} from "@ng-select/ng-select";
import {NgxSliderModule} from "@angular-slider/ngx-slider";
import {NgbTypeaheadModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, NgSelectModule, NgxSliderModule, NgbTypeaheadModule, TranslateModule],
  declarations: [FilterSidebarComponent],
  exports: [FilterSidebarComponent]
})
export class FilterSidebarModule {}
