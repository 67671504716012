import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  @Input('type') type: any;

  jobContent = {
    'job_category' : 'Krankenpflege',
    'title' : 'Pflegehelfer m/w/d',
    'company_name' : 'Unternehmensname',
    'plz' : '87700',
    'ort' : 'Memmingen',
    'publish_date' : '13 Jan 22'
  }

  constructor(
    private _activatedRoute: ActivatedRoute,
    public activeModal: NgbActiveModal,
    private _modalService: NgbModal,
    private _router: Router
  ) {}

  ngOnInit(): void {

  }

  onClose() {
    if(this.type === 'favorite'){
      this._router.navigate([], {
        queryParams: {...this._activatedRoute.snapshot.queryParams, favorite: false},
      });
      this.activeModal.close();
      return;
    }
    this.activeModal.close();
  }
}
