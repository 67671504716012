<div *ngIf="!isIframeMode" class="container-fluid filters">
  <div class="row h-100">
    <div class="header">
      <img style="width: auto; height: 20px;" src="assets/img/logo.png" />
    </div>
  </div>
</div>

<div class="container-fluid map-wrap" [class.h-100]="isIframeMode">

  <filter-sidebar
    *ngIf="isOpenedFilterDrawer"
    [(stopMovePropagation)]="stopMovePropagation"
    [(centerMap)]="centerMap"
    [(selectedLocationProperties)]="selectedLocationProperties"
    [(initialCircle)]="initialCircle"
    (onClose)="closeFilterDrawer($event)"
    [allJobs]="layersDetails"
    [jobs]="filteredLayerDetails"
    (countOfAppliedFilters)="appliedFiltersCount = $event"
    >
  </filter-sidebar>
  
  <div>
    <search-result-sidebar tourAnchor="search-result-sidebar"
      [(isSidebarOpen)]="isSidebarOpen"
      [jobs]="filteredLayerDetails"
      (onSearch)="onSearchJobs($event)"
      (sortJobs)="sortJobs($event)"
      ></search-result-sidebar>
  </div>

  <div class="map-actions">
    <!-- Filter btn -->
    <button tourAnchor="map-actions-filter" [disabled]="displayFavoriteJobs" [ngStyle]="currentTourStepId == '1' && {'border': '2px solid #5223ca'}" (click)="openFilterDrawer()" class="btn btn-filter shadow-lg btn-shadow" [class.btn-opacity]="displayFavoriteJobs">
     <i class="bi bi-sliders"></i>
    </button>
    <div *ngIf="appliedFiltersCount > 0"> <span class="applied-filters-count">{{appliedFiltersCount}}</span></div>

    <!-- Favorites btn -->
    <button tourAnchor="map-actions-favorites"
            [ngStyle]="currentTourStepId == '2' && {'border': '2px solid #5223ca'}"
            class="btn btn-filter shadow-lg mt-4 btn-shadow" [class.btn-favorite]="displayFavoriteJobs"
            (click)="filterFavoriteJobs(true)">
      <i *ngIf="!displayFavoriteJobs" class="bi bi-star"></i>
      <i *ngIf="displayFavoriteJobs" class="bi bi-star-fill"></i>
    </button>
    <span *ngIf="displayFavoriteJobs" class="x-btn" (click)="filterFavoriteJobs(false)"><i class="bi bi-x"></i></span>
    <span *ngIf="!displayFavoriteJobs && (favoritesCount$ | async) > 0" class="x-btn favorite-count">{{(favoritesCount$ | async)}}</span>

  </div>
  
  <!-- Tour btn -->
  <div class="tour-btn-wrapper" (click)="openTourModal()">
    <button class="btn btn-filter shadow-lg btn-shadow">
      <i class="bi bi-info-lg" style="font-size: 18px;"></i>
    </button>
  </div>

  <div class="row h-100">
    <div class="col-md-12 col-xl-12 px-0 position-relative d-md-inline-block">
      <button
        class="btn btn-custom btn-sm map-search px-2 py-2 primary-shadow flex-center btn-shadow"
        style="text-transform: unset"
        [disabled]="_mainService.searching$ | async"
        (click)="searchArea()" *ngIf="mapMoved">
        {{'IN_DIESEM_BEREICH_SUCHEN' | translate }}
      </button>
      <div id="map"
           class="w-100 map-height"
           leaflet
           [leafletOptions]="options"
           [leafletFitBounds]="fitBounds"
           (leafletMapReady)="mapReady($event)"
           [leafletLayers]="initialCircle"
           (leafletMarkerClusterReady)="markerClusterReady($event)"
           [leafletMarkerCluster]="layers"
           [leafletMarkerClusterOptions]="clusterOptions">
      </div>
    </div>

    <div class="d-none display-cluster-container cluster-container" [class.sidebar-open]="isSidebarOpen" *ngIf="!!clusterJobs && clusterJobs.length > 0">
      <span class="founded-jobs-badge">{{clusterJobs.length}} {{clusterJobs.length > 1 ? "Jobs" : "Job"}}</span>
      <span class="cluster-close" (click)="closeCluster()">Schließen</span>
      <div class="cluster-jobs">
        <div class="cluster-job" *ngFor="let job of clusterJobs">
          <job-card [item]="job" [clusterSlider]="true"></job-card>
        </div>
      </div>
    </div>

    <div class="d-none display-carousel cluster-container w-100" [class.sidebar-open]="isSidebarOpen" *ngIf="!!clusterJobs && clusterJobs.length > 0">
      <div class="carousel-infos">
        <span class="carousel-founded-jobs-badge">{{clusterJobs.length}} {{clusterJobs.length > 1 ? "Jobs" : "Job"}}</span>
        <span class="carousel-cluster-close" (click)="closeCluster()">Schließen</span>
      </div>
      <div class="carousel-cluster-jobs">
        <ngb-carousel
          class="job-edit-carousel mb-5 text-center"
          [interval]="-1"
          (slide)="slideChange($event)"
          [activeId]="currentSlideIndex">
          <ng-template ngbSlide *ngFor="let splitJobs of splitClusterJobs">
            <div class="cluster-job" *ngFor="let job of splitJobs">
              <job-card [item]="job" [clusterSlider]="true"></job-card>
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div> 
    <div [class.d-inline-block]="!isSidebarOpen && filteredLayerDetails.length > 0 && clusterJobs.length == 0" class="d-none d-sm-none job-founded-btn"
          [ngStyle]="currentTourStepId == '3' && {'border': '2px solid #5223ca'}"
          tourAnchor="job-list-btn"
          (click)="openSideBar()">{{displayFavoriteJobs ? 'Favoritenliste' : 'Jobliste'}} ({{ displayFavoriteJobs ? (favoritesCount$ | async) : filteredLayerDetails.length}}) anzeigen</div>
   </div>

  <div id="supported-by-bpa" class="d-none d-sm-flex" (click)="onSupportedByBpaClick()">
    <div class="text-center">
      <div>Unterstützt</div>
      <div>vom</div>
    </div>
    <img src="/assets/img/bpa-logo.png" alt=""/>
  </div>

  <div class="d-flex d-sm-none" id="supported-by-bpa-mobile-screen" (click)="onSupportedByBpaClick()">
    <div>Unterstützt vom</div>
    <img src="/assets/img/bpa-logo.png" alt="">
  </div>

  <footer class="footer">
    <div class="row">
      <div class="col-4">
        <a href="legaldocs/impressum.html" target="_blank" class="footer-link">Impressum</a>
        </div>
      <div class="col-4">
         <a href="legaldocs/datenschutz.html" target="_blank" class="footer-link">Datenschutz</a>
       </div>
      <div class="col-4">
         <a (click)="_mainService.openModal(cmpId)" target="_blank" class="footer-link">Cookies</a>
      </div>
    </div>
  </footer>
</div>

<ng-template #tourModal>
  <div style="background: linear-gradient(164deg, white, #ffffffcc);border-radius: 12px;padding: 20px;">
    <div *ngIf="currentTourStepId == null" style="text-align: center;">
      <h3>Wie funktioniert #DubistPflege?</h3>
      <div style="font-size: 18px;color: #000000B3;">
        Möchtest du eine kurze Einführungstour starten, um dich mit den wichtigsten Funktionen vertraut zu machen?
      </div>
      <div class="d-flex justify-content-center" style="column-gap: 10px;margin-top: 13px;margin-bottom: 6px;">
        <button type="button" class="btn btn-outline-primary tour-no-btn" (click)="handleTourButtonClick(false)">Nein</button>
        <button type="button" class="btn btn-primary" (click)="handleTourButtonClick(true)">Ja</button>
      </div>
    </div>
    <div *ngIf="currentTourStepId == 0">
      <h3 class="text-center" [innerHTML]="_tourService.currentStep?.title"></h3>
      <div tourAnchor="jobmap" class="d-none"></div>
      <div class="d-flex justify-content-center" style="margin-top: 12px;margin-bottom: 12px;">
        <div class="d-flex" style="flex-direction: column;row-gap: 17px;text-align: start;line-height: 18px;color: #000000B3">
          <div class="d-flex align-items-center" style="column-gap: 10px;">
            <img style="width: 28px;height: 35px;" src="../../assets/img/pin.png" alt="">
            <span>Es befindet sich ein Job am markierten Ort.</span>
          </div>
          <div class="d-flex align-items-center" style="column-gap: 10px;">
            <img style="width: 28px;height: 35px;" src="../../assets/img/cluster-example.png" alt="">
            <span>Es befinden sich mehrere (3) Jobs am markierten Ort.</span>
          </div>
          <div class="d-flex align-items-center" style="column-gap: 10px;">
            <img style="width: 28px;height: 35px;" src="../../assets/img/favorite-pin.png" alt="">
            <span>Der Job am markierten Ort gehört zu deinen Favoriten.</span>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <div class="text-muted mb-3 pins-content-text" style="font-size: 15px;" [innerHTML]="_tourService.currentStep?.content"></div>
      </div>
      <div class="text-center">
        <div>
          <button *ngIf="_tourService.hasNext(_tourService.currentStep)" class="btn btn-primary btn-default" (click)="_tourService.next()" [innerHTML]="_tourService.currentStep?.nextBtnTitle"></button>
        </div>
        <button class="btn btn-default" (click)="_tourService.end()" style="color: #5223ca;" [innerHTML]="_tourService.currentStep?.endBtnTitle"></button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #introductionModal>
  <div class="introduction-modal position-relative">
    <div class="d-flex justify-content-end pointer" style="position: absolute;top:2px;right:6px;" (click)="closeIntroductionModal()">
      <i class="bi bi-x text-muted instructionModalX" style="font-size: 30px;"></i>
    </div>
    <img id="snapping-hand" src="../../assets/img/snapping-hand-with-jap-icon.svg" alt="">
    <div style="text-align: center;">
      <h3 id="welcome-to-dbp">Willkommen bei <span>#DubistPflege<img src="../../assets/img/fav-icon.png"></span></h3>
      <div id="welcome-to-dbp-subheading">
        Schön, dass du dich für Pflegejobs interessierst.
      </div>
      <div id="one-two-three-heading" class="text-muted">
        So einfach findest du Angebote in deiner Nähe:
      </div>
      <div class="d-flex justify-content-center" style="margin-bottom: 12px;">
        <div class="d-flex one-two-three-wrapper">
          <div class="d-flex align-items-center" style="column-gap: 6px;">
            <div class="position-relative" style="border: 2px solid rgba(82, 35, 202, 0);border-radius: 50%;min-width: 34px;min-height: 34px;width: 34px;height: 34px;">
              <span class="text-muted" style="font-size: 22px;position: absolute;left: 9px;top: -2px;color: rgba(82, 35, 202, 0.6);">1.</span>
            </div>
            <div *ngIf="!mainCompanyNameExistsInPathParams" style="line-height: 22px;font-size: 16px;">Gib deinen Wunschort ein.</div>
            <div *ngIf="mainCompanyNameExistsInPathParams" style="line-height: 22px;font-size: 16px;">Wähle einen Job auf der Karte oder der Liste.</div>
          </div>
          <div class="d-flex align-items-center" style="column-gap: 6px;">
            <div class="position-relative" style="border: 2px solid rgba(82, 35, 202, 0);border-radius: 50%;min-width: 34px;min-height: 34px;width: 34px;height: 34px;">
              <span class="text-muted" style="font-size: 22px;position: absolute;left: 9px;top: -2px;color: rgba(82, 35, 202, 0.6);">2.</span>
            </div>
            <div *ngIf="!mainCompanyNameExistsInPathParams" style="line-height: 22px;font-size: 16px;">Wähle einen Job auf der Karte oder der Liste und klicke „Zum Job“ für mehr Infos.</div>
            <div *ngIf="mainCompanyNameExistsInPathParams" style="line-height: 22px;font-size: 16px;">Klicke „Zum Job“ für mehr Infos.</div>
          </div>
          <div class="d-flex align-items-center" style="margin-top: 4px;column-gap: 6px;text-align: start;">
            <div class="position-relative" style="border: 2px solid rgba(82, 35, 202, 0);border-radius: 50%;min-width: 34px;min-height: 34px;width: 34px;height: 34px;">
              <span class="text-muted" style="font-size: 22px;position: absolute;left: 9px;top: -2px;color: rgba(82, 35, 202, 0.6);">3.</span>
            </div>
            <div style="line-height: 22px;font-size: 16px;">Bewirb dich in unter 1 Minute – einfach per Chat auch ohne Lebenslauf.</div>
          </div>
        </div>
      </div>

      <div class="form-group mb-1 main-filters position-relative autocomplete-form-control digital-input">
        <ng-template #rt let-r="result" let-t="term">
          <ngb-highlight [result]="r.display_label" [term]="t"></ngb-highlight>
        </ng-template>
        <!-- <i class="bi bi-search"></i> -->
        <div class="w-100 d-flex justify-content-center">
          <div class="position-relative" style="width:100%;max-width: 450px;">
            <label *ngIf="!mainCompanyNameExistsInPathParams" class="text-muted pl-1" id="where-to-search">Wie lautet dein Wunschort?</label>
            <div class="d-flex" style="column-gap: 8px;justify-content: center;margin-top: -2px;">
              <div *ngIf="!mainCompanyNameExistsInPathParams" class="position-relative">
                <input
                type="text"
                id="introduction-modal-location-input"
                class="form-control"
                inputmode="search"
                [resultTemplate]="rt"
                [(ngModel)]="locationModel"
                [ngbTypeahead]="searchLocation"
                [ngModelOptions]="{standalone: true}"
                [inputFormatter]="formatter"
                [disabled]="searchForm.disabled"
                (selectItem)="onSelect($event)"
                placeholder="{{'PLZ_ODER_ORT' | translate}}"/>
                <span *ngIf="!!locationModel" (click)="locationModel = ''" class="ng-clear-wrapper" style="position: absolute;top: 4px;right: 10px;"
                title="Clear all"><span aria-hidden="true" class="ng-clear close-icon-search">×</span></span>
              </div>
              <button type="button" class="btn btn-outline-primary" style="line-height: 1;height: 36px;white-space: nowrap;" (click)="searchFromIntroductionModal()">Los geht's!</button>
            </div>
          </div>
        </div>
      </div>

      <div style="display: flex;align-items: center;justify-content: center;">
        <input type="checkbox" [checked]="dontShowIntroductionModalNextTime" (change)="dontShowIntroductionModalNextTime = !dontShowIntroductionModalNextTime">
        <span class="text-muted pointer" style="margin-left: 5px;font-size: 14px;" (click)="dontShowIntroductionModalNextTime = !dontShowIntroductionModalNextTime">Beim nächsten Mal nicht mehr anzeigen.</span>
      </div>
    </div>
  </div>
</ng-template>
