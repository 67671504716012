import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BpaService } from '../../../services/bpa.service';
import {NgxSpinnerService} from 'ngx-spinner';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {v4 as uuidv4} from 'uuid';
import {COMPANY_CATEGORIES} from '@digitalhilft/utils/constans';
import * as _ from 'lodash';

@Component({
  selector: 'app-facilities',
  templateUrl: './facilities.component.html',
  styleUrls: ['./facilities.component.scss']
})
export class FacilitiesComponent implements OnInit {

  @ViewChild("alreadyConfiguredFormModal", {static: true}) alreadyConfiguredFormModal: ElementRef;
  sessionID: string;
  email: string;
  orderID: string;
  mainFacilityData: any;
  facilities: any;
  facilitiesAlreadyConfigured: boolean;
  companyCategories = COMPANY_CATEGORIES;
  urlRegExp = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  project: string;
  isCollapsed = [];
  activeFacilityIndex: number = 0;
  showWarning: boolean = false;
  facilitiesWithDuplicates = {};

  constructor(private _activatedRoute: ActivatedRoute,
              private _router: Router,
              private _bpaService: BpaService,
              private _spinner: NgxSpinnerService,
              private _fb: FormBuilder,
              private _modalController: NgbModal) { }

  ngOnInit(): void {
    this._spinner.show();
    if (sessionStorage.getItem("session_id")) {
      this.sessionID = sessionStorage.getItem("session_id");
    } else {
      this.sessionID = uuidv4();
      sessionStorage.setItem("session_id", this.sessionID);
    }
    this.facilitiesAlreadyConfigured = false;
    this.facilities = [];
    this._activatedRoute.queryParams
      .subscribe(params => {
        this.email = params.email;
        this.orderID = params.order;
        this._bpaService.getMainFacilityData(this.email, this.orderID)
                        .subscribe(res => {
                          this.mainFacilityData = res;

                          if (this.mainFacilityData.project?.length > 0) {
                            this.project = this.mainFacilityData.project;
                          } else {
                            this.project = "general";
                          }

                          if (this.mainFacilityData.facilities?.length > 0) {
                            this.facilitiesAlreadyConfigured = true;
                            this._bpaService.bpaBusinessLog(this.orderID, this.sessionID, "facilities-already-configured", this.project).subscribe();
                            this._modalController.open(this.alreadyConfiguredFormModal, {backdrop: "static", centered: true});
                            /* this.mainFacilityData.facilities.forEach(facility => {
                              this.facilities.push(
                                this._fb.group({
                                  company_name: [facility.company_name, Validators.required],
                                  zip: [facility.zip, [Validators.pattern(/^\d+$/), Validators.minLength(5), Validators.maxLength(5), Validators.required]],
                                  city: [facility.city, Validators.required],
                                  street: [facility.street, Validators.required],
                                  carrer_site_url: [facility.carrer_site_url],
                                  company_category: [facility.company_category ? facility.company_category : '', [Validators.minLength(1), Validators.required]],
                                  justapplai_email: [this.email, [Validators.email, Validators.required]],
                                  application_delivery_email: [facility.application_delivery_email, [Validators.email, Validators.required]]
                                })
                              );
                            }) */
                          } else {
                            for (let i = 0; i < this.mainFacilityData.number_of_facilities; i++) {
                              let facility = this._fb.group({
                                company_name: ['', Validators.required],
                                zip: ['', [Validators.pattern(/^\d+$/), Validators.minLength(5), Validators.maxLength(5), Validators.required]],
                                city: ['', Validators.required],
                                street: ['', Validators.required],
                                carrer_site_url: [''],
                                company_category: ['', [Validators.minLength(1), Validators.required]],
                                justapplai_email: [this.email, [Validators.email, Validators.required]],
                                application_delivery_email: ['', [Validators.email, Validators.required]]
                              });
                              facility.valueChanges.subscribe(() => this.processFacilityValueChanges());
                              this.facilities.push(facility);
                              this.facilitiesWithDuplicates[i] = [];
                            }
                          }
                          this._spinner.hide();
                        });
      }
    );
  }

  getFacilitiesConfigurationState() {
    return this.facilities.every(facility => {
      return facility.valid === true;
    });
  }

  processFacilityValueChanges() {
    this.showWarning = false;
    Object.keys(this.facilitiesWithDuplicates).forEach(key => {
      let selectedFacility = this.facilities[key];
      this.facilitiesWithDuplicates[key] = this.facilities.map((facility, index) => {
        if (index == key) {
          return null;
        } else if (selectedFacility.get("company_name").value == facility.get("company_name").value && selectedFacility.get("company_name").value != '' &&
                    selectedFacility.get("zip").value == facility.get("zip").value && selectedFacility.get("zip").value != '' &&
                    selectedFacility.get("city").value == facility.get("city").value && selectedFacility.get("city").value != '' &&
                    selectedFacility.get("street").value == facility.get("street").value && selectedFacility.get("street").value != '') {
          return index+1;
        } else {
          return null;
        }
      }).filter(i => i != null);
    });
  }

  toggleCollapse(index: number) {
      this.showWarning = false;
      if (this.isCollapsed[index]) {
        this.activeFacilityIndex = null;
      } else {
        this.activeFacilityIndex = index;
      }
  }

  openNextIncompleteFacility() {
    if (this.activeFacilityIndex != null) {
      if (this.facilities[this.activeFacilityIndex].valid) {
        let invalidFacilityFound = false;
        let difference;
        let tempIndex = _.cloneDeep(this.activeFacilityIndex) + 1;
        while (!invalidFacilityFound) {
          difference = this.facilities.length - tempIndex;
          if (difference == 0) {
            tempIndex = 0;
          }
          if (!this.facilities[tempIndex].valid) {
            this.activeFacilityIndex = tempIndex;
            if (Object.keys(this.facilities[tempIndex].controls).some(key => {
                return key != "justapplai_email" && this.facilities[tempIndex].get(key).value
              })) {
              this.showWarning = true;
            }
            invalidFacilityFound = true;
          }
          tempIndex += 1;
        }
      } else {
        // Tell the user to please finish the selected facility
        this.showWarning = true;
      }
    } else {
      // open the first facility that is not valid
      this.facilities.every((index, facility) => {
        if (facility.valid) {
          return true;
        } else {
          this.activeFacilityIndex = index;
          return false;
        }
      });
    }
    setTimeout(() => {
      document.getElementById("heading" + this.activeFacilityIndex).scrollIntoView({ behavior: 'smooth' });
    }, 200);
  }

  submitFacilitiesConfiguration(submittedFormModal) {
    this._spinner.show();
    let data = {
      email: this.email,
      order_id: this.orderID,
      facilities: []
    }
    this.facilities.forEach(facility => {
      data.facilities.push(facility.value);
    });
    this._bpaService.registerFacilities(data).subscribe(res => {
      this._bpaService.bpaBusinessLog(this.orderID, this.sessionID, "submit-facilities-configuration", this.project).subscribe();
      this._spinner.hide();
      this._modalController.open(submittedFormModal, {backdrop: "static", centered: true});
    });
  }

  closeModal() {
    this._modalController.dismissAll();
    sessionStorage.setItem("forwardedUserToLandingPage", "true");
    sessionStorage.setItem("forwardedUserOrderID", this.orderID);
    if (this._activatedRoute.snapshot.url[0].path == "bpa-recruiting-kampagne") {
      this._router.navigateByUrl('/bpa/recruiting-kampagne/registrierung');
    } else if (this.project != "general") {
      this._router.navigateByUrl(this.project + '/recruiting-kampagne/registrierung');
    } else {
      this._router.navigateByUrl('/recruiting-kampagne/registrierung');
    }
  }
  
  configureLater() {
    this._spinner.show();
    this._bpaService.registerFacilitiesLater(this.email, this.orderID)
                    .subscribe(res => {
                      this._bpaService.bpaBusinessLog(this.orderID, this.sessionID, "configuration-screen-on-configure-later-click", this.project).subscribe();
                      sessionStorage.setItem("forwardedUserToLandingPage", "true");
                      sessionStorage.setItem("forwardedUserOrderID", this.orderID);
                      this._spinner.hide();
                      if (this._activatedRoute.snapshot.url[0].path == "bpa-recruiting-kampagne") {
                        this._router.navigateByUrl('/bpa/recruiting-kampagne/registrierung');
                      } else if (this.project != "general") {
                        this._router.navigateByUrl(this.project + '/recruiting-kampagne/registrierung');
                      } else {
                        this._router.navigateByUrl('/recruiting-kampagne/registrierung');
                      }
                    });
  }

}
