import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BpaService } from '../../services/bpa.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import {v4 as uuidv4} from 'uuid';
import { MEMBERSHIP_ORGANISATIONS } from '@digitalhilft/utils/constans';
import { environment } from '../../../../environments/environment';
import * as _ from 'lodash';

@Component({
  selector: 'app-recruiting-campaign',
  templateUrl: './recruiting-campaign.component.html',
  styleUrls: ['./recruiting-campaign.component.scss']
})
export class RecruitingCampaignComponent implements OnInit {

  projectName: string;
  showConfigureFacilities: boolean;
  membershipOrganisations: any;
  facilityDataAreTheSameAsCompanyData: boolean;
  environment = environment;

  @ViewChild('verificationLinkSentModal') verificationLinkSentModal: any;
  @ViewChild('submittedFormModal') submittedFormModal: any;
  @ViewChild('orderVerifiedModal') orderVerifiedModal: any;
  orderID: string;

  faqToggleActive: boolean = false;

  faqList = [
    {
      question: "Was kostet die Teilnahme?",
      answer: "Die Laufzeit der Kampagne beträgt 6 Monate. Die Kosten betragen 350 Euro netto je teilnehmende Einrichtung für 6 Monate. Der Preis beinhaltet: Medien- und Agenturkosten, automatische Übernahme der Stellen in die JobMap. Auf Wunsch kann die Laufzeit verlängert werden. Es werden keine weiteren Kosten fällig.",
      selected: false
    },
    {
      question: "Wie funktioniert #DubistPflege?",
      answer: "#DubistPflege ist eine Social Media Kampagne auf Facebook und Instagram, die sich gezielt an ehemalige Pflegekräfte, Azubis und Quereinsteiger richtet. Diese finden bei Interesse mit einem Klick eine vollständige JobMap mit allen teilnehmenden Einrichtungen. Dort können sie ganz einfach mobil direkt mit Ihnen in Kontakt treten. Die Ausspielung der Jobs erfolgt vollautomatisch ohne Aufwand für Sie.",
      selected: false
    },
    {
      question: "Warum soll ich an einer weiteren Kampagne teilnehmen?",
      answer: "Weil #DubistPflege anders und eine ideale Ergänzung zu Ihren eigenen Kampagnen ist. Sie richtet sich gezielt an Zielgruppen, die sonst nicht angesprochen werden: Ehemalige Pflegekräfte, die den Job verlassen haben, Quereinsteiger und Azubis. Dadurch werden zusätzliche Kräfte gewonnen.",
      selected: false
    },
    {
      question: "Wie funktioniert die Registrierung?",
      answer: "Bei der Registrierung geben Sie die wichtigsten Informationen zu Ihrem Unternehmen in die Eingabemaske ein. Zur Bestätigung der Teilnahme erhalten Sie eine E-Mail mit einem Bestätigungslink, auf den Sie zum Abschluss der Bestellung klicken müssen. Prüfen Sie ggf. auch Ihren Spam-Ordner. Nach der Bestätigung erhalten Sie eine weitere E-Mail, über die Sie die Informationen zu Ihrer Einrichtung eintragen können.",
      selected: false
    },
    {
      question: "Ich habe noch Fragen.",
      answer: "Melden Sie sich jetzt zu unseren kostenlosen und unverbindlichen Webinaren zum Projekt an (Dauer max. 30 min):",
      selected: false
    }
  ];

  emailForm = this._fb.group({
    order_id: [''],
    email: ['', [Validators.email, Validators.required]],
    project: [''],
    configure_facilities_after_validation: ['']
  });

  furtherInformationForm = this._fb.group({
    email: ['', [Validators.email, Validators.required]],
    membership_organisation: ['Sonstige'],
    membership_nr: [''],
    number_of_facilities: ['', [Validators.pattern(/^[1-9]\d*$/), Validators.required]]
  });

  companyDetailsForm = this._fb.group({
    company_name: ['', Validators.required],
    zip: ['', [Validators.pattern(/^\d+$/), Validators.minLength(5), Validators.maxLength(5), Validators.required]],
    city: ['', Validators.required],
    street: ['', Validators.required],
    contact_name: ['', Validators.required],
    contact_surname: [''],
    contact_email: ['', [Validators.email, Validators.required]],
    contact_phone: [''],
    internal_ref_id: ['']
  });

  selectedPricePackage: any;
  selectedPricePackageId: any;

  invoiceForm = this._fb.group({
    bill_to_company_name: ['', Validators.required],
    bill_to_zip: ['', [Validators.pattern(/^\d+$/), Validators.minLength(5), Validators.maxLength(5), Validators.required]],
    bill_to_city: ['', Validators.required],
    bill_to_street: ['', Validators.required],
    bill_to_name: ['', Validators.required],
    bill_to_surname: [''],
    bill_to_email: ['', [Validators.email, Validators.required]],
    bill_to_phone: [''],
    internal_ref_id: ['']
  });

  pricePackages: any;

  participateNowWithEmailFromFaq: boolean = false;

  currentForm: string;
  isEmailVerified: boolean;

  invoiceCheckboxIsChecked: boolean;
  email: string;
  sessionID: string;

  billInfoWillBeSentLater: boolean;

  project: string;

  configureFacilitiesAfterValidation: any;

  // this email is used to track whether the registrant came from our email
  emailFromEmailDistribution: string;
  projectLogo: any;
  hideLogonLogo: boolean = false;

  constructor(private _bpaService: BpaService,
              private _fb: FormBuilder,
              private cd: ChangeDetectorRef,
              private modalController: NgbModal,
              private _spinner: NgxSpinnerService,
              private _router: Router,
              private _route: ActivatedRoute) { }

  ngOnInit(): void {
    this.facilityDataAreTheSameAsCompanyData = false;
    this.invoiceCheckboxIsChecked = false;
    this.emailFromEmailDistribution = this._route.snapshot.queryParamMap.get("email");
    if (this._route.snapshot.url[0].path != "recruiting-kampagne") {
      this.project = this._route.snapshot.url[0].path;
      if (this.project == "bpa") {
        this.furtherInformationForm.patchValue({membership_organisation: "bpa"})
      }
      this.setMembershipOrganisations();
    } else {
      this.project = "general";
      this.setMembershipOrganisations();
    }

    this._bpaService.getProjectConfig(this.project).subscribe(projectConfig => {
      this.project = projectConfig.project;
      this.processProjectConfig(projectConfig);

      this.emailForm.get("project").setValue(this.project);
      if (sessionStorage.getItem("session_id")) {
        this.sessionID = sessionStorage.getItem("session_id");
      } else {
        this.sessionID = uuidv4();
        sessionStorage.setItem("session_id", this.sessionID);
      }
      if (sessionStorage.getItem("continueRegistrationFormEmail")) {
        this._bpaService.getMainFacilityData(sessionStorage.getItem("continueRegistrationFormEmail"), sessionStorage.getItem("continueRegistrationFormOrderID")).subscribe(res => {
          this.emailForm.patchValue({"configure_facilities_after_validation": res.configure_facilities_after_validation});
          sessionStorage.setItem("emailForm", this.emailForm.value)
          this.project = res.project;
          this._bpaService.bpaBusinessLog(sessionStorage.getItem("continueRegistrationFormOrderID"), this.sessionID, "forwarded-user-to-landing-page", this.project).subscribe();
          this.setRegistrationFormData(res);
          this.setInitialForm();
          sessionStorage.removeItem("continueRegistrationFormEmail");
          sessionStorage.removeItem("continueRegistrationFormOrderID");
          sessionStorage.setItem("landingPageAlreadyTrackedInThisSession", "true");
          this.setPricePackageAndInvoiceCheckbox();
        });
      } else {
        // logging landing-page depending on where user came from
        if (sessionStorage.getItem("forwardedUserToLandingPage")) {
          this._bpaService.bpaBusinessLog(sessionStorage.getItem("forwardedUserOrderID"), this.sessionID, "forwarded-user-to-landing-page", this.project).subscribe();
          sessionStorage.removeItem("forwardedUserToLandingPage");
          sessionStorage.removeItem("forwardedUserOrderID");
        } else if (!sessionStorage.getItem("landingPageAlreadyTrackedInThisSession")) {
          sessionStorage.setItem("landingPageAlreadyTrackedInThisSession", "true");
          this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "landing-page", this.project, this.emailFromEmailDistribution).subscribe();
        }
        this.loadSessionStorageData();
        this.setInitialForm();
      }
    });
    
  }

  processProjectConfig(projectConfig: any) {
    this.emailForm.get("configure_facilities_after_validation").setValue(projectConfig.configure_facilities_after_validation);
    this.configureFacilitiesAfterValidation = projectConfig.configure_facilities_after_validation;
    this.pricePackages = projectConfig.price_packages;
    this.projectLogo = projectConfig.logo;
    this.hideLogonLogo = projectConfig.hide_logon_logo;
    //this.projectLogo = "https://logon-tech.io/assets/images/logo.svg";
    if (this.pricePackages?.length > 1) {
      this.pricePackages.sort((a,b) => {
        if (a["valid_time"] < b["valid_time"]) {
          return -1;
        } else {
          return 1;
        }
      });
      if (localStorage.getItem("selectedPricePackageId") && localStorage.getItem("selectedPricePackageIdProject") == this.project) {
        this.selectedPricePackageId = Number(localStorage.getItem("selectedPricePackageId"));
      } else {
        localStorage.removeItem("selectedPricePackageId");
        localStorage.removeItem("selectedPricePackageIdProject");
        this.selectedPricePackageId = this.pricePackages[1].id;
      }
    } else {
      this.selectedPricePackage = this.pricePackages[0];
      this.selectedPricePackageId = this.selectedPricePackage.id;
    }
  }

  setMembershipOrganisations() {
    this.membershipOrganisations = MEMBERSHIP_ORGANISATIONS;
    this.membershipOrganisations.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }

  setRegistrationFormData(data: any) {
    this.isEmailVerified = data.order_verified == 1;

    this.emailForm.get("email").setValue(data.email);
    this.emailForm.get("order_id").setValue(data.order_id);

    this.furtherInformationForm.get("email").setValue(data.email);
    this.furtherInformationForm.get("membership_nr").setValue(data.membership_nr);
    this.furtherInformationForm.get("number_of_facilities").setValue(data.number_of_facilities);
    
    this.companyDetailsForm.get("company_name").setValue(data.company_name);
    this.companyDetailsForm.get("zip").setValue(data.zip);
    this.companyDetailsForm.get("city").setValue(data.city);
    this.companyDetailsForm.get("street").setValue(data.street);
    this.companyDetailsForm.get("contact_name").setValue(data.contact_name);
    this.companyDetailsForm.get("contact_surname").setValue(data.contact_surname);
    this.companyDetailsForm.get("contact_email").setValue(data.contact_email);
    this.companyDetailsForm.get("contact_phone").setValue(data.contact_phone);
    this.companyDetailsForm.get("internal_ref_id").setValue(data.internal_ref_id);
    
    this.invoiceForm.get("bill_to_company_name").setValue(data.bill_to_company_name);
    this.invoiceForm.get("bill_to_zip").setValue(data.bill_to_zip);
    this.invoiceForm.get("bill_to_city").setValue(data.bill_to_city);
    this.invoiceForm.get("bill_to_street").setValue(data.bill_to_street);
    this.invoiceForm.get("bill_to_name").setValue(data.bill_to_name);
    this.invoiceForm.get("bill_to_surname").setValue(data.bill_to_surname);
    this.invoiceForm.get("bill_to_email").setValue(data.bill_to_email);
    this.invoiceForm.get("bill_to_phone").setValue(data.bill_to_phone);
    this.invoiceForm.get("internal_ref_id").setValue(data.internal_ref_id);

    sessionStorage.setItem('emailForm', JSON.stringify(this.emailForm.value));
    sessionStorage.setItem('furtherInformationForm', JSON.stringify(this.furtherInformationForm.value));
    sessionStorage.setItem('companyDetailsForm', JSON.stringify(this.companyDetailsForm.value));
    sessionStorage.setItem('selectedPricePackage', JSON.stringify(this.selectedPricePackage));
    sessionStorage.setItem('invoiceForm', JSON.stringify(this.invoiceForm.value));

    this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "continue-registration-process-modal", this.project).subscribe();

    this.modalController.open(this.orderVerifiedModal, {
      size: 'md',
      centered: true
    });
  }

  participateNowFromFAQ() {
    if (this.emailForm.valid) {
      this.participateNowWithEmailFromFaq = true;
      this.selectForm('furtherInformationForm');
    } else {
      this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "participate-now-without-email-from-faq", this.project).subscribe();
      this.currentForm = 'furtherInformationForm';
    }
    localStorage.setItem("selectedPricePackageId", this.selectedPricePackageId);
    localStorage.setItem("selectedPricePackageIdProject", this.project);
  }

  participateNowWithoutEmail() {
    this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "participate-now-without-email", this.project).subscribe();
    this.currentForm = 'furtherInformationForm';
  }

  loadSessionStorageData() {
    let forms = [
      sessionStorage.getItem('emailForm'),
      sessionStorage.getItem('furtherInformationForm'),
      sessionStorage.getItem('companyDetailsForm'),
      sessionStorage.getItem('invoiceForm')
    ];

    let formsDict = {
      0: this.emailForm,
      1: this.furtherInformationForm,
      2: this.companyDetailsForm,
      3: this.invoiceForm
    }

    for (const [formsDictKey, formsDictValue] of Object.entries(formsDict)) {
      if (forms[formsDictKey]) {
        for (const [key, value] of Object.entries(JSON.parse(forms[formsDictKey]))) {
          formsDict[formsDictKey].get(key).setValue(value);
        }
      }
    }
    this.setPricePackageAndInvoiceCheckbox();
  }

  setPricePackageAndInvoiceCheckbox() {
    if (this.companyDetailsForm.valid && !this.invoiceForm.valid) {
      this.invoiceCheckboxIsChecked = true;
      this.invoiceForm.patchValue({bill_to_email: this.emailForm.get("email").value});
    } else {
      this.invoiceCheckboxIsChecked = false;
    }
    this.configureFacilitiesAfterValidation = this.emailForm.get("configure_facilities_after_validation").value;
    if (sessionStorage.getItem('selectedPricePackage') != "undefined") {
      this.selectedPricePackage = JSON.parse(sessionStorage.getItem('selectedPricePackage'));
    }
    if (this.selectedPricePackage) {
      this.selectedPricePackageId = this.selectedPricePackage.id
    }
    if (sessionStorage.getItem("invoiceCheckboxIsChecked") == "true") {
      this.invoiceCheckboxIsChecked = true;
    }
  }

  checkEmailVerificationState() {
    const email = this.emailForm.get("email").value;
    const orderID = this.emailForm.get("order_id").value;
    this._bpaService.getMainFacilityData(email, orderID).subscribe(res => {
      this.isEmailVerified = res.order_verified == 1;
      this.resetRegistration();
      if (this.isEmailVerified) {
        this._bpaService.bpaBusinessLog(orderID, this.sessionID, "forward-verified-user-to-facilities-configuration-screen", this.project).subscribe();
        this._spinner.hide();
        this._router.navigateByUrl('/recruiting-kampagne/erfolgreich-registriert?email=' + email + "&order=" + orderID);
      } else {
        this._spinner.hide();
        this.modalController.open(this.submittedFormModal, {
          size: 'md',
          centered: true,
          backdrop: 'static'
        });
      }
    });
  }

  setInitialForm() {
    // For displaying the right form after refreshing the page
    if (!this.emailForm.valid && !this.furtherInformationForm.valid) {
      this.currentForm = "emailForm";
    } else if (this.emailForm.valid && !this.furtherInformationForm.valid) {
      this.currentForm = "furtherInformationForm";
    } else if (this.furtherInformationForm.valid && !this.companyDetailsForm.valid) {
      this.currentForm = "companyDetailsForm";
    } else if (this.companyDetailsForm.valid && this.pricePackages && !this.selectedPricePackage) {
      this.currentForm = "pricePackageForm";
    } else if (this.companyDetailsForm.valid && !this.pricePackages && !this.invoiceForm.valid) {
      this.currentForm = "invoiceForm";
    } else if (this.selectedPricePackage && !this.invoiceForm.valid) {
      this.currentForm = "invoiceForm";
    }
  }

  closeModal() {
    this.modalController.dismissAll();
  }

  onFacilitySameAsCompanyCheckboxChange(event: any) {
    let checkboxIsChecked = event.target.checked;
    if (checkboxIsChecked) {
      this.facilityDataAreTheSameAsCompanyData = true;
      this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "facility-data-same-as-company-data-checkbox-checked", this.project).subscribe();
    } else {
      this.facilityDataAreTheSameAsCompanyData = false;
      this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "facility-data-same-as-company-data-checkbox-unchecked", this.project).subscribe();
    }
  }

  onCheckboxChange(event: any) {
    this.invoiceCheckboxIsChecked = event.target.checked;
    this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "different-invoice-address-checkbox-click", this.project).subscribe();
  }

  openWebinarRegistrationInNewTab() {
    let webinarLink;
    if (this.project != "general") {
      webinarLink = this.environment.dubistpflegeUrl + this.project + '/recruiting-kampagne/webinar-anmeldung'
    } else {
      webinarLink = this.environment.dubistpflegeUrl + 'recruiting-kampagne/webinar-anmeldung'
    }
    window.open(webinarLink,'_blank');
  }

  onToggleClick(toggleName: string, questionIndex?: number) {
    if (toggleName === "faq") {
      this.faqToggleActive = !this.faqToggleActive;
      this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "faq-toggle-click", this.project).subscribe();
    } else if (toggleName === "faq-question") {
      this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "faq-question-" + (questionIndex+1) + "-click", this.project).subscribe();
      this.faqList.forEach((question, index) => {
        if (questionIndex == index) {
          question.selected = !question.selected;
        }
      });
    }

  }

  sendBillInfoLater() {
    this.billInfoWillBeSentLater = true;
    this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "send-invoice-data-later", this.project).subscribe();
    this._bpaService.sendBillInfoLater(this.emailForm.get("email").value, this.emailForm.get("order_id").value).subscribe();
    this.submitRegistration();
  }

  selectForm(formName: string) {
    // Setting the session storage form and selecting the next form to be displayed
    switch(formName) {
      case 'furtherInformationForm':
        this._spinner.show();
        this.furtherInformationForm.get("email").setValue(this.emailForm.get("email").value);
        let registrationForm = {
          ...this.emailForm.value
        }
        this._bpaService.register(registrationForm).subscribe(res => { 
          this.emailForm.get("order_id").setValue(res.order_id);
          sessionStorage.setItem('emailForm', JSON.stringify(this.emailForm.value));
          if (this.participateNowWithEmailFromFaq) {
            this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "participate-now-with-email-from-faq", this.project).subscribe();
          } else {
            this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "participate-now-with-email", this.project).subscribe();
          }
          this._spinner.hide();
        });
        break;
      case 'companyDetailsForm':
        sessionStorage.setItem('emailForm', JSON.stringify(this.emailForm.value));
        sessionStorage.setItem('furtherInformationForm', JSON.stringify(this.furtherInformationForm.value));
        break;
      case 'pricePackageForm':
        sessionStorage.setItem('companyDetailsForm', JSON.stringify(this.companyDetailsForm.value));
        sessionStorage.setItem('invoiceCheckboxIsChecked', this.invoiceCheckboxIsChecked.toString());
        sessionStorage.setItem('invoiceForm', JSON.stringify(this.invoiceForm.value));
        break;
      case 'invoiceForm':
        sessionStorage.setItem('selectedPricePackage', JSON.stringify(this.pricePackages.find(pricePackage => pricePackage["id"] == this.selectedPricePackageId)));
        sessionStorage.setItem('companyDetailsForm', JSON.stringify(this.companyDetailsForm.value));
        sessionStorage.setItem('invoiceCheckboxIsChecked', this.invoiceCheckboxIsChecked.toString());
        sessionStorage.setItem('invoiceForm', JSON.stringify(this.invoiceForm.value));
        this.invoiceForm.get("internal_ref_id").setValue(this.companyDetailsForm.get("internal_ref_id").value);
        break;
    }
    this.currentForm = formName;
  }

  copyCompanyDetailsAndContactDataToInvoiceForm() {
    this.invoiceForm.get("bill_to_company_name").setValue(this.companyDetailsForm.get("company_name").value);
    this.invoiceForm.get("bill_to_zip").setValue(this.companyDetailsForm.get("zip").value);
    this.invoiceForm.get("bill_to_city").setValue(this.companyDetailsForm.get("city").value);
    this.invoiceForm.get("bill_to_street").setValue(this.companyDetailsForm.get("street").value);
    this.invoiceForm.get("bill_to_name").setValue(this.companyDetailsForm.get("contact_name").value);
    this.invoiceForm.get("bill_to_surname").setValue(this.companyDetailsForm.get("contact_surname").value);
    // bill_to_email gets set after furtherInformationForm is sent and has the email of the furtherInformationForm
    // this.invoiceForm.get("bill_to_email").setValue(this.companyDetailsForm.get("contact_email").value);
    this.invoiceForm.get("bill_to_phone").setValue(this.companyDetailsForm.get("contact_phone").value);
    this.invoiceForm.get("bill_to_phone").setValue(this.companyDetailsForm.get("contact_phone").value);
    this.invoiceForm.get("internal_ref_id").setValue(this.companyDetailsForm.get("internal_ref_id").value);
    sessionStorage.setItem('invoiceForm', JSON.stringify(this.invoiceForm.value));
  }

  registerEmail() {
    this._spinner.show();
    if (!this.emailForm.valid) {
      this.emailForm.get("email").setValue(this.furtherInformationForm.get("email").value);
    }
    this.invoiceForm.get("bill_to_email").setValue(this.furtherInformationForm.get("email").value);
    let registrationForm = {
      ...this.emailForm.value,
      ...this.furtherInformationForm.value
    }
    this._bpaService.register(registrationForm).subscribe(res => {
      this.emailForm.get("order_id").setValue(res.order_id);
      this._bpaService.bpaBusinessLog(res.order_id, this.sessionID, "send-number-of-facilities-form", this.project).subscribe();
      this._bpaService.orderVerificationEmail(this.emailForm.get("email").value, this.emailForm.get("order_id").value).subscribe(res => {
        this.email = this.emailForm.get("email").value;
        this.selectForm('companyDetailsForm');
        this._spinner.hide();
        this.modalController.open(this.verificationLinkSentModal, {
          size: 'md',
          centered: true
        });
      });
    });
  }

  resetRegistration() {
    this.emailForm.reset();
    if (this._route.snapshot.url[0].path != "recruiting-kampagne") {
      this.project = this._route.snapshot.url[0].path;
    } else {
      this.project = "general";
    }
    this.emailForm.patchValue({project: this.project});
    this.companyDetailsForm.reset();
    this.invoiceForm.reset();
    this.furtherInformationForm.reset();
    this.furtherInformationForm.patchValue({membership_organisation: "Sonstige"});
    this.selectedPricePackage = null;
    sessionStorage.removeItem('emailForm');
    sessionStorage.removeItem('furtherInformationForm');
    sessionStorage.removeItem('companyDetailsForm');
    sessionStorage.removeItem('invoiceCheckboxIsChecked');
    sessionStorage.removeItem('selectedPricePackage');
    sessionStorage.removeItem('invoiceForm');
    this.currentForm = "emailForm";
    if (localStorage.getItem("selectedPricePackageId")) {
      localStorage.removeItem("selectedPricePackageId");
      localStorage.removeItem("selectedPricePackageIdProject");
    }
  }

  setFacilityDataSameAsCompanyData() {
    this.emailForm.get("configure_facilities_after_validation").setValue(0);
    sessionStorage.setItem('emailForm', JSON.stringify(this.emailForm.value));
    this.configureFacilitiesAfterValidation = "0";
    let facility = this._fb.group({
      company_name: [this.companyDetailsForm.get("company_name").value],
      zip: [this.companyDetailsForm.get("zip").value],
      city: [this.companyDetailsForm.get("city").value],
      street: [this.companyDetailsForm.get("street").value],
      carrer_site_url: [''],
      company_category: [''],
      justapplai_email: [this.emailForm.get("email").value],
      application_delivery_email: ['']
    });
    let data = {
      ...this.emailForm.value,
      facilities: []
    }
    data.facilities.push(facility.value);
    this._bpaService.registerFacilities(data).subscribe(res => {
      this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "facility-was-created-automatically", this.project).subscribe();
    });
  }

  submitRegistration() {
    this._spinner.show();
    // EACH FORM MUST BE SUBMITTED FROM THIS FUNCTION: COMPANY, PRICE PACKAGE, INVOICE
    if (this.currentForm == "companyDetailsForm") {
      if (this.facilityDataAreTheSameAsCompanyData) {
        this.setFacilityDataSameAsCompanyData();
      }
      // if the condition is true, then the invoice data are the same as company data
      if (!this.invoiceCheckboxIsChecked) {
        this.copyCompanyDetailsAndContactDataToInvoiceForm();
        if (!this.pricePackages) {
          this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "submit-registration-from-company-details", this.project).subscribe();
        } else {
          this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "send-company-details-form", this.project).subscribe();
        }
      } else {
        if (this.companyDetailsForm.get("internal_ref_id")?.value?.length > 1) {
          this.invoiceForm.get("internal_ref_id").setValue(this.companyDetailsForm.get("internal_ref_id").value);
        }
        this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "send-company-details-form", this.project).subscribe();
      }
    } else if (this.currentForm == "pricePackageForm") {
      this.selectedPricePackage = this.pricePackages.find(pricePackage => pricePackage["id"] == this.selectedPricePackageId);
      // if the condition is true, then the invoice data are the same as company data
      if (!this.invoiceCheckboxIsChecked) {
        this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "submit-registration-from-price-packages-screen", this.project).subscribe();
      }
    } else if (this.currentForm == "invoiceForm") {
      this.selectedPricePackage = this.pricePackages.find(pricePackage => pricePackage["id"] == this.selectedPricePackageId);
      this._bpaService.bpaBusinessLog(this.emailForm.get("order_id").value, this.sessionID, "submit-registration-from-invoice-screen", this.project).subscribe();
    }
    let registrationForm = {
      ...this.emailForm.value,
      ...this.companyDetailsForm.value,
      ...this.invoiceForm.value,
      ...this.furtherInformationForm.value
    }
    if ((this.currentForm == "companyDetailsForm" && this.pricePackages?.length == 1) || this.currentForm == "pricePackageForm" || this.currentForm == "invoiceForm") {
      if (this.pricePackages?.length == 1) {
        this.selectedPricePackage = this.pricePackages[0];
      }
      registrationForm["package_id"] = this.selectedPricePackage.id;
      registrationForm["valid_time"] = this.selectedPricePackage.valid_time;
      registrationForm["valid_time_unit"] = this.selectedPricePackage.valid_time_unit;
      registrationForm["price_amount"] = this.selectedPricePackage.price_amount;
      registrationForm["price_currency"] = this.selectedPricePackage.price_currency;
    }
    this.email = registrationForm['email'];
    this._bpaService.register(registrationForm).subscribe(res => {
      // Check if the current form is the last form and only then reset the forms and remove the session storage data
      if ((this.currentForm == "companyDetailsForm" && !(this.pricePackages?.length > 1) && !this.invoiceCheckboxIsChecked) ||
          (this.currentForm == "pricePackageForm" && !this.invoiceCheckboxIsChecked) ||
          this.currentForm == "invoiceForm") {
        this.checkEmailVerificationState();
      } else {
        if (this.currentForm == "companyDetailsForm") {
          if (this.pricePackages?.length > 1) {
            this.selectForm("pricePackageForm");
          } else {
            this.selectForm("invoiceForm");
          }
        } else if (this.currentForm = "pricePackageForm") {
          this.selectForm("invoiceForm");
        }
      }
      this._spinner.hide();
    });
  }

}
