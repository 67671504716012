import {Component} from '@angular/core';

@Component({
  selector: 'app-iframe-mode',
  template: `<map-screen [isIframeMode]="true"></map-screen>`,
})

export class IFrameModeViewComponent{

  constructor() {}
}
