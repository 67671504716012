// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  dubistpflegeUrl: 'https://dubistpflege.talentedgeai.com/',
  dubistfmUrl: 'https://app.justappl.talentedgeai.com/',
  //apiUrl: 'https://test-api.dubistpflege.org/api'
  apiUrl: 'https://dubistpflege.talentedgeai.com/api'
};


import 'zone.js/plugins/zone-error';
