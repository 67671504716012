import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SafePipe} from './safe.pipe';
import {HyphenatePipe} from './hyphenate.pipe';



@NgModule({
  declarations: [
    SafePipe,
    HyphenatePipe
  ],
  imports: [
    CommonModule
  ],
  exports:[
    SafePipe,
    HyphenatePipe
  ]
})
export class PipesModule { }
