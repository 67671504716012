import {Component, Input, OnInit} from '@angular/core';
import {MainService} from '../../services/main.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.scss']
})
export class JobCardComponent implements OnInit {
  @Input('item') item;
  @Input('clusterSlider') clusterSlider;

  isFavorite$: Observable<boolean>

  cmpParams: string[] = ['cmpId','utm_campaign', 'utm_medium', 'utm_source', 'utm_content'];

  constructor(private _mainService: MainService, private _route: ActivatedRoute) {}

  ngOnInit(): void {
     this.isFavorite$ = this._mainService.favorites$.pipe(map(items =>
       items?.filter(id => id === this.item?.ad_id)?.length !== 0
    ));
  }

  toggleFavorite(): void {
    this._mainService.toggleFavorite(this.item.ad_id);
  }

  visitJob(): void {
    let url = (this.item?.apply_url || this.item.url) + this.getCampaignParams();
    this.item['is_visited'] = true;
    this._mainService.addVisitedJob(this.item.ad_id)

    window.open(url, "_blank")
  }

  private getCampaignParams(): string {
    const urlObj = this._route.snapshot.queryParams
    let query = '?';

    for (const key in urlObj) {
      if (urlObj.hasOwnProperty(key) && this.cmpParams.includes(key) && urlObj[key]) {
        if(key === 'utm_source' && urlObj['cmpId']) {
          query += `${key}=${urlObj['cmpId']}&`;
        }else{
          query += `${key}=${urlObj[key]}&`;
        }

      }
    }

    query = query.substr(0, query.length - 1);
    return query;
  }
}
