import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, NgZone, ViewChild} from '@angular/core';
import {circle, Control, FeatureGroup, icon, LatLng, LatLngBounds, MapOptions, marker, MarkerOptions, latLng, Map} from 'leaflet';
import {MainService} from '../@digitalhilft/services/main.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {clusterOptions, mapOptions} from '../@digitalhilft/utils/helper';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import Zoom = Control.Zoom;
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {TAG_MANAGER_CONFIGS} from '../app.module';
import {JOB_CATEGORIES, SEARCH_FILTERS} from '../@digitalhilft/utils/constans';
import {TourService, IStepOption} from 'ngx-tour-ng-bootstrap';
import {SearchResultSidebarComponent} from '../@digitalhilft/components/search-result-sidebar/search-result-sidebar.component';

export interface MyMarkerOptions extends MarkerOptions {
  data: any;
}

@Component({
  selector: 'map-screen',
  templateUrl: './map-screen.component.html',
  styleUrls: ['./map-screen.component.scss'],
})

export class MapScreenComponent implements AfterViewInit {
  @Input() isIframeMode: boolean = false;

  @ViewChild('companyNameFilter') companyNameFilter;
  @ViewChild('jobArtFilter') jobArtFilter;
  @ViewChild('publishDateFilter') publishDateFilter;
  @ViewChild('sortType') sortType;
  @ViewChild('introductionModal') introductionModal: ElementRef;
  @ViewChild('tourModal') tourModal: ElementRef;
  @ViewChild(SearchResultSidebarComponent) searchResultSidebarComponent: SearchResultSidebarComponent;
  defaultSearchProperties: { city: string, zip_code: number, distance: number, latitude: string, longitude: string } = {
    city: '99817 Eisenach',
    zip_code: 99817,
    distance: 450,
    latitude: '50.9832',
    longitude: '10.2975'
  };
  appliedFiltersCount: number;
  searchFormDistance: number = 50;

  IS_SCROLL_ZOOM_ENABLED: boolean = true;
  isOpenedFilterDrawer: boolean = false;
  isSidebarOpen: boolean = true;
  messageModal: any;

  selectedLocationProperties: any = null;
  firstTimeSearch: boolean = true;
  centerMap: boolean = true;
  extendItem: boolean = false;
  displayFavoriteJobs: boolean = false;
  stopMovePropagation: boolean = true;
  stopUrlPropagation: boolean = false;
  searchType: 'search' | 'map' = 'search';
  type: string[] = ['Alle', 'Azubi', 'Dualer Student', 'Quereinsteiger'];


  // map properties
  map: Map;
  mapMoved: boolean = false;
  options: MapOptions = mapOptions;
  previousMapZoom: any;
  initialCircle: any = null;

  searchBoundPoints = [];
  saveSearchBoundPoints = false;
  fitBounds: LatLngBounds = new LatLngBounds([[53.333654, 3.910650], [47.163006, 14.464567]]);

  clusterOptions = clusterOptions;
  clusterJobs = [];
  splitClusterJobs = [];
  latestCluster = null;

  layers = [];
  layersDetails = [];
  filteredLayerDetails = [];
  filteredJobsFromDrawer: any[] = [];

  favoritesCount$: Observable<number> = this._mainService.favorites$.pipe(map(data => data?.length || 0));

  currentSlideIndex;
  width = window.innerWidth;
  currentTourStepId: string = null;
  
  // instruction modal properties
  dontShowIntroductionModalNextTime: boolean = false;
  locationModel: any;
  searchForm: FormGroup;
  localSearchCtrl = new FormControl();
  searchFilter = SEARCH_FILTERS;
  mainCompanyNameExistsInPathParams: boolean;

  cmpId: string;

  constructor(
    public _mainService: MainService,
    private _zone: NgZone,
    private _router: Router,
    public _activatedRoute: ActivatedRoute,
    private _location: Location,
    private _modalService: NgbModal,
    private _fb: FormBuilder,
    private _cdr: ChangeDetectorRef,
    private gtmService: GoogleTagManagerService,
    private _tourService: TourService
  ) {
    this.appliedFiltersCount = 0;
    this.observeUrl();

    this._mainService.favorite$.subscribe(id => {
      const item = this.layersDetails.find(itm => itm.ad_id === id);
      if (item) {
        item.favorite = !item.favorite;
        item.marker.setIcon(this.markerIcon(item.favorite ? 'stared' : 'default'));
        // this.markerAttachPopover(item);
      }
    });

    this._mainService.visitedJobs$.subscribe(ids => {
      const items = this.layersDetails.filter(itm => ids.indexOf(itm.ad_id) != -1);
      for (let item of items) {
        item.visited_job = true;
        item.marker.setIcon(this.markerIcon('visited'));
        // this.markerAttachPopover(item);
      }
    });
    this.selectedLocationProperties = JSON.parse(localStorage.getItem('selectedLocationProperties'));

    this.isSidebarOpen = !(!!(window.innerWidth <= 600));

    this.cmpId = this._activatedRoute.snapshot.queryParams?.cmpId ? this._activatedRoute.snapshot.queryParams?.cmpId : "default";
  }

  ngAfterViewInit(): void {
    if (TAG_MANAGER_CONFIGS[this.cmpId] || this.cmpId?.includes('lgnt') || this.cmpId == "default") {
      if (this._mainService.hasNoConsent(this.cmpId)) {
        console.log('Open GDP modal');
        this._mainService.openModal();
      } else if (this._mainService.consentEnabled(this.cmpId)) {
        if (this.cmpId?.includes('lgnt') || this.cmpId == "default") {
          this._mainService.setFacebookTrackingPixel();
        } else {
          this.gtmService.addGtmToDom();
          console.log('Enable GTM to doom');
        }
      } else {
        console.log('Consent disabled');
      }
    }
    if (!localStorage.getItem("dontShowIntroductionModal")) {
      this.initializeSearchForm();
      if (sessionStorage.getItem("defaultSearchPropertiesSetInThisSession") && this._activatedRoute.snapshot.queryParams?.zip_code == this.defaultSearchProperties.zip_code) {
        this.locationModel = "";
      }
      this._modalService.open(this.introductionModal, { backdrop: 'static', centered: true, keyboard: false });
    }
  }

  openURLInNewTab(url: string): void {
    window.open(url, '_blank');
  }

  searchLocation = (text$: Observable<string>) => text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap(term =>
      this._mainService.locations(term).pipe(
        catchError(() => of([])))),
  );

  formatter = (x): string => x.display_label;

  private initializeSearchForm(): void {
    this.searchForm = this._fb.group({
      display_label: [null, Validators.required],
      zip_code: [null, Validators.required],
      distance: [this._activatedRoute.snapshot.queryParams['distance'] != this.defaultSearchProperties.distance ? 
                  this._activatedRoute.snapshot.queryParams['distance'] :
                  this.searchFormDistance,
                Validators.required],
      favorite: [false],
    });

    this.localSearchCtrl.setValue(this.searchFilter.searchKey);

    this.searchForm.patchValue({
      ...this._activatedRoute.snapshot.queryParams,
    });

    if (this._activatedRoute.snapshot.queryParams?.display_label) {
      this.locationModel = {
        display_label: this._activatedRoute.snapshot.queryParams?.display_label,
        zip_code: this._activatedRoute.snapshot.queryParams?.zip_code,
        city: this._activatedRoute.snapshot.queryParams?.city,
      }
    } else {
      this.locationModel = "";
      if (this._activatedRoute.snapshot.queryParams?.zip_code) {
        if (this._activatedRoute.snapshot.queryParams?.city) {
          this.locationModel = {
            display_label: this._activatedRoute.snapshot.queryParams?.zip_code + " " + this._activatedRoute.snapshot.queryParams?.city,
            zip_code: this._activatedRoute.snapshot.queryParams?.zip_code,
            city: this._activatedRoute.snapshot.queryParams?.city,
          }
        } else {
          this.locationModel = {
            display_label: this._activatedRoute.snapshot.queryParams?.zip_code,
            zip_code: this._activatedRoute.snapshot.queryParams?.zip_code,
            city: this._activatedRoute.snapshot.queryParams?.city,
          }
        }
      } else if (this._activatedRoute.snapshot.queryParams?.city) {
        this.locationModel = {
          display_label: this._activatedRoute.snapshot.queryParams?.city,
          zip_code: this._activatedRoute.snapshot.queryParams?.zip_code,
          city: this._activatedRoute.snapshot.queryParams?.city,
        }
      }
    }
  }

  onSelect(event) {
    this.locationModel = {
      display_label: event?.item?.display_label,
      zip_code: event?.item?.zip_code,
    };
    this.searchForm.patchValue(this.locationModel);
    this.navigate({st: 'search', ...this.locationModel});
  }

  openTourModal() {
    this._modalService.open(this.tourModal, { backdrop: 'static', centered: true, keyboard: false });
  }

  initializeTour() {
    this.currentTourStepId = "0";
    const tourSteps: IStepOption[] = [
      {
        stepId: "0",
        anchorId: "jobmap",
        title: "Pins auf der Karte (1/4)",
        content: "Wenn du auf einen Pin klickst, erhältst du mehr Informationen über diesen Job.",
        placement: "left",
        prevBtnTitle: "Zurück",
        nextBtnTitle: "Nächster Schritt",
        endBtnTitle: "Tour beenden"
      },{
        stepId: "1",
        anchorId: "map-actions-filter",
        title: "Filter (2/4)",
        content: "Schränke deine Suche ein, sodass du nur die Jobs siehst, die dich auch wirklich interessieren.<br><span class='text-muted'>Probier's doch gleich Mal aus!</span>",
        placement: "left",
        prevBtnTitle: "Zurück",
        nextBtnTitle: "Nächster Schritt",
        endBtnTitle: "Tour beenden"
      }, {
        stepId: "2",
        anchorId: "map-actions-favorites",
        title: "Favoriten (3/4)",
        content: "Mit Klick auf den Favoriten-Button werden dir nur die Jobs angezeigt, die du mit einem Stern markiert hast.<br><span class='text-muted'>Du findest den Stern-Button auf jeden der aufgelisteten Jobs.</span>",
        placement: "left",
        prevBtnTitle: "Zurück",
        nextBtnTitle: "Nächster Schritt",
        endBtnTitle: "Tour beenden"
      }, {
        stepId: "3",
        anchorId: "job-list-btn",
        title: "Jobliste (4/4)",
        content: "Hier findest du alle Jobs der Karte im Listenformat.<br><span class='text-muted'>Alle gesetzten Filter werden auch auf diese Liste angewandt.</span>",
        placement: "top",
        prevBtnTitle: "Zurück",
        nextBtnTitle: "Nächster Schritt",
        endBtnTitle: "Tour beenden"
      }
    ];
    this._tourService.initialize(tourSteps);
  }

  searchFromIntroductionModal() {
    if (this.dontShowIntroductionModalNextTime) {
      localStorage.setItem("dontShowIntroductionModal", "true");
    }
    if (!this.mainCompanyNameExistsInPathParams) {
      if (typeof this.locationModel != "string") {
        this.navigate({
          st: 'search',
          display_label: this.locationModel.display_label,
          zip_code: this.locationModel.zip_code,
          latitude: this.locationModel.latitude,
          longitude: this.locationModel.longitude,
          distance: this._activatedRoute.snapshot.queryParams['distance'] != this.defaultSearchProperties.distance ? 
                                                                        this._activatedRoute.snapshot.queryParams['distance'] :
                                                                        this.searchFormDistance});
      } else if (this.locationModel.length > 1) {
        const zipCode = this.locationModel.match(/^\d{5}$/);
        if (zipCode) {
          this._router.navigate([], {
            queryParams: { 
              zip_code: zipCode[0],
              distance: this._activatedRoute.snapshot.queryParams['distance'] != this.defaultSearchProperties.distance ? 
                        this._activatedRoute.snapshot.queryParams['distance'] :
                        this.searchFormDistance
            }
          });
        } else if (this.locationModel.length > 1) {
          this._router.navigate([], {
            queryParams: { 
              city: this.locationModel,
              distance: this._activatedRoute.snapshot.queryParams['distance'] != this.defaultSearchProperties.distance ? 
                        this._activatedRoute.snapshot.queryParams['distance'] :
                        this.searchFormDistance
            }
          });
        }
      }
    }
    this._modalService.dismissAll();
  }

  closeIntroductionModal() {
    if (this.dontShowIntroductionModalNextTime) {
      localStorage.setItem("dontShowIntroductionModal", "true");
    }
    this._modalService.dismissAll();
  }

  handleTourButtonClick(startTour: boolean) {
    if (startTour) {
      this.startTour();
    } else {
      this._modalService.dismissAll();
    }
  }

  openSideBar() {
    this.isSidebarOpen = true;
    if (this.currentTourStepId == "3") {
      this._tourService.end();
      this.currentTourStepId = null;
    }
  }

  startTour() {
    this.initializeTour();
    this._tourService.start();
    this._tourService.stepHide$.subscribe(() => {
      if (this._tourService.currentStep.stepId == "0") {
        this._modalService.dismissAll();
      }
    });
    this._tourService.stepShow$.subscribe(() => {
      if (this._tourService?.currentStep?.stepId) {
        if (this._tourService.currentStep.stepId == "3" && screen.width > 575) {
          this._tourService.end();
          this.searchResultSidebarComponent.handleTourEvent();
        } else {
          this.currentTourStepId = this._tourService.currentStep.stepId;
        }
      } else {
        this.currentTourStepId = null;
      }
    });
    this._tourService.end$.subscribe(() => this.currentTourStepId = null);
  }

  observeUrl(): void {
    this._router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.appliedFiltersCount = 0;
        let queryParams = this._activatedRoute.snapshot.queryParams;
        const zipCode = queryParams['zip_code'];
        const city = queryParams['city'];
        const displayLabel = queryParams['display_label'];
        const distance = queryParams['distance'];
        const isValid = zipCode && displayLabel && distance;
        const longitude = queryParams['longitude'];
        const latitude = queryParams['latitude'];

        if (!isValid && this.selectedLocationProperties) {
          localStorage.removeItem('selectedLocationProperties');
          this.selectedLocationProperties = null;
        } else if (longitude && latitude) {
          this.selectedLocationProperties = {
            latitude: latitude,
            longitude: longitude,
          };
          localStorage.setItem('selectedLocationProperties', JSON.stringify(this.selectedLocationProperties));
        }

        if (_.isEmpty(queryParams) || ((queryParams.cmpId || location.href.includes('fbclid')) && !zipCode && !city)) {
          this.navigate({
            display_label: this.defaultSearchProperties.city,
            zip_code: this.defaultSearchProperties.zip_code,
            distance: this.defaultSearchProperties.distance,
            crc: Math.random()
          }, true);
          this.selectedLocationProperties = {
            latitude: this.defaultSearchProperties.latitude,
            longitude: this.defaultSearchProperties.longitude
          };
          sessionStorage.setItem("defaultSearchPropertiesSetInThisSession", "true");
          return;
        }


        if (queryParams['st'] == 'search' || queryParams['st'] == 'map') {
          this.searchType = queryParams['st'];
        }
        this.displayFavoriteJobs = queryParams['favorite'] == 'true';

        this.drawInitialCircle();

        if (((queryParams['zip_code'] && queryParams['zip_code'].length == 5) || queryParams['city']) && !queryParams['display_label']) {
          this._mainService.locations(queryParams['zip_code'] || queryParams['city']).subscribe(data => {
            if (data.length == 0) {
              return;
            }
            this.locationModel = {
              display_label: data[0] ? data[0].display_name : queryParams['zip_code'],
              zip_code: data[0] ? data[0].zip_code : queryParams['zip_code'],
              city: data[0] ? data[0].city : queryParams['city']
            }
            this.selectedLocationProperties = {
              latitude: data[0] ? data[0].latitude : null,
              longitude: data[0] ? data[0].longitude : null,
            };
            this.drawInitialCircle();
            this.navigate({
              display_label: data[0] ? data[0].display_name : queryParams['zip_code'],
              zip_code: data[0] ? data[0].zip_code : queryParams['zip_code'],
              distance: queryParams['distance'] || this.searchFormDistance,
              cmpId: queryParams['cmpId'] || null,
              crc: Math.random(),
            }, true);
          });
          return;
        }

        if (!this.stopUrlPropagation) {
          const mainCompanyName = this._activatedRoute.snapshot.params.main_company || null;
          const companyName = this._activatedRoute.snapshot.params.company || null;
          this.mainCompanyNameExistsInPathParams = mainCompanyName != null
          this.onMapMoved(false);
          this.closeCluster();
          if (this.displayFavoriteJobs) {
            this._mainService.getFavorites().subscribe(data => {
              this.onSuccessRequest(data, true);
            });
          } else if (isValid && this.searchType == 'search') {
            if(mainCompanyName){
              queryParams={
                ...queryParams,
                main_company_name: mainCompanyName
              }
            }
            if (companyName) {
              queryParams = {
                ...queryParams,
                company_name: companyName,
              };
            }
            if ("job_category" in queryParams) {
              const { job_category, ...updatedParams } = queryParams;
              queryParams = updatedParams;
            }
            this._mainService.search(queryParams).subscribe(data => {
              this.onSuccessRequest(data);
            });
          } else if (this.searchType == 'map') {
            const payload = {
              upper_latitude: queryParams['nwlat'],
              upper_longitude: queryParams['selng'],
              lower_latitude: queryParams['selat'],
              lower_longitude: queryParams['nwlng']
            };

            if (mainCompanyName) {
              payload['main_company_name'] = mainCompanyName;
            }
            if (companyName) {
              payload['company_name'] = companyName;
            }
            this._mainService.geoSearch(payload).subscribe(data => this.onSuccessRequest(data));
          }
        }
        this.stopUrlPropagation = false;
        this.invalidateMapSize();
      }
    });
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth === this.width) {
      return;
    }
    this.isSidebarOpen = !(!!(window.innerWidth <= 600));
  }


  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (event.target.classList.contains('popup-button-star')) {
      const id = event.target.getAttribute('data-id');
      const item = this.layersDetails.find(item => item.ad_id === id);
      if (item) {
        this._mainService.toggleFavorite(id);
      }
    }

    if (event.target.classList.contains('popup-button-details')) {
      const id = event.target.getAttribute('data-id');
      const item = this.layersDetails.find(item => item.ad_id === id);
      if (item) {
        this._mainService.trackEvent('more_details', 'moreDetailsClicked', 'ad_id', id);
      }
    }
  }

  setConsents(mtmCheck, ytCheck) {
    this._mainService.setConsentCookie('consent_saved');
    //
    // window['_paq'].push(['requireCookieConsent']);
    //
    // if (mtmCheck && environment.mtm_enable) {
    //   window['_paq'].push(['rememberConsentGiven', 8760]);
    //   this._mainService.trackEvent('init', 'new_visitor');
    // } else {
    //   window['_paq'].push(['forgetConsentGiven']);
    // }
    //
    // if (ytCheck) {
    //   this._mainService.setConsentCookie('yt_consent');
    //   this._mainService.removeConsentCookie('yt_consent_removed');
    // } else {
    //   this._mainService.setConsentCookie('yt_consent_removed');
    //   this._mainService.removeConsentCookie('yt_consent');
    // }
  }

  private onSuccessRequest(data, isFavorite = false): void {

    let jobCategory = "";
    if (this._activatedRoute.snapshot.queryParams?.j_category) {
      jobCategory += this._activatedRoute.snapshot.queryParams?.j_category;
    } 
    if (this._activatedRoute.snapshot.queryParams?.job_category) {
      if (this._activatedRoute.snapshot.queryParams?.j_category) {
        jobCategory += ";"
      }
      jobCategory += this._activatedRoute.snapshot.queryParams?.job_category;
    }
    this.layersDetails = this.filteredLayerDetails = data;
    if (jobCategory) {
      let filterData = false;
      JOB_CATEGORIES.forEach(category => {
        if (jobCategory.split(";").some(el => el === category.name)) {
          this.appliedFiltersCount += 1;
          filterData = true;
        }
      });
      if (filterData) {
        this.filteredLayerDetails = data.filter(job => jobCategory.split(";").some(el => el === job.job_category));
      }
    }

    if(this.searchFilter?.searchKey)
    {
      const internalData = jobCategory !="" ? this.filteredLayerDetails : data;
      this.filteredLayerDetails = internalData.filter(layer => {
        const keys = this.searchFilter?.searchKey.split(' ');
        for (const key of keys) {
          if (!layer.title.toLowerCase().includes(key?.toLowerCase()) &&
            !layer.company_name.toLowerCase().includes(key?.toLowerCase()) &&
            !layer.main_company_name.toLowerCase().includes(key?.toLowerCase()) &&
            !layer.plz.toLowerCase().includes(key?.toLowerCase()) &&
            !layer.ort.toLowerCase().includes(key?.toLowerCase())) {
            return false;
          }
        }
        return true;
      }
    );
    }


    // SORT filteredLayerDetails
    this.sortFilteredLayerDetails();

    if (this.messageModal) {
      this.closeMessageModal();
    }
    if (this.layersDetails.length === 0) {
      this.openMessageModal(isFavorite ? 'favorite' : 'job');
    }

    this.renderMarkerLayers();

    this.firstTimeSearch = false;

    if (this.searchType == 'search' || this.searchBoundPoints.length === 0) {
      this.saveSearchBoundPoints = true;
    }
  }

  sortFilteredLayerDetails() {
    let uniqueCompanyNames = [];
    let topList = [];
    let bottomList = [];
    this.filteredLayerDetails.sort((a,b) => a.distance - b.distance).forEach(element => {
      if (!uniqueCompanyNames.includes(element.company_name)) {
        uniqueCompanyNames.push(element.company_name);
        topList.push(element);
      } else {
        bottomList.push(element);
      }
    });
    this.filteredLayerDetails = _.shuffle(topList).concat(bottomList);
  }

  onSearchJobs(event: any): void {
    const data = this.appliedFiltersCount > 0 ? this.filteredJobsFromDrawer : this.layersDetails;
    this.filteredLayerDetails = data?.filter(layer => {
        for (const key of event) {
          if (!layer.title.toLowerCase().includes(key?.toLowerCase()) &&
            !layer.company_name.toLowerCase().includes(key?.toLowerCase()) &&
            !layer.main_company_name.toLowerCase().includes(key?.toLowerCase()) &&
            !layer.plz.toLowerCase().includes(key?.toLowerCase()) &&
            !layer.ort.toLowerCase().includes(key?.toLowerCase())) {
            return false;
          }
        }
        return true;
      }
    );

    this.closeCluster();
  }

  filterFavoriteJobs(filterFavorites: boolean) {
    this.displayFavoriteJobs = filterFavorites;
    this.navigate({favorite: this.displayFavoriteJobs});
  }

  sortJobs({key, sortDirection}: { key: string, sortDirection: string | 'asc' | 'desc' }) {
    if (!key) {
      this.filteredLayerDetails = this.layersDetails;
      return;
    }

    this.filteredLayerDetails = _.orderBy(this.filteredLayerDetails, [key], [sortDirection]);
  }

  openFilterDrawer(): void {
    this.isOpenedFilterDrawer = true;
    this.handleDrawer(true);
  }

  closeFilterDrawer(filteredJobs): void {
    if (this.currentTourStepId == "1") {
      this._tourService.next();
    }
    this.handleDrawer(false);
    this.closeCluster();
    this.filteredJobsFromDrawer = this.filteredLayerDetails = filteredJobs;
    if (this.filteredLayerDetails?.length === 0) {
      if (this.messageModal) {
        this.closeMessageModal();
      }
      this.openMessageModal('job');
    } else if (this.messageModal) {
      this.closeMessageModal();
    }
    this.renderMarkerLayers();
  }

  private handleDrawer(isOpen: boolean) {
    if (isOpen) {
      setTimeout(() => {
        document.getElementById('mySidenav').style.right = '0';
      }, 50);
      setTimeout(() => {
        document.getElementById('drawerWrapper').style.background = '#000000a1';
      }, 300);
    } else {
      if (this.isOpenedFilterDrawer) {
        document.getElementById('mySidenav').style.right = '-340px';
        document.getElementById('drawerWrapper').style.background = 'none';
      }
      setTimeout(() => {
        this.isOpenedFilterDrawer = false;
      }, 300);
    }
  }

  private navigate(data = {}, includeQueryParams: boolean = true) {
    let params = {};
    if (includeQueryParams) {
      params = {...this._activatedRoute.snapshot.queryParams};
    }

    this._router.navigate([], {
      queryParams: {...params, ...data},
    });
  }

  // Map methods
  mapReady(map: Map) {
    this.map = map;
    this.previousMapZoom = map.getZoom();
    this.enableScrollZoom(this.IS_SCROLL_ZOOM_ENABLED, map);

    this.map.addControl(new Zoom({
      position: 'topright'
    }));

    this.map.on('zoomend', () => {
      if (this.previousMapZoom < this.map.getZoom()) {
        this.stopMovePropagation = true;
      } else {
        this.closeCluster();
      }
      this.previousMapZoom = map.getZoom();
    });

    this.map.on('moveend', (event) => {
      this._zone.run(() => {
        if (this.saveSearchBoundPoints) {
          this.setSearchBounds();
          this.saveSearchBoundPoints = false;
        }
        this.onMapMoved(true);
      });
    });

  }

  drawInitialCircle() {
    if (this.selectedLocationProperties && this.selectedLocationProperties.latitude && this.selectedLocationProperties.longitude) {
      const radius = this._activatedRoute.snapshot.queryParams.distance || this.searchFormDistance;
      this.initialCircle = [circle([this.selectedLocationProperties.latitude, this.selectedLocationProperties.longitude], {radius: radius * 1000 * 1.25})];
    }
  }

  invalidateMapSize() {
    setTimeout(() => {
      this.map.invalidateSize();
    }, 150);
  }

  renderMarkerLayers() {
    this.layers = [];
    const latLongArray = [];
    const markersGroup = new FeatureGroup();

    this.layers = this.filteredLayerDetails.filter(item => item.job_art != 'Werkstudent').map(item => {
      latLongArray.push(new LatLng(item.latitude, item.longitude));
      const stared = this._mainService.favorites().indexOf(item.ad_id) != -1;
      const options: MyMarkerOptions = {
        icon: this.markerIcon(stared ? 'stared' : 'default'),
        data: item.ad_id
      };

      item.marker = marker([item.latitude, item.longitude], options)
        .on('click', (event: any) => {
          this._zone.run(() => {
            this.closeCluster();
            this.scrollListToMarker(item);
            this.map.panTo(event.latlng);
            this.clusterJobs = [item];
            this.splitClusterJobs = [[item]];
          });

        });

      markersGroup.addLayer(item.marker);
      return item.marker;
    });

    if (this.layers.length != 0 && this.centerMap) {
      this.fitBounds = this.searchType == 'map' && !this.displayFavoriteJobs ? this.getUrlBounds() : markersGroup.getBounds();
    }
    this.centerMap = true;
    // this.sortJobs();
    this.invalidateMapSize();
  }

  markerClusterReady(markerCluster) {
    // Do stuff with group
    markerCluster.on('clusterclick', (event) => {
      this._zone.run(() => {
        this.latestCluster = event;
        this.renderCluster();
      });
    }).on('animationend', () => {
      this._zone.run(() => {
        this.renderCluster();
      });
    }).on('spiderfied', (event) => {
      this.map.options.maxZoom = this.map.getZoom();
      this.stopMovePropagation = true;
    }).on('unspiderfied', (event) => {
      this.map.options.maxZoom = 25;
      this.stopMovePropagation = false;
    });
  }

  renderCluster() {
    if (this.map.getZoom() <= 15 || this.latestCluster == null) {
      console.log('Zom level not ' + this.map.getZoom());
      return;
    }

    const bounds = this.latestCluster.layer.getBounds();
    const lat = (bounds.getSouthWest().lat + bounds.getNorthEast().lat) / 2;
    const lng = (bounds.getSouthWest().lng + bounds.getNorthEast().lng) / 2;
    this.map.panTo(new LatLng(lat, lng));
    this.stopMovePropagation = true;
    this.clusterJobs = [];
    const markers = this.latestCluster.layer.getAllChildMarkers();
    for (const marker in markers) {
      if (markers.hasOwnProperty(marker)) {
        const adId = markers[marker].options.data;
        const obj = this.layersDetails.find(item => item.ad_id == adId);
        obj['cluster_number'] = (+marker + 1) + '/' + markers.length;
        this.clusterJobs.push(obj);
      }
    }


    this.splitClusterJobs = [];
    for (let i = 0; i < this.clusterJobs.length; i++) {
      if (i % 3 === 0) {
        this.splitClusterJobs.push((this.clusterJobs.slice(i, i + 3)));
      }
    }
  }

  closeCluster() {
    this.clusterJobs = [];
    this.latestCluster = null;
  }

  markerIcon(type: 'default' | 'stared' | 'visited') {
    const iconName: string = type === 'stared' ? 'favorite-pin' : type === 'visited' ? 'visited-pin' : 'pin';
    return icon({
      iconSize: [32, 41],
      iconAnchor: [13, 41],
      iconUrl: `./assets/img/${iconName}.png`,
      shadowUrl: './assets/marker-shadow.png',
    });
  }

  markerAttachPopover(item) {
    item.marker.bindPopup((layer) => {
      layer.on('popupclose', () => {
        this._zone.run(() => {
          this.extendItem = false;
        });
      });

      return `<div class="marker-popup">
                    <h4 class="m-0">${item.company_name}</h4>
                    <p class="mt-0">${item.plz} ${item.ort} - ${item.display_date}</p>
                    <p>${item.title}</p>
                    <div class="clearfix">
                        <a href="${item.url}" target="_blank" class="btn btn-primary text-white btn-custom float-left popup-button-details text-uppercase" data-id="${item.ad_id}">
                            ${item?.url?.includes('justappl.ai') ? 'Schneller zum Job' : 'ZUM JOB'}
                            <img src="./assets/img/exit-icon.png" alt="exit" class="external-link-image"/>
                        </a>
                        <img class="star-image popup-button-star" alt="star-img" data-id="${item.ad_id}" src="${item.favorite ? './assets/img/stared.png' : './assets/img/star.png'}">
                    </div>
                  </div>`;
    });
  }

  private getUrlBounds() {
    const queryParams = this._activatedRoute.snapshot.queryParams;
    return new LatLngBounds([[queryParams['nwlat'], queryParams['nwlng']], [queryParams['selat'], queryParams['selng']]]);
  }

  setSearchBounds() {
    const searchBounds = this.map.getBounds();
    this.searchBoundPoints = [];
    this.searchBoundPoints.push(latLng(searchBounds.getNorthWest().lat, searchBounds.getNorthWest().lng));
    this.searchBoundPoints.push(latLng(searchBounds.getSouthEast().lat, searchBounds.getSouthEast().lng));

    this.searchBoundPoints.push(latLng(searchBounds.getSouthEast().lat, searchBounds.getNorthWest().lng));
    this.searchBoundPoints.push(latLng(searchBounds.getNorthWest().lat, searchBounds.getSouthEast().lng));
  }

  searchArea(): void {
    const mapBounds = this.map.getBounds();
    const data = {
      nwlat: mapBounds.getNorthWest().lat,
      nwlng: mapBounds.getNorthWest().lng,
      selat: mapBounds.getSouthEast().lat,
      selng: mapBounds.getSouthEast().lng,
      st: 'map',
      cmpId: this._activatedRoute.snapshot.queryParams['cmpId'] || null,
      j_category: this._activatedRoute.snapshot.queryParams['j_category'] || null,
      job_category: this._activatedRoute.snapshot.queryParams['job_category'] || null
    };

    this.centerMap = false;
    this.navigate(data, false);
  }

  onMapMoved(flag) {
    this.mapMoved = flag;
    this._cdr.detectChanges();
  }

  private enableScrollZoom(isZoomEnabled, map) {
    isZoomEnabled && this.map.once('focus', function() {
      map.scrollWheelZoom.enable();
    });
  }

  private closeMessageModal() {
    if (this.messageModal) {
      this.messageModal.dismiss();
    }
  }

  private openMessageModal(type) {
    this.messageModal = this._mainService.openMessageModal(type);
    this.messageModal.closed.subscribe(() => {
      if (this.currentTourStepId == "2") {
        this._tourService.goto("3");
      }
    });
  }

  //can be needed
  scrollListToMarker(item) {
    const itemEl = document.getElementById(item.ad_id);
    const list = document.getElementsByClassName('list').item(0);
    if (list) {
      const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
      if (isIEOrEdge) {
        list.scrollTop = itemEl.offsetTop - 50;
      } else {
        list.scrollTo({top: itemEl.offsetTop - 50, behavior: 'smooth'});
      }
    }
  }

  slideChange(event) {
    this.currentSlideIndex = event.current;
  }

  onSupportedByBpaClick() {
    window.open("https://www.bpa.de/Aktuelles-Positionen.265.0.html?&no_cache=1&tx_ttnews%5btt_news%5d=10834&cHash=092a89a7f051faf3a9b8440b6cf90e44", "_blank");
  }
}
