<div class="sidebar-wrapper" id="drawerWrapper">
  <div id="mySidenav" class="sidenav">
    <form [formGroup]="searchForm" style="height: 100%">
      <div class="sidebar-container">
        <div class="d-flex align-items-center justify-content-between">
          <h4 class="p-0 m-0">{{'SUCHE_ANPASSEN' | translate}}</h4>
          <span class="close-btn" (click)="closeDrawer()">&times;</span>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <span class="search-result">{{allJobs?.length || 0}} {{'JOBS_GEFUNDEN' | translate}}</span>
          <span class="clear-filters primary" (click)="resetSearchForm()">{{'ALLE_FOLDER_LOSCHEN' | translate}}</span>
        </div>

        <div class="filter-container">
          <div class="mt-4 ml-1">
            <h6>{{'ORT_ODAR_PLZ' | translate}}</h6>
            <div class="form-group mb-1 main-filters position-relative autocomplete-form-control digital-input">
              <ng-template #rt let-r="result" let-t="term">
                <ngb-highlight [result]="r.display_label" [term]="t"></ngb-highlight>
              </ng-template>
              <i class="bi bi-search"></i>
              <input type="text"
                     class="form-control"
                     inputmode="search"
                     [resultTemplate]="rt"
                     [(ngModel)]="locationModel"
                     [ngbTypeahead]="searchLocation"
                     [ngModelOptions]="{standalone: true}"
                     [inputFormatter]="formatter"
                     [disabled]="searchForm.disabled"
                     (selectItem)="onSelect($event)"
                     placeholder="{{'ORT_PLZ' | translate}}"/>
              <span *ngIf="!!locationModel" (click)="locationModel = ''" class="ng-clear-wrapper"
                    title="Clear all"><span aria-hidden="true" class="ng-clear close-icon-search">×</span></span>
            </div>

          </div>

          <div class="mt-4">
            <h6>{{'UMKREIS' | translate}}</h6>
            <ngx-slider [options]="sliderOptions" formControlName="distance"></ngx-slider>
          </div>

          <div class="mt-3 ml-1">
            <div class="digital-input">
              <h6>{{'JOBTITEL_UNTERNEHMEN' | translate}}</h6>
              <i class="bi bi-search"></i>
              <input placeholder="{{'JOBTITEL_UNTERNEHMEN' | translate}}" [formControl]="localSearchCtrl"
                     class="form-control"/>
            </div>

            <div class="mt-4">
              <h6>{{'VEROFFENTLICHUNG' | translate}} </h6>
              <div class="badge-container">
                <div
                  class="filter-badge py-1 px-2"
                  (click)="timeFilter('week')"
                  [class.badge-active]="filteredByTime?.filteredByTime == 'week'">
                  Neuer als 7 Tage
                  <span class="badge-number">{{filterCounts['time_filter_week']}}</span>
                </div>
                <div
                  class="filter-badge py-1 px-2"
                  (click)="timeFilter('day')"
                  [class.badge-active]="filteredByTime?.filteredByTime == 'day'">
                  Letzte 24 Stunden
                  <span class="badge-number">{{filterCounts['time_filter_day']}}</span>
                </div>
              </div>
            </div>

            <div class="mt-4">
              <h6>{{'BERUFSKATEGORIE' | translate}} <span
                class="category-count">{{filterCounts['job_category'] + ' Jobs'}}</span></h6>
              <div class="badge-container">
                <div
                  class="filter-badge py-1 px-2"
                  *ngFor="let item of jobCategories"
                  (click)="selectJobCategory(item)"
                  [class.badge-active]="item.is_selected">
                  {{item.name | translate}}
                  <span class="badge-number">{{filterCounts['job_category_' + item.name]}}</span>
                </div>
              </div>
            </div>

            <div class="mt-4">
              <h6>{{'UNTERNEHMENSKATEGORIE' | translate}} <span
                class="category-count">{{filterCounts['company_category'] + ' Jobs'}}</span></h6>
              <div class="badge-container">
                <div
                  *ngFor="let item of companyCategories"
                  (click)="selectCompanyCategory(item)"
                  class="filter-badge py-1 px-2"
                  [class.badge-active]="item.is_selected">
                  {{item.name | translate}}
                  <span class="badge-number">{{filterCounts['company_category_' + item.name]}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer">
          <button class="btn btn-sm btn-light mr-2" (click)="closeDrawer()">{{'ABBRECHEN' | translate}}</button>
          <button class="btn btn-sm btn-primary" (click)="search()">{{'ANPASSEN' | translate}}
            ({{filteredJobs.length}})
          </button>
        </div>
      </div>
    </form>
  </div>

</div>
