<div class="d-flex justify-content-between verification">
    <div class="component-container text-center">
        <div class="w-100">
            <img id="dubistpflege-logo" src="../../../../../assets/img/bpa-campaign/dubistpflege-logo.png" alt="">
        </div>
        <div class="d-flex justify-content-center">
            <div *ngIf="showSummaryPage" id="successfully-registered-card">
                <div *ngIf="mainFacilityData?.order_verified != 1" class="heading mb-3">Sie haben Ihre Registrierung erfolgreich bestätigt!</div>
                <div *ngIf="mainFacilityData?.order_verified == 1" class="heading mb-3">Ihre Registrierung ist bestätigt!</div>
                <div style="width: 100%;display: flex;flex-direction: column;align-items: center;margin-bottom: 20px;padding: 20px;border-radius: 12px;background-color: #f8f8f8;border: 1px solid rgba(0,0,0,0.05)">
                    <h5 style="margin-bottom: 15px;color: #8B94AF">Zusammenfassung Ihrer Registrierung</h5>
                    <div style="display: flex;flex-direction: column;row-gap: 4px;">
                        <div class="d-flex">
                            <div class="summary-label">Unternehmensname:</div>
                            <div class="summary-value">{{mainFacilityData?.company_name}}</div>
                        </div>
                        <div class="d-flex">
                            <div class="summary-label">Adresse:</div>
                            <div class="summary-value">{{mainFacilityData?.zip}} {{mainFacilityData?.city}}, {{mainFacilityData?.street}}</div>
                        </div>
                        <div class="d-flex">
                            <div class="summary-label">Kontaktperson:</div>
                            <div class="summary-value">{{mainFacilityData?.contact_name}}, {{mainFacilityData?.contact_email}}{{mainFacilityData?.contact_phone ? ", " + mainFacilityData?.contact_phone : ""}}</div>
                        </div>
                        <div class="d-flex">
                            <div  class="summary-label">Teilnehmende Einrichtungen:</div>
                            <div class="summary-value">{{mainFacilityData?.number_of_facilities}}</div>
                        </div>
                        <div class="d-flex">
                            <div class="summary-label">Laufzeit:</div>
                            <div class="summary-value">{{mainFacilityData?.valid_time}} {{mainFacilityData?.valid_time_unit}}</div>
                        </div>
                        <div class="d-flex">
                            <div class="summary-label">Gesamtkosten:</div>
                            <div class="summary-value">{{totalAmount}},- {{mainFacilityData?.price_currency}} zzgl. MwSt.</div>
                        </div>
                        <div class="mt-3" style="color: #8B94AF" *ngIf="mainFacilityData?.billing_details_later == 1">Wir werden Sie bezüglich Ihrer Rechnungsdaten kontaktieren.</div>
                    </div>
                </div>
                <div *ngIf="mainFacilityData?.configure_facilities_after_validation == 1">
                    <div *ngIf="mainFacilityData?.order_verified == 0 || (mainFacilityData?.order_verified == 1 && !(mainFacilityData?.facilities?.length > 0))" class="main-text">Damit Ihre Einrichtungen an der Recruiting Kampagne teilnehmen können, benötigen wir noch die Adress-Daten zu Ihren Einrichtungen.</div>
                    <div *ngIf="mainFacilityData?.order_verified == 0 || (mainFacilityData?.order_verified == 1 && !(mainFacilityData?.facilities?.length > 0))" class="later-configuration-text mt-1 text-muted">Wenn Sie auf "Später erfassen" klicken, senden wir Ihnen einen Link an <span>{{email}}</span>, über den Sie Ihre Adress-Daten zu einem späteren Zeitpunkt erfassen können.</div>
                    <div *ngIf="!(mainFacilityData?.facilities?.length > 0)" class="d-flex justify-content-center mt-4">
                        <div>
                            <div>
                                <button class="btn btn-outline-primary" (click)="configureNow()">ADRESS-DATEN ERFASSEN</button>
                            </div>
                            <div *ngIf="mainFacilityData?.order_verified == 0 || (mainFacilityData?.order_verified == 1 && !(mainFacilityData?.facilities?.length > 0))">
                                <button class="btn btn-link" style="text-decoration: underline;color: #8B94AF;" (click)="configureLater()">Später erfassen</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="mainFacilityData?.facilities?.length > 0 || mainFacilityData?.configure_facilities_after_validation == 0">
                    <div id="modal-text">
                        <div id="check-email-text">
                            <div>Die Stellenanzeigen werden automatisch von Ihrer Webseite übernommen. Sollte dies nicht möglich sein, wird unser Support-Team auf Sie zukommen.</div>
                            <div style="padding: 0 20px;">Sie werden informiert, sobald Ihre Stellen auf der JobMap live sind.</div>
                            <div class="text-muted mt-2">Weitere Informationen erhalten Sie im Onboarding-Webinar. Den Link dazu finden Sie in Ihrer Welcome-Mail, die Ihnen gerade geschickt worden ist.</div>
                            <div class="mt-3">Sie können dieses Fenster nun schließen.</div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center" style="margin-top: 14px;">
                        <button class="btn btn-outline-primary" (click)="forwardToLandingPage()">JETZT SCHLIESSEN</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="footer">
        <a href="legaldocs/impressum.html" target="_blank" class="footer-link">Impressum</a>
        <a href="legaldocs/datenschutz.html" target="_blank" class="footer-link">Datenschutz</a>
    </footer>
</div>