import {LabelType, Options} from "@angular-slider/ngx-slider";
import {latLng, tileLayer, divIcon, point} from "leaflet";

export const  sliderOptions: Options = {
  floor: 10,
  ceil: 450,
  step: 10,
  translate: (value: number, label: LabelType): string => {
    return value + "km";
  }
};

export const clusterOptions = {
  spiderfyOnMaxZoom: false,
  showCoverageOnHover: true,
  zoomToBoundsOnClick: true,
  iconCreateFunction: function (cluster) {
    const markers = cluster.getAllChildMarkers();
    const html = '<div class="circle">' + markers.length + '</div>';
    return divIcon({html: html, className: 'my-cluster-icon', iconSize: point(32, 32)});
  },
};

export const mapOptions = {
  layers: [
    tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?access_token=pk.eyJ1Ijoic2FicmlvIiwiYSI6ImNramlmM2Y4eTAycnEzMnM3dzVsZGVnamsifQ.RhZDzmM7S9xUksEe1531kA',
      {
        minZoom: 7,
        attribution: 'Map data &copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors.'
      })
  ],
  zoomControl: false,
  center: latLng(48.15024427286979, 11.580279452273365),
  scrollWheelZoom: false,
  tap: false
};
