import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BpaService } from '../../../services/bpa.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {v4 as uuidv4} from 'uuid';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {

  email: string;
  orderID: string;
  mainFacilityData: any;
  sessionID: string;
  showSummaryPage: boolean;

  priceCurrencies = {
    euro: "€",
    dollar: "$",
    CHF: "CHF"
  }

  totalAmount: number;

  project: string;

  constructor(private _activatedRoute: ActivatedRoute,
              private _router: Router,
              private _bpaService: BpaService,
              private _spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.showSummaryPage = false;
    this._spinner.show();
    if (sessionStorage.getItem("session_id")) {
      this.sessionID = sessionStorage.getItem("session_id");
    } else {
      this.sessionID = uuidv4();
      sessionStorage.setItem("session_id", this.sessionID);
    }
    this._activatedRoute.queryParams
      .subscribe(params => {
        this.email = params.email;
        this.orderID = params.order;

        this._bpaService.getMainFacilityData(this.email, this.orderID)
                        .subscribe(res => {
                          this.mainFacilityData = res;
                          this.totalAmount = this.mainFacilityData.number_of_facilities * this.mainFacilityData.price_amount;
                          if (this.mainFacilityData.project?.length > 0) {
                            this.project = this.mainFacilityData.project;
                          } else {
                            this.project = "general";
                          }
                          if (this.mainFacilityData.order_verified != 1) {
                            this._bpaService.verifyRegistration(this.email, this.orderID).subscribe(res => {
                              this._bpaService.bpaBusinessLog(this.orderID, this.sessionID, "verified-registration", this.project).subscribe();
                              this.sendWelcomeMailOrForwardToRegistration();
                              this._spinner.hide();
                            });
                          } else {
                            this.sendWelcomeMailOrForwardToRegistration();
                            this._spinner.hide();
                          }
                          
        });
      });
  }

  sendWelcomeMailOrForwardToRegistration() {
    this._bpaService.getProjectConfig(this.project).subscribe(projectConfig => {
      this.project = projectConfig.project;
      if (!this.mainFacilityData.company_name ||
          (projectConfig.price_packages.length > 1 && this.mainFacilityData.package_id == null) ||
          (this.mainFacilityData.billing_details_later == 0 && !this.mainFacilityData.bill_to_company_name)) {
        sessionStorage.setItem("continueRegistrationFormEmail", this.email);
        sessionStorage.setItem("continueRegistrationFormOrderID", this.orderID);
        if (this._activatedRoute.snapshot.url[0].path == "bpa-recruiting-kampagne") {
          this._router.navigateByUrl('/bpa/recruiting-kampagne/registrierung');
        } else if (this.project != "general") {
          this._router.navigateByUrl(this.project + '/recruiting-kampagne/registrierung');
        } else {
          this._router.navigateByUrl('/recruiting-kampagne/registrierung');
        }
      } else {
        // Registration data are complete and the welcome mail shall be send to the customer
        this.showSummaryPage = true;
        this._bpaService.sendWelcomeEmail(this.email, this.orderID).subscribe();
      }
    });
  }

  configureNow() {
    this._bpaService.bpaBusinessLog(this.orderID, this.sessionID, "configure-facilities-now-click", this.project).subscribe();
    this._router.navigateByUrl('/recruiting-kampagne/einrichtungen-konfigurieren?email=' + this.email + "&order=" + this.orderID);
  }

  configureLater() {
    this._spinner.show();
    this._bpaService.registerFacilitiesLater(this.email, this.orderID)
                    .subscribe(res => {
                      this._bpaService.bpaBusinessLog(this.orderID, this.sessionID, "verification-screen-on-configure-later-click", this.project).subscribe();
                      sessionStorage.setItem("forwardedUserToLandingPage", "true");
                      sessionStorage.setItem("forwardedUserOrderID", this.orderID);
                      this._spinner.hide();
                      if (this._activatedRoute.snapshot.url[0].path == "bpa-recruiting-kampagne") {
                        this._router.navigateByUrl('/bpa/recruiting-kampagne/registrierung');
                      } else if (this.project != "general") {
                        this._router.navigateByUrl(this.project + '/recruiting-kampagne/registrierung');
                      } else {
                        this._router.navigateByUrl('/recruiting-kampagne/registrierung');
                      }
                    });
  }

  forwardToLandingPage() {
    sessionStorage.setItem("forwardedUserToLandingPage", "true");
    sessionStorage.setItem("forwardedUserOrderID", this.orderID);
    if (this._activatedRoute.snapshot.url[0].path == "bpa-recruiting-kampagne") {
      this._router.navigateByUrl('/bpa/recruiting-kampagne/registrierung');
    } else if (this.project != "general") {
      this._router.navigateByUrl(this.project + '/recruiting-kampagne/registrierung');
    } else {
      this._router.navigateByUrl('/recruiting-kampagne/registrierung');
    }
  }

}
