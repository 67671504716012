import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
import {environment} from '../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MessagesComponent} from '../modals/messages/messages.component';
import {CookiesComponent} from '../modals/cookies/cookies.component';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  private _searching: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly searching$: Observable<boolean> = this._searching.asObservable();

  private _favorites: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  readonly favorites$: Observable<string[]> = this._favorites.asObservable();

  private _favorite: BehaviorSubject<string> = new BehaviorSubject<string>('');
  readonly favorite$: Observable<string> = this._favorite.asObservable();

  private _visitedJobs: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  readonly visitedJobs$: Observable<string[]> = this._visitedJobs.asObservable();


  constructor(private _http: HttpClient,
              private _modalService: NgbModal,
              private cookieService: CookieService,
              private _spinner: NgxSpinnerService
  ) {
    if (this.cookieService.check('favorites')) {
      const favorites = JSON.parse(this.cookieService.get('favorites'));
      this._favorites.next(favorites);
    }

    if (this.cookieService.check('visited_jobs')) {
      const visitedJobs = JSON.parse(this.cookieService.get('visited_jobs'));
      this._visitedJobs.next(visitedJobs);
    }
  }

  search(data: any) {
    this._spinner.show();
    this.toggle(this._searching);
    this.trackEvent('search', 'zip_code', data.zip_code);
    this.trackEvent('search', 'job_type', data.type);
    let urlParams = "";
    Object.keys(data).forEach((key, i) => {
      urlParams += key + "=" + encodeURIComponent(data[key]) + (i+1 == Object.keys(data).length ? "" : "&");
    });
    return this._http.get(environment.apiUrl + '/search?' + urlParams).pipe(
      map((items: any[]) => items.map(item => {
        return {...item, favorite: this.isFavorite(item.ad_id), is_visited: this.isVisitedJob(item.ad_id)};
      })),
      tap(data => {
        this._spinner.hide();
        this.toggle(this._searching);
      }),
      catchError(error => {
        this._spinner.hide();
        this.toggle(this._searching);
        return throwError(error);
      })
    );
  }

  geoSearch(payload: any) {
    this._spinner.show();
    this.toggle(this._searching);
    this.trackEvent('search', 'mouseMapMoved');

    return this._http.get(environment.apiUrl + '/geo-search', {params: payload}).pipe(
      map((items: any[]) => items.map(item => {
        return {...item, favorite: this.isFavorite(item.ad_id), is_visited: this.isVisitedJob(item.ad_id)};
      })),
      tap(data => {
          this._spinner.hide();
          this.toggle(this._searching);
        }
      ),
      catchError(error => {
        this._spinner.hide();

        this.toggle(this._searching);
        return throwError(error);
      })
    );
  }

  getFavorites() {
    if (this._favorites.getValue().length == 0) {
      return of([]);
    }

    this._spinner.show();
    this.toggle(this._searching);
    return this._http.get(environment.apiUrl + '/favorites', {params: {favoriteIds: this._favorites.getValue().join(',')}}).pipe(
      map((items: any[]) => items.map(item => {
        return {...item, favorite: true};
      })),
      tap(data => {
        this._spinner.hide();
        this.toggle(this._searching);
      }),
      catchError(error => {
        this._spinner.hide();
        this.toggle(this._searching);
        return throwError(error);
      }),
      finalize(() => {
        this._spinner.hide();
      })
    );
  }

  isFavorite(adId) {
    return this._favorites.getValue().indexOf(adId) !== -1;
  }

  favorites() {
    return this._favorites.getValue();
  }

  isVisitedJob = (adId) => this._visitedJobs.getValue().indexOf(adId) !== -1;


  getVisitedJobs(): string[] {
    return this._visitedJobs.getValue();
  }

  addVisitedJob(id) {
    if (this.getVisitedJobs().indexOf(id) != -1) {
      return;
    }

    this._visitedJobs.next([...this.getVisitedJobs(), id]);

    this.cookieService.set('visited_jobs', JSON.stringify(this.getVisitedJobs()), {
      expires: new Date(new Date().setMonth(new Date().getMonth() + 3))
    });
  }

  locations(word) {
    if (word === '') {
      return of([]);
    }
    return this._http.get(environment.apiUrl + '/zip-codes', {params: {word: word, country_id: '1'}}).pipe(
      map((items: any) => {
        return items.map(item => {
          return {...item, display_label: item.display_name};
        });
      })
    );
  }

  private toggle(subject: BehaviorSubject<boolean>) {
    subject.next(!subject.getValue());
  }

  toggleFavorite(id) {
    this._favorite.next(id);
    if (this.favorites().indexOf(id) != -1) {
      this._favorites.next(this.favorites().filter(favoriteId => favoriteId !== id));
    } else {
      this.trackEvent('favorite', 'favoriteAdded', 'ad_id', id);
      this._favorites.next([...this.favorites(), id]);
    }

    this.cookieService.set('favorites', JSON.stringify(this.favorites()), {
      expires: new Date(new Date().setMonth(new Date().getMonth() + 3))
    });
  }

  setConsentCookie(name) {
    this.cookieService.set(
      name,
      Math.random().toString(),
      new Date(new Date().setMonth(new Date().getMonth() + 12)),
      '/'
    );
  }

  setFacebookTrackingPixel() {
    (function(f: any, b, e, v, n, t, s) {
      if (f.fbq) {
        return;
      }
      n = f.fbq = function() {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) {
        f._fbq = n;
      }
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    (window as any).fbq.disablePushState = true; //not recommended, but can be done
    (window as any).fbq('init', '536363727931698');
    (window as any).fbq('track', 'PageView');
  }


  trackEvent(category: string, action: string, name?: string, value?: number) {
    if (this.cookieService.check('mtm_consent')) {
      // this.matomoTracker.trackEvent(category, action, name, value);
    }
  }


  openModal(cmpId = null) {
    const modal = this._modalService.open(CookiesComponent, {
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      centered: true,
      windowClass: 'cookie-consent-modal',
      backdropClass: 'cookie-consent-modal-backdrop',
    });

    if (cmpId && !this.hasNoConsent(cmpId)) {
      modal.componentInstance.einstellungun = 'einstellungun-modal';
    }
  }

  openMessageModal(type: string) {
    const modal = this._modalService.open(MessagesComponent, {
      size: 'md',
      centered: true,
      windowClass: type == 'favorite' ? 'no-favorites-modal' : 'modal-bottom no-jobs-modal',
      backdrop: false
    });
    modal.componentInstance.type = type;

    return modal;
  }


  hasNoConsent(name) {
    return !this.cookieService.check(name + '_consent') && !this.cookieService.check(name + '_consent_removed');
  }

  consentEnabled(name) {
    return this.cookieService.check(name + '_consent') && !this.cookieService.check(name + '_consent_removed');
  }

  // Favorites
  allowCookies(cmpId: any) {
    if (!cmpId) {
      return false;
    }
    this.cookieService.delete(cmpId + '_consent_removed');
    this.setConsentCookie(cmpId + '_consent');

    if (cmpId?.includes('lgnt') || cmpId == "default") {
      this.setFacebookTrackingPixel();
    }
  }


  forbidCookies(cmpId: any) {
    if (!cmpId) {
      return false;
    }
    this.setConsentCookie(cmpId + '_consent_removed');
  }
}
