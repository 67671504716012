<div class="job-container" [ngClass]="{'fixed-height shadow': clusterSlider}">
  <div class="p-3">
    <div class="flex-space-between pb-1">
      <span class="sub-title">{{item?.job_category}}</span>
      <span class="status-label" *ngIf="item.is_visited">Gesehen</span>
    </div>

    <div [class.job-title-container]="clusterSlider">
      <span class="job-title">{{item?.title | hyphenate}}</span>
    </div>

    <div>
      <span class="sub-title">{{item?.company_name}}</span>
    </div>

    <div class="flex-space-between">
      <span class="sub-title">{{item?.plz}} {{item?.ort}}</span>
<!--      <span class="sub-title">{{item?.publish_date | date:'dd MMM yyyy'}}</span>-->
    </div>

    <div  class="flex-space-between mt-2">
      <i *ngIf="!(isFavorite$ | async)" (click)="toggleFavorite()"  class="bi bi-star fs-22 pointer"></i>
      <i *ngIf="(isFavorite$ | async)" (click)="toggleFavorite()" class="bi bi-star-fill yellow-star fs-22 pointer"></i>
      <a (click)="visitJob()"><button class="btn btn-sm btn-primary button-radius">{{'ZUM_JOB' | translate}}</button></a>
    </div>
  </div>
</div>
